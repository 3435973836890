const actions = {
  // News Centers
  FETCH_NEWS_CENTERS: 'FETCH_NEWS_CENTERS',
  FETCH_NEWS_CENTERS_SUCCESS: 'FETCH_NEWS_CENTERS_SUCCESS',
  FETCH_NEWS_CENTERS_FAILURE: 'FETCH_NEWS_CENTERS_FAILURE',

  // News Center
  CREATE_NEWS_CENTER: 'CREATE_NEWS_CENTER',
  CREATE_NEWS_CENTER_SUCCESS: 'CREATE_NEWS_CENTER_SUCCESS',
  CREATE_NEWS_CENTER_FAILURE: 'CREATE_NEWS_CENTER_FAILURE',
  FETCH_NEWS_CENTER: 'FETCH_NEWS_CENTER',
  FETCH_NEWS_CENTER_SUCCESS: 'FETCH_NEWS_CENTER_SUCCESS',
  UPDATE_NEWS_CENTER: 'UPDATE_NEWS_CENTER',
  UPDATE_NEWS_CENTER_SUCCESS: 'UPDATE_NEWS_CENTER_SUCCESS',
  UPDATE_NEWS_CENTER_FAILURE: 'UPDATE_NEWS_CENTER_FAILURE',
  DELETE_NEWS_CENTER: 'DELETE_NEWS_CENTER',
  DELETE_NEWS_CENTER_SUCCESS: 'DELETE_NEWS_CENTER_SUCCESS',
  SET_SELECTED_NEWS_CENTER: 'SET_SELECTED_NEWS_CENTER',

  SET_CONNECT_DOMAIN_TAB_KEY: 'SET_CONNECT_DOMAIN_TAB_KEY',
  CUSTOM_DOMAIN_UPDATE: 'CUSTOM_DOMAIN_UPDATE',
  CUSTOM_DOMAIN_UPDATE_SUCCESS: 'CUSTOM_DOMAIN_UPDATE_SUCCESS',
  CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE: 'CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE',
  FETCH_NEWS_CENTER_LANGUAGES: 'FETCH_NEWS_CENTER_LANGUAGES',
  FETCH_NEWS_CENTER_LANGUAGES_SUCCESS: 'FETCH_NEWS_CENTER_LANGUAGES_SUCCESS',

  // News Items
  FETCH_NEWS_ITEMS: 'FETCH_NEWS_ITEMS',
  APPEND_NEWS_ITEMS: 'APPEND_NEWS_ITEMS',
  FETCH_APPEND_NEWS_ITEMS_SUCCESS: 'FETCH_APPEND_NEWS_ITEMS_SUCCESS',
  FETCH_NEWS_ITEMS_SUCCESS: 'FETCH_NEWS_ITEMS_SUCCESS',
  FETCH_NEWS_ITEMS_FAILURE: 'FETCH_NEWS_ITEMS_FAILURE',
  FETCH_NEWS_ITEM: 'FETCH_NEWS_ITEM',
  FETCH_NEWS_ITEM_SUCCESS: 'FETCH_NEWS_ITEM_SUCCESS',
  CREATE_NEWS_ITEM: 'CREATE_NEWS_ITEM',
  CREATE_NEWS_ITEM_SUCCESS: 'CREATE_NEWS_ITEM_SUCCESS',
  CREATE_NEWS_ITEM_FAILURE: 'CREATE_NEWS_ITEM_FAILURE',
  UPDATE_NEWS_ITEM: 'UPDATE_NEWS_ITEM',
  UPDATE_NEWS_ITEM_SUCCESS: 'UPDATE_NEWS_ITEM_SUCCESS',
  UPDATE_NEWS_ITEM_FAILURE: 'UPDATE_NEWS_ITEM_FAILURE',
  DELETE_NEWS_ITEM: 'DELETE_NEWS_ITEM',
  DELETE_NEWS_ITEM_SUCCESS: 'DELETE_NEWS_ITEM_SUCCESS',
  DELETE_NEWS_ITEM_FAILURE: 'DELETE_NEWS_ITEM_FAILURE',
  SET_DUPLICATE_NEWS_ITEM: 'SET_DUPLICATE_NEWS_ITEM',
  DUPLICATE_NEWS_ITEM: 'DUPLICATE_NEWS_ITEM',
  DUPLICATE_NEWS_ITEM_SUCCESS: 'DUPLICATE_NEWS_ITEM_SUCCESS',
  DUPLICATE_NEWS_ITEM_FAILURE: 'DUPLICATE_NEWS_ITEM_FAILURE',
  SET_COVER_IMAGE: 'SET_COVER_IMAGE',

  FETCH_NEWS_ITEMS_STATUS_COUNTS_SUCCESS:
    'FETCH_NEWS_ITEMS_STATUS_COUNTS_SUCCESS',

  // News Center Labels
  FETCH_NEWS_CENTER_LABELS: 'FETCH_NEWS_CENTER_LABELS',
  FETCH_NEWS_CENTER_LABELS_SUCCESS: 'FETCH_NEWS_CENTER_LABELS_SUCCESS',
  CREATE_NEWS_CENTER_LABEL: 'CREATE_NEWS_CENTER_LABEL',
  CREATE_NEWS_CENTER_LABEL_SUCCESS: 'CREATE_NEWS_CENTER_LABEL_SUCCESS',
  SAVE_NEWS_CENTER_LABEL_FAILURE: 'SAVE_NEWS_CENTER_LABEL_FAILURE',
  UPDATE_NEWS_CENTER_LABEL: 'UPDATE_NEWS_CENTER_LABEL',
  UPDATE_NEWS_CENTER_LABEL_SUCCESS: 'UPDATE_NEWS_CENTER_LABEL_SUCCESS',
  DELETE_NEWS_CENTER_LABEL: 'DELETE_NEWS_CENTER_LABEL',
  DELETE_NEWS_CENTER_LABEL_SUCCESS: 'DELETE_NEWS_CENTER_LABEL_SUCCESS',
  UPDATE_NEWSCENTER_LABELS_POSITION: 'UPDATE_NEWSCENTER_LABELS_POSITION',
  UPDATE_LABEL_DRAWER_STATUS: 'UPDATE_LABEL_DRAWER_STATUS',
  FETCH_AUTHORS: 'FETCH_AUTHORS',
  FETCH_AUTHORS_SUCCESS: 'FETCH_AUTHORS_SUCCESS',

  // Import News Items
  IMPORT_NEWS_ITEMS: 'IMPORT_NEWS_ITEMS',
  SET_IMPORT_NEWS_ITEMS_LOADER: 'SET_IMPORT_NEWS_ITEMS_LOADER',
  TOGGLE_IMPORT_MODAL_VISIBILITY: 'TOGGLE_IMPORT_MODAL_VISIBILITY',
  SET_CONNECT_DOMAIN_MODAL_VISIBLE: 'SET_CONNECT_DOMAIN_MODAL_VISIBLE',
  //report filters
  SET_RECIPIENT_SEARCH_TEXT: 'SET_RECIPIENT_USER_SEARCH_TEXT',
  SET_RECIPIENT_SORT_KEY_AND_ORDER: 'SET_RECIPIENT_SORT_KEY_AND_ORDER',
  SET_RECIPIENT_REACTION: 'SET_RECIPIENT_REACTION',
};

export default actions;
