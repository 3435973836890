import actions from './actions';
import { getBotSettingsData } from '../../containers/Messages/Bots/helper';
import { addHyphenToTeamsTeammates } from '../../helpers/utility';

let initState = {
  isAssistantTableLoading: false,
  assistantStatusList: [],
  assistantTableLists: [],
  searchedValue: '',
  selectedAssistantStatus: 'all',
  isDisableAssistantAction: false,
  isReOrderBot: false,
  isCreateAssistantDrawerOpen: false,
  botDetail: [],
  assistantSortKey: 'priority',
  assistantSortOrder: 'asc',
  duplicateId: '',
  tabKey: '1',
  editorPageStatusLoading: false,
  editorBotStatus: false,
  assistantLoading: {
    builderLoading: false,
    rulesLoading: false,
    settingsLoading: false,
  },
  teams: [],
  teamMates: [],
  openResponsesData: { visibility: false, data: null },
  applyTagData: { visibility: false, data: null },
  enrollBotDrawerData: { visibility: false, data: null },
  routeConversationData: { visibility: false, data: null },
  captureEmailData: { visibility: false, data: null },
  messageDrawerData: { visibility: false, data: null },
  questionWithButtonData: { visibility: false, data: null },
  welcomeMessageData: { visibility: false, data: null },
  meetingDrawerData: { visibility: false, data: null },
  conversationNoteDrawerData: { visibility: false, data: null },
  contactPropertyData: { visibility: false, data: null },
  conversationPropertyData: { visibility: false, data: null },
  goalDrawerData: { visibility: false, data: null },
  agentAvailabilityData: { visibility: false, data: null },
  customAudienceData: { visibility: false, data: null },
  supportBotQuestion: { visibility: false, data: null },
  handoverToEmmaData: { visibility: false, data: null },
  delayData: { visibility: false, data: null },
  meetingSchedulerList: [],
  meetingLinks: [],
  node: null,
  defaultDisplayRules: [],
  isComponentCreateLoading: false,
  settingsDetail: null,
  isSettingsFormError: false,
  assistantType: 'instant_bot',
  selectedBots: [],
  selectedBotTagID: '',
  botObject: null,
  previewLoader: false,
  audienceType: 'everyone',
  unansweredQuestions: [],
  unhelpfulResponses: [],
  supportBotsList: [],
  trainBotBtnLoader: {},
  createAssistantDrawerData: {},
  isTemplateUpdate: false,
  chatbotDetail: null,
  isTemplateDrawerVisible: false,
  allComponentsConfigured: false,
  isBotNameLoader: false,
  isBotStatusUpdateLoader: false,
  templateId: '',
  applyConversationTagData: { visibility: false, data: null },
  isValidatingReferenceID: false,
  isAIBotEnabled: false,
  aiBotDetails: {},
  aiBotLoader: false,
  emmaSourcesList: [],
  sourcesCount: {},
  selectedSourceType: 'all',
  isSourceTableLoading: false,
  sourceOrderBy: 'item_name',
  sourceOrderType: 'asc',
  sourceSearchValue: '',
  sourceDetails: {},
  selectedSourceId: null,
  sourceSubmitLoader: false,
};

export default function assistantReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_ASSISTANT_LIST:
      return {
        ...state,
        isAssistantTableLoading: true,
      };
    case actions.FETCH_ASSISTANT_LIST_SUCCESS:
      return {
        ...state,
        isAssistantTableLoading: false,
        assistantStatusList: action.payload.bots_count,
        assistantTableLists: action.payload.bots,
      };
    case actions.FETCH_ASSISTANT_LIST_FAILURE:
      return {
        ...state,
        isAssistantTableLoading: false,
      };
    case actions.SET_ASSISTANT_STATUS:
      return {
        ...state,
        selectedAssistantStatus: action.payload,
      };
    case actions.SET_CREATE_DRAWER_VISIBILITY_STATUS:
      return {
        ...state,
        isCreateAssistantDrawerOpen: action.payload,
        assistantNameSet: action.name ? `${action.name}(Copy)` : '',
        duplicateId: action.id ? action.id : '',
        createAssistantDrawerData: action.data,
        templateId: action.templateId,
      };
    case actions.CREATE_ASSISTANT:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.DUPLICATE_ASSISTANT:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.CREATE_ASSISTANT_SUCCESS:
      return {
        ...state,
        isCreateAssistantDrawerOpen: false,
        isComponentCreateLoading: false,
      };
    case actions.CREATE_ASSISTANT_FAILURE:
      return {
        ...state,
        isComponentCreateLoading: false,
      };
    case actions.APPEND_ASSISTANT_LIST:
      return {
        ...state,
        isAssistantTableLoading: true,
      };
    case actions.APPEND_ASSISTANT_LIST_SUCCESS:
      return {
        ...state,
        isAssistantTableLoading: false,
        assistantTableLists: state.assistantTableLists.concat(
          action.payload.bots,
        ),
      };
    case actions.APPEND_ASSISTANT_LIST_FAILURE:
      return {
        ...state,
        isAssistantTableLoading: false,
      };
    case actions.DUPLICATE_ASSISTANT_SUCCESS:
      return {
        ...state,
        isComponentCreateLoading: false,
        isCreateAssistantDrawerOpen: false,
      };
    case actions.DUPLICATE_ASSISTANT_FAILURE:
      return {
        ...state,
        isComponentCreateLoading: false,
      };
    case actions.DELETE_ASSISTANT:
      return {
        ...state,
        isDisableAssistantAction: true,
      };
    case actions.DELETE_ASSISTANT_SUCCESS:
      return {
        ...state,
        isDisableAssistantAction: false,
      };
    case actions.DELETE_ASSISTANT_FAILURE:
      return {
        ...state,
        isDisableAssistantAction: false,
      };
    case actions.SET_ASSISTANT_SEARCH_VALUE:
      return {
        ...state,
        searchedValue: action.payload,
      };
    case actions.RE_ORDER_ASSISTANT_STATUS:
      return {
        ...state,
        isReOrderBot: action.payload,
      };
    case actions.UPDATE_ASSISTANT_STATUS_SUCCESS:
      return {
        ...state,
        assistantTableLists: state.assistantTableLists.map(data => {
          if (data.id === action.payload.id) {
            data.status = action.payload.status;
          }
          return data;
        }),
      };
    case actions.CHANGE_ASSISTANT_POSITION:
      return {
        ...state,
        assistantTableLists: action.data,
      };
    case actions.SET_ASSISTANT_SORT_VALUES:
      return {
        ...state,
        assistantSortKey: action.sortKey,
        assistantSortOrder: action.sortOrder,
      };
    case actions.SET_ASSISTANT_TAB_KEY:
      return {
        ...state,
        tabKey: action.payload,
      };
    case actions.SET_ASSISTANT_EDITOR_STATUS_LOADING:
      return {
        ...state,
        editorPageStatusLoading: action.payload,
      };
    case actions.SET_ASSISTANT_EDITOR_STATUS_VALUE:
      return {
        ...state,
        editorBotStatus: action.payload,
      };
    case actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS:
      return {
        ...state,
        assistantLoading: {
          builderLoading: [false, true].includes(action.builderLoading)
            ? action.builderLoading
            : state.assistantLoading.builderLoading,
          rulesLoading: [false, true].includes(action.rulesLoading)
            ? action.rulesLoading
            : state.assistantLoading.rulesLoading,
          settingsLoading: [false, true].includes(action.settingsLoading)
            ? action.settingsLoading
            : state.assistantLoading.settingsLoading,
        },
      };
    case actions.FETCH_ASSISTANT_BOT_DETAILS_SUCCESS:
      const { bot } = action.payload;
      return {
        ...state,
        botDetail: action.payload,
        editorBotStatus: action.payload.bot.status === 'active',
        defaultDisplayRules: action.payload.bot.display_conditions,
        audienceType: action.payload.bot.audience_type,
        settingsDetail: getBotSettingsData(bot),
        isSettingsFormError: false,
      };
    case actions.UPDATE_ASSISTANT_SUCCESS:
      return {
        ...state,
        botDetail: action.payload,
      };
    case actions.UPDATE_ASSISTANT_CHAT_BUILDER_SUCCESS:
      return {
        ...state,
        botDetail: {
          ...state.botDetail,
          bot: action.payload.bot,
        },
      };
    case actions.UPDATE_ASSISTANT_DISPLAY_RULES_SUCCESS:
      return {
        ...state,
        defaultDisplayRules: action.payload.bot.display_conditions,
        audienceType: action.payload.bot.audience_type,
      };
    case actions.SET_ASSISTANT_SETTINGS_DATA:
      return {
        ...state,
        settingsDetail: action.isError ? state.settingsDetail : action.payload,
        isSettingsFormError: action.isError,
      };
    case actions.UPDATE_ASSISTANT_SETTINGS_SUCCESS:
      return {
        ...state,
        botDetail: action.payload,
        settingsDetail: getBotSettingsData(action.payload.bot),
        isSettingsFormError: false,
      };
    case actions.FETCH_ASSISTANT_USER_LIST_SUCCESS:
      let { teams, teamMates } = addHyphenToTeamsTeammates(action.payload);
      return {
        ...state,
        teams: teams,
        teamMates: teamMates,
        meetingSchedulerList: action.payload.scheduler,
      };
    case actions.SET_OPEN_RESPONSE_DRAWER_STATUS:
      return {
        ...state,
        openResponsesData: action.payload,
      };
    case actions.SET_APPLY_TAG_DRAWER_STATUS:
      return {
        ...state,
        applyTagData: action.payload,
      };
    case actions.SET_ENROLL_BOT_DRAWER_STATUS:
      return {
        ...state,
        enrollBotDrawerData: action.payload,
      };
    case actions.SET_ROUTE_CONVERSATION_DRAWER_STATUS:
      return {
        ...state,
        routeConversationData: action.payload,
      };
    case actions.SET_CAPTURE_EMAIL_DRAWER_STATUS:
      return {
        ...state,
        captureEmailData: action.payload,
      };
    case actions.SET_SEND_MESSAGE_DRAWER_STATUS:
      return {
        ...state,
        messageDrawerData: action.payload,
      };
    case actions.SET_ADD_BUTTON_DRAWER_STATUS:
      return {
        ...state,
        questionWithButtonData: action.payload,
      };
    case actions.SET_MEETINGS_DRAWER_STATUS:
      return {
        ...state,
        meetingDrawerData: action.payload,
        meetingLinks: [],
      };
      case actions.SET_CONVERSATION_NOTE_DRAWER_STATUS:
      return {
        ...state,
        conversationNoteDrawerData: action.payload,
      };
    case actions.SET_WELCOME_MESSAGE_DRAWER_STATUS:
      return {
        ...state,
        welcomeMessageData: action.payload,
      };
    case actions.SET_CONTACT_PROPERTY_DRAWER_STATUS:
      return {
        ...state,
        contactPropertyData: action.payload,
      };
    case actions.SET_CONVERSATION_PROPERTY_DRAWER_STATUS:
      return {
        ...state,
        conversationPropertyData: action.payload,
      };
    case actions.SET_GOAL_DRAWER_STATUS:
      return {
        ...state,
        goalDrawerData: action.payload,
      };
    case actions.FETCH_MEETING_SUCCESS:
      return {
        ...state,
        meetingLinks: action.payload.meeting_links,
      };
    case actions.SET_NODE:
      return {
        ...state,
        node: action.payload,
      };
    case actions.SET_AGENT_AVAILABILITY_DRAWER_STATUS:
      return {
        ...state,
        agentAvailabilityData: action.payload,
      };
    case actions.SET_BOT_CUSTOM_AUDIENCE_DRAWER_STATUS:
      return {
        ...state,
        customAudienceData: action.payload,
      };
    case actions.SET_HANDOVER_TO_EMMA_DRAWER_STATUS:
      return {
        ...state,
        handoverToEmmaData: action.payload,
      };
    case actions.RESET_ASSISTANTS:
      return initState;
    case actions.SET_ASSISTANT_TYPE:
      return {
        ...state,
        assistantType: action.payload,
      };
    case actions.SET_BOTS_SELECTED_ROWS:
      return {
        ...state,
        selectedBots: action.selectedRowKeys,
      };
    case actions.APPLY_BOT_TAG_SUCCESS:
      return {
        ...state,
        selectedBots: [],
      };
    case actions.SET_BOT_TAG_ID:
      return {
        ...state,
        selectedBotTagID: action.payload,
      };
    case actions.FETCH_BOT_PREVIEW_DETAILS:
      return {
        ...state,
        previewLoader: true,
      };
    case actions.FETCH_BOT_PREVIEW_SUCCESS:
      return {
        ...state,
        botObject: action.payload,
        previewLoader: false,
      };
    case actions.UPDATE_BOT_TIME_STAMP:
      return {
        ...state,
        botDetail: {
          ...state.botDetail,
          timeStamp: new Date().getTime() / 1000,
        },
      };
    case actions.SET_SUPPORT_BOT_DRAWER_STATUS:
      return {
        ...state,
        supportBotQuestion: action.payload,
      };
    case actions.FETCH_UNANSWERED_QUESTIONS_LIST: {
      return {
        ...state,
        isAssistantTableLoading: true,
      };
    }
    case actions.FETCH_UNANSWERED_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        unansweredQuestions: action.payload,
        isAssistantTableLoading: false,
      };
    case actions.FETCH_UNANSWERED_QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        isAssistantTableLoading: false,
      };
    case actions.APPEND_UNANSWERED_QUESTIONS_LIST:
      return {
        ...state,
        isAssistantTableLoading: true,
      };
    case actions.APPEND_UNANSWERED_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        isAssistantTableLoading: false,
        unansweredQuestions: state.unansweredQuestions.concat(action.payload),
      };
    case actions.APPEND_UNANSWERED_QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        isAssistantTableLoading: false,
      };
    case actions.FETCH_UNHELPFUL_RESPONSES_LIST: {
      return {
        ...state,
        isAssistantTableLoading: true,
      };
    }
    case actions.FETCH_UNHELPFUL_RESPONSES_LIST_SUCCESS:
      return {
        ...state,
        unhelpfulResponses: action.payload,
        isAssistantTableLoading: false,
      };
    case actions.FETCH_UNHELPFUL_RESPONSES_LIST_FAILURE:
      return {
        ...state,
        isAssistantTableLoading: false,
      };
    case actions.APPEND_UNHELPFUL_RESPONSES_LIST:
      return {
        ...state,
        isAssistantTableLoading: true,
      };
    case actions.APPEND_UNHELPFUL_RESPONSES_LIST_SUCCESS:
      return {
        ...state,
        isAssistantTableLoading: false,
        unhelpfulResponses: state.unhelpfulResponses.concat(action.payload),
      };
    case actions.APPEND_UNHELPFUL_RESPONSES_LIST_FAILURE:
      return {
        ...state,
        isAssistantTableLoading: false,
      };
    case actions.FETCH_SUPPORT_BOTS_LIST: {
      return {
        ...state,
        isAssistantTableLoading: true,
      };
    }
    case actions.FETCH_SUPPORT_BOTS_LIST_FAILURE:
      return {
        ...state,
        isAssistantTableLoading: false,
      };
    case actions.FETCH_SUPPORT_BOTS_LIST_SUCCESS:
      return {
        ...state,
        supportBotsList: action.payload.bots,
        isAssistantTableLoading: false,
      };
    case actions.TRAIN_BOT:
      return {
        ...state,
        trainBotBtnLoader: {
          ...state.trainBotBtnLoader,
          [action.questionId]: true,
        },
      };
    case actions.TRAIN_BOT_SUCCESS:
      return {
        ...state,
        trainBotBtnLoader: {
          ...state.trainBotBtnLoader,
          [action.payload]: false,
        },
      };
    case actions.TRAIN_BOT_FAILURE:
      return {
        ...state,
        trainBotBtnLoader: {
          ...state.trainBotBtnLoader,
          [action.payload]: false,
        },
      };
    case actions.RESET_ASSISTANT:
      return initState;
    case actions.UPDATE_BOT_DETAIL:
      return {
        ...state,
        botDetail: {
          ...state.botDetail,
          name: action.payload.name,
          status: action.payload.status,
          description: action.payload.description,
          bot_template_category_id: action.payload.bot_template_category_id,
        },
        isTemplateDrawerVisible: false,
      };
    case actions.CREATE_ASSISTANT_BOT_FROM_TEMPLATE:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.CREATE_ASSISTANT_BOT_FROM_TEMPLATE_SUCCESS:
      return {
        ...state,
        isCreateAssistantDrawerOpen: false,
        isComponentCreateLoading: false,
      };
    case actions.CREATE_ASSISTANT_BOT_FROM_TEMPLATE_FAILURE:
      return {
        ...state,
        isComponentCreateLoading: false,
      };
    case actions.SET_APPLY_CONVERSATION_TAG_DRAWER_STATUS:
      return {
        ...state,
        applyConversationTagData: action.payload,
      };
    case actions.CHECK_ENROLL_BOT_DATA:
      return {
        ...state,
        isValidatingReferenceID: true,
      };
    case actions.CHECK_ENROLL_BOT_DATA_SUCCESS:
      return {
        ...state,
        isValidatingReferenceID: false,
        enrollBotDrawerData: {
          ...state.enrollBotDrawerData,
          data: { ...state.enrollBotDrawerData.data, bot_data: action.payload },
        },
      };
    case actions.CHECK_ENROLL_BOT_DATA_FAILURE:
      return {
        ...state,
        isValidatingReferenceID: false,
        enrollBotDrawerData: {
          ...state.enrollBotDrawerData,
          data: { ...state.enrollBotDrawerData.data, bot_data: '' },
        },
      };
    case actions.FETCH_AI_BOT_DETAILS:
      return {
        ...state,
        sourceOrderBy: action.sourceOrderBy,
        sourceOrderType: action.sourceOrderType,
      };
    case actions.SET_SOURCE_SEARCH_VALUE:
      return {
        ...state,
        sourceSearchValue: action.payload,
      };
    case actions.FETCH_SOURCE_DETAILS_SUCCESS:
      return {
        ...state,
        sourceDetails: {
          ...action.payload,
        },
      };
    case actions.FETCH_SOURCES_COUNT_SUCCESS:
      return {
        ...state,
        sourcesCount: action.payload,
      };
    case actions.UPDATE_SELECTED_SOURCE_ID:
      return {
        ...state,
        selectedSourceId: action.payload,
      };
    case actions.FETCH_EMMA_SOURCES_SUCCESS:
      return {
        ...state,
        emmaSourcesList: action.payload.sources,
        isSourceTableLoading: false,
      };
    case actions.SET_SOURCE_TYPE:
      return {
        ...state,
        selectedSourceType: action.payload,
      };
    case actions.SET_SOURCE_TABLE_LOADER:
      return {
        ...state,
        isSourceTableLoading: action.payload,
      };
    case actions.RESET_SOURCE_VALUES:
      return {
        ...state,
        sourceDetails: {},
      };
    case actions.APPEND_SOURCES_LIST_SUCCESS:
      return {
        ...state,
        emmaSourcesList: [...state.emmaSourcesList, ...action.payload.sources],
        isSourceTableLoading: false,
      };
    case actions.SET_SOURCE_SORT_VALUES:
      return {
        ...state,
        sourceOrderBy: action.sourceOrderBy,
        sourceOrderType: action.sourceOrderType,
      };
    case actions.SET_SOURCE_SUBMIT_LOADER:
      return {
        ...state,
        sourceSubmitLoader: action.payload,
      }
    case actions.UPDATE_SELECTED_SOURCE_DETAILS:
      return {
        ...state,
        sourceDetails: { ...state.sourceDetails, ...action.payload },
      };
    case actions.SET_DELAY_DRAWER_STATUS: {
      return {
        ...state,
        delayData: action.payload,
      };
    }
    default:
      return state;
  }
}
