import React, { useState } from 'react';
import { Button, Icon, Input, Modal, Spin, message, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/chat/actions';
import Benefit1 from '../../../image/ai-custom-answers/benefit-1.png';
import Benefit2 from '../../../image/ai-custom-answers/benefit-2.png';
import Benefit3 from '../../../image/ai-custom-answers/benefit-3.png';
import {
  convertHtmlToText,
  fetchProjectSecretKey,
} from '../../../helpers/utility';
import settings from '../../../settings';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const AskEmmaModal = (props) => {
  const dispatch = useDispatch(),
    askEmmaMode = useSelector(({ Chat }) => Chat.askEmmaMode),
    askEmmaLoader = useSelector(({ Chat }) => Chat.askEmmaLoader),
    askEmmaModalVisible = useSelector(({ Chat }) => Chat.askEmmaModalVisible),
    askEmmaGeneratedTexts = useSelector(
      ({ Chat }) => Chat.askEmmaGeneratedTexts,
    );
  const {
    showNewConversation,
    currentConversationId,
    pushContentIntoComposer,
  } = props;
  const conversationId =
    showNewConversation && !currentConversationId
      ? 'new_message'
      : currentConversationId;
  const askEmmaGeneratedText =
    (askEmmaGeneratedTexts && askEmmaGeneratedTexts[conversationId]) || null;
  const { completion_text, source_data } = askEmmaGeneratedText || {};
  const [isIncludeConversation, setIncludeConversation] = useState(true);

  const [question, setQuestion] = useState('');
  let inputRef = '';

  const handleModalClose = () => {
    dispatch({
      type: actions.UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY,
      payload: {
        visible: false,
        mode: askEmmaMode,
      },
    });
    setTimeout(() => {
      dispatch({
        type: actions.FETCH_AI_CUSTOM_ANSWER_SUCCESS,
        payload: null,
        conversationId,
      });
      setQuestion('');
      setIncludeConversation(true);
    }, 100);
  };

  const fetchAICustomAnswer = () => {
    if (question.trim() !== '') {
      dispatch({
        type: actions.AI_ASSIST_CUSTOM_ANSWER_LOADER,
        payload: true,
        conversationId,
      });
      dispatch({
        type: actions.FETCH_AI_CUSTOM_ANSWER,
        customMessage: question.trim(),
        conversationId,
        includeConversationId: currentConversationId && isIncludeConversation,
      });
    } else {
      message.error('Please enter any question');
    }
  };

  const handleCustomAnswerInsert = () => {
    pushContentIntoComposer(completion_text.replace(/\n/g, '<br>'));
    handleModalClose();
  };

  const redirectToSources = (sourceId) => {
    if (sourceId) {
      window.open(
        `${
          settings.REACT_DOMAIN_URL
        }projects/${fetchProjectSecretKey()}/support/emma/contents/${sourceId}`,
        '_blank',
      );
    }
  };

  return (
    <Modal
      className="ai-assist-ask-emma-modal"
      visible={askEmmaModalVisible}
      onCancel={handleModalClose}
      width={800}
      footer={null}
      closable={false}
      centered={true}
      zIndex={1001}
    >
      <h3 className="mb-4">Ask Emma</h3>
      {askEmmaMode === 'ask_emma' && (
        <div className="flex flex-row gap-2">
          <Input
            ref={(input) => (inputRef = input) && inputRef.focus()}
            className="form-item input-md"
            value={question}
            placeholder={'Type your question'}
            onChange={(event) => setQuestion(event.target.value)}
            onKeyDown={(event) => {
              event.key === 'Enter' && fetchAICustomAnswer();
            }}
            maxLength={300}
            disabled={askEmmaLoader[conversationId]}
          />
          {completion_text && (
            <Button
              className="btn btn-secondary-outline"
              onClick={() => fetchAICustomAnswer()}
            >
              Retry
            </Button>
          )}
        </div>
      )}
      {askEmmaLoader[conversationId] ? (
        <Spin
          indicator={antIcon}
          className="flex justify-center items-center w-full min-h-40"
          tip="Emma is generating an answer for you. This may take a few moments..."
        />
      ) : (
        <>
          {completion_text ? (
            <>
              <div className="ai-custom-response">
                {completion_text === 'not_found'
                  ? 'I’m sorry, but none of the information I could find seems related to your question. Try again by rephrasing your question.'
                  : convertHtmlToText(completion_text)}
                {source_data && source_data.length > 0 && (
                  <div className="source-list">
                    <div className={'font-medium mb-1'}>
                      Source
                      {source_data.length > 1 ? 's' : ''}
                    </div>
                    <div className={'flex flex-col gap-1'}>
                      {source_data.map((source) => (
                        <div
                          className={'cursor-pointer'}
                          onClick={() => redirectToSources(source.source_id)}
                          key={source.title}
                        >
                          {source.title}
                          <i className={'far fa-angle-right'} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {completion_text !== 'not_found' && (
                <div className="flex justify-end mt-4">
                  <Button type="link" onClick={() => handleModalClose()}>
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleCustomAnswerInsert()}
                  >
                    Insert
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              {askEmmaMode === 'ask_emma' && (
                <>
                  {currentConversationId && (
                    //Hidden now need to enable as per the feedback
                    <div className="mt-4 hidden">
                      <Checkbox
                        defaultChecked={isIncludeConversation}
                        onChange={(e) =>
                          setIncludeConversation(e.target.checked)
                        }
                        checked={isIncludeConversation}
                      >
                        Include conversation history
                      </Checkbox>
                    </div>
                  )}
                  <div className="mt-4 mb-4">
                    <p className="text-lg font-semibold text-center">
                      Ask a question - get direct answers pulled from your help
                      content
                    </p>

                    <div className="flex justify-center gap-2 ">
                      <div className="text-center max-w-48">
                        <img
                          src={Benefit1}
                          className="mb-2"
                          alt="Answers are based only on your Emma sources"
                        />
                        <p>Answers are based only on your Emma sources</p>
                      </div>

                      <div className="text-center max-w-48">
                        <img
                          src={Benefit2}
                          className="mb-3"
                          alt="Check the sources if an answer looks off"
                        />
                        <p>Check the sources if an answer looks off</p>
                      </div>

                      <div className="text-center max-w-48">
                        <img
                          src={Benefit3}
                          className="mb-4"
                          alt="To improve future Answers, verify and update sources"
                        />
                        <p>
                          To improve future Answers, verify and update sources
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default AskEmmaModal;
