import moment from 'moment';
import { extractCurrentUserId } from '../../helpers/utility';
import { isEmpty, max } from 'lodash';
import {
  repliesReport,
  reportSummary,
  temmatesAndTeamsDataList,
} from './constants';
import React from 'react';
import { Icon } from 'antd';

export function previousDays(numberOfDays) {
  let days = {};
  days.fromDate = moment()
    .subtract(numberOfDays - 1, 'd')
    .format('YYYY-MM-DD');
  days.toDate = moment().format('YYYY-MM-DD');
  days.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return days;
}

export function changedCount(currentCount = 0, previousCount = 0, isTime) {
  let changedValue = currentCount - previousCount;
  let isMinus = Math.sign(changedValue) === -1;
  changedValue = Math.abs(changedValue);
  if (isTime && changedValue) {
    changedValue = dataConversion(changedValue, isTime);
  }
  return { changedValue, isMinus };
}

export function graphDataStructure(values = [], isTime) {
  return {
    graphValues: values.map((data) => ({
      time: data.value,
      name: moment(data.start_date).format('MMM D'),
      y: isTime
        ? autoChangeUnits(values, getValue, data.value) || 0
        : data.value || 0,
    })),
    unit: isTime ? autoChangeUnits(values, getUnit) : 'conversations',
  };
}

export function graphDataStructureNews(values = {}) {
  if (typeof values !== 'object' || values === null) {
    return { graphValues: [], unit: 'conversations' };
  }
  
  const graphValues = Object.entries(values).map(([key, value]) => ({
    time: value || 0,
    name: moment(key).format('MMM D'),
    y: value || 0,
  }));
  
  return { graphValues, unit: 'conversations' };
}

export function sagaValueCheck(params, reducerState) {
  let {
      fromDate,
      toDate,
      timeZone,
      checkFor,
      type,
      rating,
      author,
      user,
      has_comment,
      mailFilterId,
      newsItemId
    } = params,
    teamId,
    teamMateId;
  if (!fromDate) {
    fromDate = reducerState.fromDate;
  }
  if (!toDate) {
    toDate = reducerState.toDate;
  }
  if (!timeZone) {
    timeZone = reducerState.timeZone;
  }
  if (!checkFor) {
    checkFor = reducerState.checkFor;
  }
  if (!type) {
    type = reducerState.type;
  }

  if (type === 'teammates') {
    teamMateId = checkFor ? checkFor : extractCurrentUserId();
    teamId = '';
  } else {
    teamId = checkFor && checkFor !== 'allTeamMates' ? checkFor : '';
    teamMateId = '';
  }

  let map = {
    fromDate,
    toDate,
    timeZone,
    teamId,
    teamMateId,
    mailFilterId,
    newsItemId
  };

  if (type === 'article_feedback' || type === 'satisfaction_rating') {
    if (!rating) {
      rating = reducerState.rating;
    }

    if (!has_comment) {
      has_comment = reducerState.has_comment;
    }

    map.rating = rating;
    map.has_comment = has_comment;
  }

  if (type === 'article_feedback') {
    if (!author) {
      author = reducerState.author;
    }
    map.author = author;
  }

  if (type === 'satisfaction_rating') {
    if (!user) {
      user = reducerState.user;
    }
    map.user = user;
  }

  return map;
}

export function dataConversion(value, isTime) {
  let data = value,
    days,
    hours,
    minutes;
  if (isTime && value) {
    days = Math.floor(value / (24 * 60 * 60));
    value -= days * (24 * 60 * 60);
    hours = Math.floor(value / (60 * 60));
    value -= hours * (60 * 60);
    minutes = Math.floor(value / 60);
    value -= minutes * 60;
    data = `${0 < days ? `${days}d` : ''} ${0 < hours ? `${hours}h` : ''} ${
      0 < minutes ? `${minutes}m` : ''
    } ${0 < value ? `${value}s` : ''}`;
  }
  return data ? data : 0;
}

const getValue = (data, value) => {
  switch (data) {
    case 'seconds':
      return parseFloat(
        moment.duration(value, 'seconds').asSeconds().toFixed(2),
      );
    case 'minutes':
      return parseFloat(
        moment.duration(value, 'seconds').asMinutes().toFixed(2),
      );
    case 'hours':
      return parseFloat(moment.duration(value, 'seconds').asHours().toFixed(2));
    case 'days':
      return parseFloat(moment.duration(value, 'seconds').asDays().toFixed(2));
    default:
      return 0;
  }
};
const getUnit = (data) => data;

export function autoChangeUnits(values, cb, value) {
  const maxValue = max(values.map((data) => data.value));
  if (maxValue < 60) {
    return cb('seconds', value);
  } else if (maxValue >= 60 && maxValue < 3600) {
    return cb('minutes', value);
  } else if (maxValue >= 3600 && maxValue < 86400) {
    return cb('hours', value);
  } else if (maxValue >= 86400) {
    return cb('days', value);
  } else {
    return cb('days', 0);
  }
}

export function hoverLabel(index, metrics) {
  return metrics[index].start_date !== metrics[index].end_date
    ? `${moment(metrics[index].start_date).format('MMM D')} - ${moment(
        metrics[index].end_date,
      ).format('MMM D')}`
    : moment(metrics[index].start_date).format('MMM D');
}

export function dateDifference(fromDate, toDate) {
  let isLeapYear =
    moment(fromDate).format('YYYY') % 4 === 0 ||
    moment(toDate).format('YYYY') % 4 === 0;
  let difference = moment(toDate).diff(moment(fromDate), 'days');
  if (isLeapYear) {
    return difference < 366;
  } else {
    return difference < 365;
  }
}

export function getPercentageDifference(previousValue, currentValue) {
  if (previousValue) {
    return parseFloat(((currentValue - previousValue) / previousValue) * 100);
  } else return 0;
}

export function compareGrid(data, loader) {
  const compareGraphData = {
    newConversations: {
      value:
        data.newConversationReports &&
        data.newConversationReports.overall_value,
      previousValue:
        data.newConversationReports &&
        data.newConversationReports.previous_overall_value,
      loader: loader && loader.newConversationReportsLoader,
      case: 'in-direct',
      tooltip:
        'The total number of conversations created by contact for the currently selected time period.',
    },
    avgConversations: {
      value:
        data.newConversationReports &&
        data.newConversationReports.average_value,
      previousValue:
        data.newConversationReports &&
        data.newConversationReports.previous_average_value,
      loader: loader && loader.newConversationReportsLoader,
      case: 'in-direct',
      tooltip:
        'The average number of conversations created by contact per day for the currently selected time period.',
    },
    closedConversations: {
      value:
        data.closedConversationReports &&
        data.closedConversationReports.overall_value,
      previousValue:
        data.closedConversationReports &&
        data.closedConversationReports.previous_overall_value,
      loader: loader && loader.closedConversationReportsLoader,
      case: 'direct',
      tooltip:
        'The number of conversations that were closed, and have not been reopened since, for the currently selected time period.',
    },
    avgResolutions: {
      value:
        data.closedConversationReports &&
        data.closedConversationReports.average_value,
      previousValue:
        data.closedConversationReports &&
        data.closedConversationReports.previous_average_value,
      loader: loader && loader.closedConversationReportsLoader,
      case: 'direct',
      tooltip:
        'The average number of conversations closed per day for the currently selected time period.',
    },
    customersReached: {
      value:
        data.customersReachedReports &&
        data.customersReachedReports.overall_value,
      previousValue:
        data.customersReachedReports &&
        data.customersReachedReports.previous_overall_value,
      loader: loader && loader.customersReachedReportsLoader,
      case: 'in-direct',
      tooltip:
        'The total number of customers that created a conversation with at least one reply in the currently selected time period.',
    },
    avgCustomers: {
      value:
        data.customersReachedReports &&
        data.customersReachedReports.average_customers_reached,
      previousValue:
        data.customersReachedReports &&
        data.customersReachedReports.previous_average_customers_reached,
      loader: loader && loader.customersReachedReportsLoader,
      case: 'in-direct',
      tooltip:
        'The average number of customers that created a conversation per day in the currently selected time period.',
    },
  };
  return compareGraphData;
}

export function SummaryGrid(data, loader) {
  let summaryGirdData = {
    medianFirstResponseTime: {
      value:
        data.medianFirstResponseReports &&
        data.medianFirstResponseReports.overall_value,
      previousValue:
        data.medianFirstResponseReports &&
        data.medianFirstResponseReports.previous_overall_value,
      loader: loader && loader.medianFirstResponseReportsLoader,
      case: 'in-direct',
      tooltip:
        'The median time between a conversation being created and the first response sent by a teammate.',
      isTimeLimit: true,
    },
    medianResponseTime: {
      value:
        data.medianFirstResponseReports &&
        data.medianFirstResponseReports.median_response_time,
      previousValue:
        data.medianFirstResponseReports &&
        data.medianFirstResponseReports.previous_median_response_time,
      loader: loader && loader.medianFirstResponseReportsLoader,
      case: 'in-direct',
      tooltip:
        'The median time between a customer’s first unattended message and the following response sent by a teammate.',
      isTimeLimit: true,
    },
    medianTimeToClose: {
      value: data.medianCloseReports && data.medianCloseReports.overall_value,
      previousValue:
        data.medianCloseReports &&
        data.medianCloseReports.previous_overall_value,
      loader: loader && loader.medianCloseReportsLoader,
      case: 'in-direct',
      tooltip:
        'The median time between a conversation’s first contact initiated message and it being closed for the final time.',
      isTimeLimit: true,
    },
    avgRepliesToResolution: {
      value:
        data.medianCloseReports &&
        data.medianCloseReports.average_replies_to_resolution,
      previousValue:
        data.medianCloseReports &&
        data.medianCloseReports.previous_average_replies_to_resolution,
      loader: loader && loader.medianCloseReportsLoader,
      case: 'direct',
      tooltip:
        'The average number of replies for resolved conversations with at least one reply that were resolved in the currently selected time period',
      isTimeLimit: false,
    },
  };
  return summaryGirdData;
}

export function getPercentageDifferenceCount(data) {
  if (!isEmpty(data.newConversationReports)) {
    let presentDifferance =
      data.newConversationReports.closed_count -
      data.newConversationReports.opened_count;
    let previousDifferance =
      data.newConversationReports.previous_closed_count -
      data.newConversationReports.previous_opened_count;
    if (previousDifferance) {
      return (
        ((presentDifferance - previousDifferance) / previousDifferance) *
        100
      ).toFixed(1);
    } else if (presentDifferance) {
      return presentDifferance;
    } else return 0;
  } else return 0;
}

export function checkLocation(location) {
  if (location && location.includes('responsiveness')) return reportSummary;
  else if (location && location.includes('team'))
    // This is both team and teammates data check.
    return temmatesAndTeamsDataList;
  else return repliesReport;
}

export function checkGraphType(reportName, location) {
  if (
    location &&
    reportName === 'conversationReliedTo' &&
    location.includes('team') // This is both team and teammates data check.
  )
    return 'graph-card';
  else return 'report-card';
}

export function conversationDataList(inboxReportsData) {
  let conversationData = {
    newConversations: {
      value:
        inboxReportsData.newConversationReports &&
        inboxReportsData.newConversationReports.metrics,
    },
    closedConversations: {
      value:
        inboxReportsData.closedConversationReports &&
        inboxReportsData.closedConversationReports.metrics,
    },
    customersReached: {
      value:
        inboxReportsData.customersReachedReports &&
        inboxReportsData.customersReachedReports.metrics,
    },
  };
  return conversationData;
}

export function botCoverageDataList(data) {
  let response = {
    covered: {
      value: data.covered && data.covered.metrics,
    },
    not_covered: {
      value: data.not_covered && data.not_covered.metrics,
    },
  };
  return response;
}

export function emailEngagementDataList(data) {
  let response = {
    sent: {
      value: data.sent && data.sent.metrics,
    },
    open: {
      value: data.open && data.open.metrics,
    },
    click: {
      value: data.click && data.click.metrics,
    },
    click_through_rate: {
      value: data.click_through_rate && data.click_through_rate.metrics,
    },
    delivered: {
      value: data.delivered && data.delivered.metrics,
    },
    hard_bounced: {
      value: data.hard_bounced && data.hard_bounced.metrics,
    },
    unsubscribed: {
      value: data.unsubscribed && data.unsubscribed.metrics,
    },
    spam_report: {
      value: data.spam_report && data.spam_report.metrics,
    },
  };
  return response;
}

export function checkIndication(changedValue, isMinus, caseType, valueType) {
  if (caseType === 'direct') {
    return (
      <span className={`changed-count ${isMinus ? 'negative' : ''}`}>
        {isMinus ? (
          <i className={'fas fa-caret-down'} />
        ) : (
          <i className={'fas fa-caret-up'} />
        )}{' '}
        {changedValue}
        {valueType === 'percentage' ? '%' : ''}
      </span>
    );
  } else if (caseType === 'in-direct') {
    return (
      <span className={`changed-count ${isMinus ? '' : 'negative'}`}>
        {isMinus ? (
          <i className={'fas fa-caret-up'} />
        ) : (
          <i className={'fas fa-caret-down'} />
        )}{' '}
        {changedValue}
        {valueType === 'percentage' ? '%' : ''}
      </span>
    );
  } else return 0;
}

export function getSummaryPercentageChangeDom(data) {
  let currentValue = data.value ? data.value : 0;
  let previousValue = data.previousValue ? data.previousValue : 0;

  if (currentValue - previousValue === 0 || previousValue === 0) {
    // No change scenario
    return <span className={'changed-count neutral'}>-</span>;
  } else if (currentValue - previousValue > 0) {
    // Increased value scenario
    return (
      <span
        className={`changed-count ${
          data.case === 'direct' ? 'positive' : 'negative'
        }`}
      >
        <i className={'fas fa-caret-up'} />
        {`${Math.abs(
          getPercentageDifference(previousValue, currentValue),
        ).toFixed(1)} %`}
      </span>
    );
  } else {
    // Decreased value scenario
    return (
      <span
        className={`changed-count ${
          data.case === 'direct' ? 'negative' : 'positive'
        }`}
      >
        <i className={'fas fa-caret-down'} />
        {`${Math.abs(
          getPercentageDifference(previousValue, currentValue),
        ).toFixed(1)} %`}
      </span>
    );
  }
}

export function loaderIcon(size) {
  return <Icon type="loading" style={{ fontSize: size }} spin />;
}

export function totalDays(fromDate, toDate) {
  return (moment(toDate).diff(moment(fromDate), 'days') + 1).toString();
}

export const getSatisfactionRatingTotal = (count) =>
  Object.keys(count).reduce((acc, key) => count[key] + acc, 0);

export const getSatisfactionIndividualPercentage = (count) => {
  let totalCount = getSatisfactionRatingTotal(count);
  return Object.keys(count).reduce(
    (acc, data, index, arr) => {
      let currentPercent = Math.round(
        Number(
          index === arr.length - 1
            ? 100 - acc.total
            : ((count[data] / totalCount) * 100).toFixed(2),
        ),
      );
      return {
        ...acc,
        total: acc.total + currentPercent,
        count: {
          ...acc.count,
          [data]: {
            count: count[data],
            percent: currentPercent,
          },
        },
      };
    },
    {
      total: 0,
      count: {},
    },
  ).count;
};

export const getSatisfactionPercentage = (count) =>
  Number(
    (
      (((count[5] || 0) + (count[4] || 0)) /
        getSatisfactionRatingTotal(count) || 1) * 100
    ).toFixed(2),
  );
