import { Icon } from 'antd';
import React from 'react';

export const fontSize = [
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '18',
  '20',
  '24',
  '30',
  '36',
  '48',
  '60',
  '72',
];

export const paragraphFormat = {
  N: 'Paragraph',
  H1: 'Heading 1',
  H2: 'Heading 2',
  H3: 'Heading 3',
  H4: 'Heading 4',
  H5: 'Heading 5',
  H6: 'Heading 6',
  BLOCKQUOTE: 'Blockquote',
  PRE: 'Code',
};

export const lineHeights = {
  Default: '',
  Single: '1',
  1.15: '1.15',
  1.25: '1.25',
  1.5: '1.5',
  1.75: '1.75',
  Double: '2',
  2.5: '2.5',
  Triple: '3',
  Quadruple: '4',
};

export const mathData = [
  { id: 'add', display_name: 'Add' },
  { id: 'subtract', display_name: 'Subtract' },
  { id: 'multiple', display_name: 'Multiple' },
  { id: 'divide', display_name: 'Divide' },
];
export const performMathOperation = [
  { id: 'add', value: 'to' },
  { id: 'subtract', value: 'from' },
  { id: 'multiple', value: 'with' },
  { id: 'divide', value: 'by' },
];

export const invalidContent = ['null', null, undefined, ''];

export const defaultTimeZone = 'Etc/UTC';

export const commonDateFormat = 'DD/MM/YYYY';

export const keyCode = {
  backSpace: 8,
  enter: 13,
  escape: 27,
  upArrow: 38,
  downArrow: 40,
  delete: 46,
  at: 50,
  hashTag: 51,
  questionMark: 191,
};

// Todo: Need to remove after backend implemented these changes
export const propertyLists = {
  eu_consent: 'EU consent',
  ip_city: 'IP city',
  ip_country: 'IP country',
  ip_country_code: 'IP country code',
  ip_time_zone: 'IP time zone',
};

export const debounceTime = 200;

export const spaceReg = /\s/gi;

export const dateFormatList = ['DD/MM/YYYY', 'YYYY-MM-DD'];

export const conversationStatusChangeDelay = 50;

export const supportedImageFormats = '.jpg, .jpeg, .png';
export const supportedVideoFormats = '.mp4';

export const tableTypes = {
  chatOnGoing: 'chatOnGoing',
  chatOneOff: 'chatOneOff',
};
export const userType = [
  { name: 'User', id: 1, key: 'user' },
  { name: 'Lead', id: 2, key: 'lead' },
  { name: 'Visitor', id: 3, key: 'visitor' },
];

export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, Democratic Republic of the',
  'Costa Rica',
  "Côte d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (Democratic People's Republic of)",
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const spamRestrictOmniSearchKeys = [
  'add_note',
  'insert articles',
  'snippet',
  'upload_attachment',
  'upload_image',
  'assign_to',
  'assign_to_me',
  'close_conversation',
  'reopen_conversation',
  'snooze_conversation',
  'change_priority',
  'tag_conversation',
  'write_reply',
  'ask_emma',
  'ai_assist',
];

export const PRODUCT_INFO = {
  contacts: {
    title: 'View, segment, and filter your customer data',
    description:
      'Craft the best customer support experiences using a complete picture of your customers data and past actions.',
    links: [
      {
        label: 'Learn how Emails work',
        action: {
          type: 'article',
          id: 67,
        },
      },
      {
        label: 'Best practices',
        action: {
          type: 'article',
          id: 76,
        },
      },
    ],
  },
  inbox: {
    title:
      'Install the Messenger and more channels to connect with your customers',
    description:
      'Provide conversational support wherever your customers are with lots of channels to choose from. Customize your Messenger to fully match your brand.',
  },
  emails: {
    title: 'Drive growth with targeted messages',
    description:
      'Increase sales, onboard new users, and grow faster with powerful messages that deliver.',
    links: [
      {
        label: 'Learn how Emails work',
        action: {
          type: 'article',
          id: 67,
        },
      },
      {
        label: 'Best practices',
        action: {
          type: 'article',
          id: 76,
        },
      },
    ],
  },
  chats: {
    title: 'Offer proactive support',
    description:
      'Onboard, educate, and notify your customers with in-context, automated messages — cutting support volume and keeping customers happy.',
    links: [
      {
        label: 'Learn how Chats work',
        action: {
          type: 'article',
          id: 312,
        },
      },
    ],
  },
  posts: {
    title: 'Announce with impact',
    description:
      'Share product updates or promotions with in-context, automated messages, and drive customers to take action.',
    links: [
      {
        label: 'Learn how Posts work',
        action: {
          type: 'article',
          id: 317,
        },
      },
    ],
  },
  custom_bots: {
    title: 'Automate your inbox at scale',
    description:
      'Speed up customer resolutions, qualify leads, triage conversations, book meetings and grow your pipeline automatically.',
    links: [
      {
        label: 'Learn how Bots work',
        action: {
          type: 'article',
          id: 294,
        },
      },
      {
        label: 'Best practices',
        action: {
          type: 'article',
          id: 169,
        },
      },
    ],
  },
  surveys: {
    title: 'Gather valuable customer insights',
    description:
      'From nurture campaigns to onboarding experiences — and much more — Surveys helps you capture and automatically act on valuable customer insights.',
    links: [
      {
        label: 'Learn how surveys work',
        action: {
          type: 'article',
          id: 415,
        },
      },
      {
        label: 'Try example survey',
        action: {
          type: 'survey',
          id: 20,
        },
      },
      {
        label: 'Try NPS survey',
        action: {
          type: 'survey',
          id: 71,
        },
      },
    ],
  },
  tours: {
    title: 'Drive adoption with Product Tours',
    description:
      "Guide customers through their first steps, highlight what's new, and give proactive guidance at scale.",
    links: [
      {
        label: 'Learn how Tours work',
        action: {
          type: 'article',
          id: 166,
        },
      },
    ],
  },
  forms: {
    title: 'Build first-party audience at scale',
    description:
      'Create, test and personalize popups, embed forms, banners, slide-ins and more to grow your email & SMS lists while driving more sales.',
    links: [
      {
        label: 'Learn how Forms works',
        action: {
          type: 'article',
          id: 258,
        },
      },
      {
        label: 'Types of forms',
        action: {
          type: 'article',
          id: 53,
        },
      },
    ],
  },
  workflows: {
    title: 'Map out a cohesive customer journey',
    description:
      'Craft a better experience across the entire customer journey with omnichannel messaging - from welcoming new customers to recovering carts.',
    links: [
      {
        label: 'Learn how Workflows work',
        action: {
          type: 'article',
          id: 211,
        },
      },
    ],
  },
  automation_rules: {
    title: 'Automate simple tasks',
    description:
      'Automate manual tasks by setting up simple one-off actions using an if-this, then-that builder.',
    links: [
      {
        label: 'Learn how Rules work',
        action: {
          type: 'article',
          id: 166,
        },
      },
    ],
  },
  inbox_rules: {
    title: 'Automate repetitive tasks at scale',
    description:
      'Save time by automating repetitive processes using rules that automatically assign incoming conversations to specific teammates, tag conversations, reply with canned messages and more.',
    background: 'white',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 398,
        },
      },
      {
        label: 'Common ways of using rules',
        action: {
          type: 'article',
          id: 346,
        },
      },
    ],
  },
  custom_answers: {
    title: 'Control Emma when you want it',
    description:
      'Create bespoke answers to your most important questions, and Emma will prioritize them over its AI Answers.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 397,
        },
      },
      {
        label: 'Create your first answer',
        action: {
          type: 'article',
          id: 398,
        },
      },
      {
        label: 'Training best practices',
        action: {
          type: 'article',
          id: 400,
        },
      },
    ],
  },
  setup_emma: {
    title: 'Meet Emma, our breakthrough AI Chatbot',
    description:
      'A natural language bot, Emma understands complex queries, asks clarifying questions, and fully converses with your customers – dramatically reducing support volume, unlocking 24/7 support, and delivering CSAT-boosting service.',
    links: [
      {
        label: 'Learn how AI Answers work',
        action: {
          type: 'article',
          id: 478,
        },
      },
    ],
  },
  emma_content: {
    title: 'Manage your Emma content',
    description:
      'Add content to help Emma answer your customers’ questions. Emma will automatically generate AI Answers by using the content you manage in this page.',
    links: [
      {
        label: 'Learn how AI Answers work',
        action: {
          type: 'article',
          id: 478,
        },
      },
    ],
  },
  meetings: {
    title: 'Focus on meeting, not making meetings',
    description:
      "Share a link with customers that lets them see when you're free to book meetings with you, cutting out those tedious 'what time works best for you' emails.",
    links: [
      {
        label: 'Learn how Meetings works',
        action: {
          type: 'article',
          id: 372,
        },
      },
      {
        label: 'Setup your profile',
        action: {
          type: 'article',
          id: 8,
        },
      },
    ],
  },
  news_items: {
    title: 'News items',
    description:
      'Create a customized dedicated public-facing web page. Announce product updates, company news, promotions, events, and more.',
    links: [
      {
        label: 'Learn how News items works',
        action: {
          type: 'article',
          id: 508,
        },
      },
    ],
  },
  events: {
    title: 'Events',
    description:
      'Record specific actions users take in your product — track the first, last and total amount of times a user does something.',
    links: [
      {
        label: 'Learn how Event Visualizer work',
        action: {
          type: 'article',
          id: 30,
        },
      },
      {
        label: 'Track custom events',
        action: {
          type: 'article',
          id: 29,
        },
      },
    ],
  },
  apps: {
    title: 'Connect your favourite apps',
    description: 'Discover new apps and connect your existing tools with Gist.',
    links: [
      {
        label: 'Build your own app',
        action: {
          type: 'link',
          url: '/developer',
        },
      },
    ],
  },
  developer_apps: {
    title: 'Your Apps',
    description:
      'Build on the Gist platform by creating apps. Apps can be just for your own team, or can be listed in the App Store for others to use too.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 398,
        },
      },
    ],
  },
  messenger_apps: {
    title: 'Customize your Messenger home',
    description:
      'Adds apps to your Messenger home to help website visitors and customers find what they’re looking for quickly - so they can do more than chat.',
  },
  spam: {
    title: 'Spam settings',
    description:
      'Manage which incoming conversations should be marked as spam in your inbox.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 320,
        },
      },
    ],
  },
  snippets: {
    title: 'Reduce the time spent on writing repetitive answers.',
    description:
      'Speed up your workflow with customizable, easily repeatable messages with full personalization tags support.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 398,
        },
      },
    ],
  },
  kb_themes: {
    title: 'Match your brand',
    description:
      'Customize and publish themes to change your knowledge base appearance.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 435,
        },
      },
    ],
  },
  qualification_bot: {
    title: 'Qualification Bot',
    description:
      'Automate your lead qualification with custom responses to disqualify low value leads, or send relevant content to help your leads self serve.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 244,
        },
      },
    ],
  },
  security: {
    title: 'Security',
    description:
      'Enhance your security and prevent impersonation for logged-in users by setting up Identity Verification.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 469,
        },
      },
    ],
  },
  email_forwarding: {
    title: 'Email Forwarding',
    description:
      'Forward emails from your shared email accounts (such as support@yourdomain.com) to Gist so you can read and reply from your Gist inbox.',
    links: [
      {
        label: 'View step by step instructions',
        action: {
          type: 'article',
          id: 120,
        },
      },
    ],
  },
  email_sending_addresses: {
    title: 'Email Sending Addresses',
    description:
      'By default, all emails sent through Gist are signed with our high-reputation sending domains, meaning your campaigns are less likely to end up in a spam folder. If you would prefer to send your emails using your own domain, click "Add new address" and follow the instructions.',
    links: [
      {
        label: 'Learn how to set it up',
        action: {
          type: 'article',
          id: 61,
        },
      },
    ],
  },
  email_templates: {
    title: 'Custom Email Templates',
    description:
      'Add your logo and select the colors and fonts that fit your brand, so you can create a reusable design for your future email campaigns.',
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 402,
        },
      },
    ],
  },
  subscription_types: {
    title: 'Subscription Types',
    description:
      "Classify email messages by subscription type and let your audience manage their email preferences with granular unsubscribe. You can view people subscription data on the Contacts page. We've created the presets below to help you get started!",
    links: [
      {
        label: 'How it works',
        action: {
          type: 'article',
          id: 401,
        },
      },
    ],
  },
  teams: {
    title: 'Organize your inbox by team',
    description:
      'Create separate spaces for each of your teams in the Inbox. Automatically route conversations, manage and report on teams rather than teammates.',
    links: [
      {
        label: 'How to use teams',
        action: {
          type: 'article',
          id: 10,
        },
      },
    ],
  },
  roles: {
    title: 'Customize your team permissions using roles',
    description:
      'Set up permissions in a consistent way using roles, which helps keep your workspace secure and compliant.',
    links: [
      {
        label: 'Default roles',
        action: {
          type: 'article',
          id: 392,
        },
      },
      {
        label: 'Custom roles',
        action: {
          type: 'article',
          id: 393,
        },
      },
    ],
  },
  office_hours: {
    title: 'Set your office hours',
    description:
      'Let people know when your team will be back online. They’ll see hours converted to their own time zone.',
    links: [
      {
        label: 'Learn about office hours',
        action: {
          type: 'article',
          id: 333,
        },
      },
    ],
  },
  knowledge_base: {
    title: 'Help customers help themselves',
    description:
      'Create articles from scratch or migrate from Intercom. Articles can be integrated with Emma, Inbox, live chat and more. Your Help Center can be fully customized to fit your brand with no-code.',
    links: [
      {
        label: 'Setup your help center',
        action: {
          type: 'article',
          id: 118,
        },
      },
    ],
  },
  contact_properties: {
    title: 'Send data to segment and personalise your customer experience',
    description:
      'Use this data as context in the Inbox, as rules for automations, as audience for outbound messages, and much more.',
    links: [
      {
        label: 'How to send customer data',
        action: {
          type: 'article',
          id: 27,
        },
      },
    ],
  },
};

export const antIcon = <Icon type="loading" spin />;

export const notificationList = [
  {
    title: 'Conversation assigned to you',
    content:
      'Get notified when a new conversation is assigned to you. Bots only notify at the point of hand off.',
    key: 'assigned_to_me',
  },
  {
    title: 'Conversation assigned to your teams',
    content:
      'Get notified when a new conversation is assigned to your teams. Bots only notify at the point of hand off.',
    key: 'assigned_to_associated_teams',
  },
  {
    title: 'New unassigned conversation',
    content:
      'Get notified when an unassigned conversation is received in the inbox. Bots only notify at the point of handoff.',
    key: 'unassigned',
  },
  {
    title: 'You’re mentioned in an internal note',
    content:
      'Get notified when a teammate @mentions you in an internal note from an email or chat conversation.',
    key: 'mentioned',
  },
  {
    title: 'Reply to conversation assigned to you',
    content:
      'Get notified when someone replies to a conversation assigned to you.',
    key: 'reply_for_assigned_to_me',
  },
  {
    title: 'Reply to conversation assigned to your teams',
    content:
      'Get notified when someone replies to a conversation assigned to your teams.',
    key: 'assigned_to_related_teams',
  },
  {
    title: 'Reply to conversation assigned to other teams or teammates',
    content:
      'Get notified when someone replies to a conversation assigned to other teams or teammates.',
    key: 'assigned_to_others',
  },
];

export const copilotContainer = 'copilot-container';
