import { message } from 'antd';

import settings from '../../../settings';
import { handleTabOnEdge, handleClickOnNode } from './helper';
import {
  botToolbarButtons,
  imageEditButtons,
  videoInsertButtons,
} from '../../../components/Settings/constant';
import {
  SimpleMessage,
  WelcomeMesage,
  Buttons,
  EmailCapture,
  Meetings,
  Automation,
  OpenResponse,
  SupportBotQuestion,
  LinkBot,
  Conditions,
  Delay,
  NoteBlock,
  Emma,
  ConversationNote,
} from '../../../components/Assistants/Editor/Components';

const events = {
  click: (params) => {
    handleClickOnNode(params);
  },
  input: (params) => {
    params.e.target.style.height = 'auto';
    params.e.target.style.height = `${params.e.target.scrollHeight}px`;
    const { node } = params;
    window.botToolkit.updateNode(node, { value: params.e.target.value });
  },
  contextmenu: (params) => {
    const { node, e } = params;
    if (
      !['note_block', 'support_bot_question', 'enroll_to_another_bot'].includes(
        node.data.type,
      )
    ) {
      const { clientX: mouseX, clientY: mouseY } = e;
      const contextMenu = document.querySelector('#customContextMenu');
      contextMenu.style.top = `${mouseY}px`;
      contextMenu.style.left = `${mouseX}px`;
      contextMenu.classList.add('visible');
      contextMenu.setAttribute('data-node-id', node.id);
    }
  },
};

export const viewConfig = {
  nodes: {
    send_message: { component: SimpleMessage, events },
    greeting_message: { component: WelcomeMesage, events },
    capture_question: { component: Buttons, events },
    capture_email: { component: EmailCapture, events },
    send_calendar_invite: { component: Meetings, events },
    add_conversation_note: { component: ConversationNote, events },
    automation: { component: Automation, events },
    question_with_open_response: { component: OpenResponse, events },
    condition: { component: Conditions, events },
    support_bot_question: { component: SupportBotQuestion, events },
    note_block: { component: NoteBlock, events },
    enroll_to_another_bot: { component: LinkBot, events },
    emma: { component: Emma, events },
    delay: { component: Delay, events },
    default: { component: SimpleMessage, events },
  },
  edges: {
    default: {
      editable: true,
      anchors: ['Right', [0, 0, -1, 0, 0, 30]],
      endpoints: [['Dot', { radius: 7 }], 'Blank'],
      endpointStyle: {
        fill: '#5856d5',
      },
      connector: ['Flowchart', { cornerRadius: 8, gap: [7, 0] }],
      paintStyle: {
        strokeWidth: 2,
        stroke: '#cfd1d2',
        outlineWidth: 3,
        outlineStroke: 'transparent',
      }, //	paint style for this edge type.
      hoverPaintStyle: { strokeWidth: 2, stroke: '#5856d5' }, // hover paint style for this edge type.
      overlays: [
        ['Arrow', { location: 1, width: 12, length: 15 }],
        [
          'Label',
          {
            cssClass: 'delete-edges',
            label: "<span><i class='fal fa-trash'></i></span>",
            events: {
              tap: (params) => {
                handleTabOnEdge(params);
              },
            },
          },
        ],
      ],
    },
  },
  ports: {
    default: {
      endpoint: [
        'Dot',
        {
          radius: 4,
          cssClass: 'custom-anchor-class',
        },
      ],
      interceptors: {
        beforeDrop: function (params) {
          // returning anything but true will cause the connection to be aborted.
          const { port } = params.connection.source.jtk;
          if (port && port.getEdges().length > 0) {
            return false; // Don't connect, already have an connection
          }
          return true;
        },
      },
    },
  },
};

export const minZoom = 0.3;

export const maxZoom = 1;

export const defaultZoom = 0.8;

export function renderConfig() {
  return {
    dragOptions: {
      filter: '.froalaEditor, i', // Without this element to drag the node
      containment: true,
      grid: [100, 100],
    },
    dropOptions: {
      containment: true,
    },
    layout: {
      type: 'Absolute',
    },
    zoom: sessionStorage.getItem('botZoomLevel')
      ? sessionStorage.getItem('botZoomLevel') / 100
      : defaultZoom,
    zoomToFit: false,
    zoomRange: [minZoom, maxZoom],
    consumeRightClick: false,
    lassoInvert: false,
    enablePan: true,
    enableWheelZoom: false,
    enablePanButtons: false,
  };
}

export const emailChoices = [
  { id: 1, response_type: 'valid_email', message: 'Valid email' },
  { id: 2, response_type: 'invalid_email', message: 'Invalid email' },
  { id: 3, message: 'Unwanted email', response_type: 'unwanted_email' },
];

export const meetingButtonList = [
  { id: 1, response_type: 'successful_booking', message: 'Successful booking' },
  { id: 2, response_type: 'abandoned_booking', message: 'Abandoned booking' },
];

export const conditionalButtonList = [
  { id: 1, response_type: 'yes_path', message: 'Yes' },
  { id: 2, response_type: 'no_path', message: 'No' },
];

export const messageBlocks = [
  {
    type: 'send_message',
    text: 'Send a simple message',
    icon: 'fas fa-comment-lines',
    color: '#7a6ad1',
  },
  {
    type: 'capture_email',
    text: 'Ask for email',
    icon: 'fas fa-envelope',
    color: '#7a6ad1',
  },
  {
    type: 'capture_question',
    text: 'Reply buttons',
    icon: 'fas fa-grip-lines',
    color: '#7a6ad1',
  },
  {
    type: 'question_with_open_response',
    text: 'Collect customer data',
    icon: 'fas fa-comment-dots',
    color: '#7a6ad1',
  },
  // {
  //   type: 'send_expected_reply_time',
  //   text: 'Send expected reply time',
  //   icon: 'fas fa-comment-lines',
  //   color: '#7a6ad1',
  // },
  {
    type: 'send_calendar_invite',
    text: 'Book a meeting',
    icon: 'fas fa-calendar-alt',
    color: '#7a6ad1',
  },
  // {
  //   type: 'dynamic_content',
  //   text: 'Dynamic content',
  //   icon: 'fas fa-comment-lines',
  //   color: '#48ebab',
  // },
  {
    type: 'add_conversation_note',
    text: 'Add conversation note',
    icon: 'fas fa-pencil',
    color: '#7a6ad1',
  },
];

export const devBlocks = [
  {
    type: 'dev',
    action_type: 'collect_customer_reply',
    text: 'Collect customer reply',
    icon: 'fas fa-comment-lines',
    color: '#48ebab',
  },
  {
    type: 'dev',
    action_type: 'set_local_variable',
    text: 'Set local variable',
    icon: 'fas fa-database',
    color: '#48ebab',
  },
  {
    type: 'emma',
    action_type: 'set_ai_response',
    text: 'Set AI response',
    icon: 'fas fa-database',
    color: '#48ebab',
  },
  {
    type: 'emma',
    action_type: 'send_ai_response',
    text: 'Send AI response',
    icon: 'fas fa-database',
    color: '#48ebab',
  },
  {
    type: 'dev',
    action_type: 'send_http_request',
    text: 'Send HTTP request',
    icon: 'fas fa-database',
    color: '#48ebab',
  },
  {
    type: 'dev',
    action_type: 'execute_js_code',
    text: 'Execute JavaScript code',
    icon: 'fas fa-code',
    color: '#48ebab',
  },
];

export const automationBlocks = [
  {
    type: 'emma',
    action_type: 'hand_over_to_emma',
    text: 'Let Emma AI answer',
    icon: 'fas fa-database',
    color: '#48ebab',
  },
  {
    type: 'automation',
    action_type: 'apply_contact_tags',
    text: 'Tag contact',
    icon: 'fas fa-tag',
    color: '#b77ce4',
  },
  {
    type: 'automation',
    action_type: 'apply_conversation_tags',
    text: 'Tag conversation',
    icon: 'fas fa-tag',
    color: '#b77ce4',
  },
  {
    type: 'automation',
    action_type: 'map_property',
    text: 'Set contact property',
    icon: 'fas fa-pennant',
    color: '#00a4bd',
  },
  {
    type: 'automation',
    action_type: 'map_conversation_property',
    text: 'Set conversation property',
    icon: 'fas fa-pennant',
    color: '#00a4bd',
  },
  {
    type: 'automation',
    action_type: 'assign_conversation',
    text: 'Assign conversation',
    icon: 'fas fa-user-plus',
    color: '#ffac59',
  },
  {
    type: 'automation',
    action_type: 'close_conversation',
    text: 'Close conversation',
    icon: 'fas fa-check',
    color: '#ffac59',
  },
  // {
  //   type: 'automation',
  //   action_type: 'goal',
  //   text: 'Goal',
  //   icon: 'fas fa-trophy',
  //   color: '#48ebab',
  // },
  {
    type: 'enroll_to_another_bot',
    action_type: 'enroll_to_another_bot',
    text: 'Pass to another bot',
    icon: 'fas fa-link',
    color: '#ffac59',
  },
  {
    type: 'automation',
    action_type: 'disable_composer',
    text: 'Disable customer reply',
    icon: 'fad fa-comment-alt-slash',
    color: '#ffac59',
  },
];

export const conditionBlocks = [
  {
    type: 'condition',
    action_type: 'check_agent_availability',
    text: 'Check if team is online',
    icon: 'fas fa-users',
    color: '#b77ce4',
  },
  {
    type: 'condition',
    action_type: 'custom_audience',
    text: 'Matches a custom audience',
    icon: 'fas fa-split',
    color: '#ffac59',
  },
  {
    type: 'delay',
    text: 'Delay',
    icon: 'fas fa-clock',
    color: '#ff657f',
  },
  {
    type: 'note_block',
    text: 'Add a note',
    icon: 'fas fa-pencil',
    color: '#7a6ad1',
  },
];

export const disbledSourceLinkComponents = ['disable_composer'];

export const editNotAllowedComponents = [
  'close_conversation',
  'disable_composer',
];

export const froalaBaseConfig = {
  key: settings.FROALA_EDITOR_KEY,
  iconsTemplate: 'font_awesome_5r',
  pastePlain: true,
  toolbarBottom: true,
  toolbarSticky: false,
  attribution: false,
  charCounterCount: false,
  linkAlwaysBlank: true,
  htmlAllowedEmptyTags: [
    'textarea',
    'iframe',
    'object',
    'video',
    'style',
    'script',
    '.fa',
    '.fr-emoticon',
    '.fr-inner',
    'path',
    'line',
    'hr',
  ],
  linkAlwaysNoFollow: true,
  linkNoOpener: true,
  linkNoReferrer: true,
  linkInsertButtons: [],
  linkEditButtons: ['linkEdit', 'linkRemove', 'linkOpen'],
  linkList: [],
  htmlAllowedTags: [
    'p',
    'a',
    'strong',
    'em',
    'u',
    'br',
    'img',
    'span',
    'iframe',
  ],
  videoResponsive: true,
  heightMin: 150,
  imageDefaultWidth: 0,
  imageDefaultAlign: 'left',
  imageDefaultMargin: 0,
  imageDefaultDisplay: 'block',
  imageStyles: { full_width: 'Full Width' },
  imageResize: false,
  toolbarButtons: botToolbarButtons,
  imageEditButtons,
  videoInsertButtons,
  quickInsertEnabled: false,
  imageInsertButtons: ['imageUpload'],
  pluginsEnabled: ['draggable', 'emoticons', 'image', 'link', 'url', 'video'],
  imageUploadParam: 'attachment_image',
  events: {
    'image.uploaded': function (response) {
      // Parse response to get image url.
      const imgLink = JSON.parse(response).data.link;

      // Inserts image manually
      this.image.insert(imgLink, false, null, this.image.get(), response);
      this.image.insert(
        imgLink,
        false,
        { href: imgLink, changeurl: true },
        this.image.get(),
        response,
      );
      return false;
    },
    'image.error': () => {
      // TODO: Need to handle properly along with sentry
      message.error('Image not uploaded');
    },
    'video.error': () => {
      // TODO: Need to handle properly along with sentry
      message.error('Video not uploaded');
    },
    'video.linkError': () => {
      // TODO: Need to handle properly along with sentry
      message.error('Video not uploaded');
    },
  },
};
