import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  patchPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  trackEvent,
  unProcessableEntity,
} from '../../helpers/utility';
import { message, notification } from 'antd';
import settings from '../../settings';
import { store } from '../store';
import {
  constructBuilderData,
  loadBuilderData,
} from '../../containers/Messages/Bots/helper';
import displayActions from '../displayRules/actions';
import filterActions from '../filters/actions';
import { filterVariables } from '../constants';

export function* fetchAssistantList(params) {
  try {
    let searchValue = '',
      status = '',
      type = '',
      tagID = '',
      { assistantSortKey, assistantSortOrder } = store.getState().Assistants;
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().Assistants.searchedValue;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().Assistants.selectedAssistantStatus;
    }
    if (![null, undefined].includes(params.sortKey)) {
      assistantSortKey = params.sortKey;
    }
    if (![null, undefined].includes(params.sortOrder)) {
      assistantSortOrder = params.sortOrder;
    }
    if (params.assistantType) {
      type = params.assistantType;
    } else {
      type = store.getState().Assistants.assistantType;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = store.getState().Assistants.selectedBotTagID;
    }
    let queryParams = `bot_type=${type}&status=${status}&search_text=${encodeURIComponent(
      searchValue,
    )}&sort_key=${assistantSortKey}&sort_type=${assistantSortOrder}&tag_ids=${tagID}`;
    if (params.status === 'reorder') {
      queryParams = `bot_type=${type}&sort_key=priority&sort_type=asc`;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots?${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ASSISTANT_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_ASSISTANT_LIST_FAILURE,
    });
    message.error('Fetch bot list failed');
  }
}

export function* changeAssistantPosition(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/update_bot_priority`;
    const data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Bots priority update failed');
  }
}

export function* createAssistant(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots`;
    const { data } = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_ASSISTANT_SUCCESS,
      payload: data,
    });
    if (data.bot.bot_type === 'support_bot') {
      trackEvent('Created support bot');
      updateBrowserHistory(`emma/${data.bot.id}`);
    } else {
      trackEvent('Created custom bot');
      updateBrowserHistory(`bots/${data.bot.id}`);
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_ASSISTANT_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* deleteAssistant(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_ASSISTANT_LIST,
      // status: store.getState().Assistants.selectedAssistantStatus,
    });
    yield put({
      type: actions.DELETE_ASSISTANT_SUCCESS,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_ASSISTANT_FAILURE,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* appendAssistantList(params) {
  try {
    const { isReOrderForm } = store.getState().Assistants;
    let searchValue = '',
      status = '',
      type = '',
      tagID = '';
    const { payload } = params,
      { assistantSortKey, assistantSortOrder } = store.getState().Assistants;
    let lastBotId = '';
    if (payload && payload.lastBotId) {
      lastBotId = payload.lastBotId;
    }
    if (params.assistantType) {
      type = params.assistantType;
    } else {
      type = store.getState().Assistants.assistantType;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = store.getState().Assistants.selectedBotTagID;
    }
    let queryParams = `reorder=true&last_bot_id=${lastBotId}&status=all&bot_type=${type}`;

    if (!isReOrderForm) {
      if (params.searchValue) {
        searchValue = params.searchValue;
      } else {
        searchValue = store.getState().Assistants.searchedValue;
      }
      if (params.status) {
        status = params.status;
      } else {
        status = store.getState().Assistants.selectedAssistantStatus;
      }
      queryParams = `bot_type=${type}&status=${status}&search_text=${encodeURIComponent(
        searchValue,
      )}&last_bot_id=${lastBotId}&sort_key=${assistantSortKey}&sort_type=${assistantSortOrder}&tag_ids=${tagID}`;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots?${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_ASSISTANT_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.APPEND_ASSISTANT_LIST_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* duplicateAssistant(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${
      params.id
    }/duplicate`;
    let response = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.DUPLICATE_ASSISTANT_SUCCESS,
      payload: response.data,
    });
    if (response.data.bot.bot_type === 'support_bot') {
      updateBrowserHistory(`emma/${response.data.bot.id}`);
    } else {
      updateBrowserHistory(`bots/${response.data.bot.id}`);
    }
    message.success(response.message);
  } catch (error) {
    yield put({
      type: actions.DUPLICATE_ASSISTANT_FAILURE,
    });
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* updateAssistant(params) {
  let object = {
      bot: {},
    },
    id = params.id ? params.id : store.getState().Assistants.botDetail.bot.id;
  if (params.isEditorPage) {
    object.bot.bot_data = { diagram: params.data };
  }
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${id}`;
    let data = yield call(() => patchPromise(url, object));
    if (params.isEditorPage) {
      store.dispatch({
        type: actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS,
        builderLoading: false,
      });
      if (params.isChatbotTemplate) {
        yield put({
          type: actions.UPDATE_ASSISTANT_CHAT_BUILDER_SUCCESS,
          payload: data.data,
        });
      } else {
        yield put({
          type: actions.UPDATE_ASSISTANT_SUCCESS,
          payload: data.data,
        });
      }
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (params.isEditorPage) {
      store.dispatch({
        type: actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS,
        builderLoading: false,
      });
    }
    yield put({
      type: actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* fetchBotDetails(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${params.assistantId}`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ASSISTANT_BOT_DETAILS_SUCCESS,
      payload: data,
    });
    if (data.bot.bot_type === 'support_bot') {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.bot.display_conditions,
        filterName: filterVariables.supportBotKey,
      });
    } else {
      yield put({
        type: displayActions.SET_DISPLAY_RULES,
        payload: data.bot.display_conditions,
      });
    }
    if (data.bot.message_goal) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.bot.message_goal.goal_filter,
        filterName: filterVariables.botsKeyGoal,
      });
    }
    loadBuilderData(constructBuilderData(data.bot));
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* getUsersList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teams-users-list?include_current_user=true&sort_by=email`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ASSISTANT_USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('User details fetch failed');
    }
  }
}

export function* fetchMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/user_meetings_list?meeting_user_id=${
      params.payload
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_MEETING_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('User details fetch failed');
    }
  }
}
export function* updateAssistantSettings(params) {
  const id = params.id
    ? params.id
    : store.getState().Assistants.botDetail.bot.id;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${id}/update_settings`;
    const data = yield call(() => patchPromise(url, params.payload));
    store.dispatch({
      type: actions.UPDATE_ASSISTANT_SETTINGS_SUCCESS,
      payload: data.data,
    });
    store.dispatch({
      type: actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS,
      settingsLoading: false,
    });
    message.success(data.message);
  } catch (error) {
    store.dispatch({
      type: actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS,
      settingsLoading: false,
    });
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}
export function* updateAssistantDisplayRules(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${
      params.id
    }/update_display_conditions`;
    let data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_ASSISTANT_DISPLAY_RULES_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS,
      rulesLoading: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS,
      rulesLoading: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* updateAssistantStatus(params) {
  let object = {
      bot: { status: params.status },
    },
    id = params.id ? params.id : store.getState().Assistants.botDetail.bot.id;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${id}/update_status`;
    let data = yield call(() => patchPromise(url, object));

    if (params.fromTable) {
      yield put({
        type: actions.FETCH_ASSISTANT_LIST,
      });
    } else {
      yield put({
        type: actions.SET_ASSISTANT_EDITOR_STATUS_LOADING,
        payload: false,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (!params.fromTable) {
      yield put({
        type: actions.SET_ASSISTANT_EDITOR_STATUS_LOADING,
        payload: false,
      });
    }
    message.error(error.response.data.errors);
  }
}

export function* applyBotTags(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/apply_tag`;
    const responseData = yield call(() =>
      postPromise(url, params.payload.data),
    );
    yield put({
      type: actions.FETCH_ASSISTANT_LIST,
    });
    yield put({
      type: actions.APPLY_BOT_TAG_SUCCESS,
    });
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Bot tags apply failed');
    }
  }
}

export function* removeBotTags(param) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/remove_tag?feature_type=Bot&feature_id=${
      param.payload.id
    }&tag_id=${param.payload.tagId}`;
    const responseData = yield call(() => postPromise(url));
    yield put({
      type: actions.FETCH_ASSISTANT_LIST,
    });
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('remove bot tags failed');
    }
  }
}

export function* botPreview(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${
      params.botId
    }/get_greetings`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_BOT_PREVIEW_SUCCESS,
      payload: { id: params.botId, greetings: data.data },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* fetchUnansweredQuestionsList(params) {
  try {
    let searchedValue = store.getState().Assistants.searchedValue;
    if (params.searchValue) {
      searchedValue = params.searchValue;
    }
    let queryParams = `search_text=${encodeURIComponent(searchedValue)}`;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/support_bots/unanswered_questions?${queryParams}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_UNANSWERED_QUESTIONS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_UNANSWERED_QUESTIONS_LIST_FAILURE,
    });
    message.error('Fetch unanswered list failed');
  }
}

export function* fetchUnhelpfulResponsesList(params) {
  try {
    let searchedValue = store.getState().Assistants.searchedValue;
    if (params.searchValue) {
      searchedValue = params.searchValue;
    }
    let queryParams = `search_text=${encodeURIComponent(searchedValue)}`;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/support_bots/unhelpful_responses?${queryParams}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_UNHELPFUL_RESPONSES_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_UNHELPFUL_RESPONSES_LIST_FAILURE,
    });
    message.error('Fetch unanswered list failed');
  }
}

export function* appendUnansweredQuestionsList(params) {
  try {
    let searchValue = store.getState().Assistants.searchedValue;
    let { lastId } = params.payload;
    if (params.searchValue) {
      searchValue = params.searchValue;
    }
    let queryParams = `search_text=${encodeURIComponent(
      searchValue,
    )}&last_id=${lastId}`;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/support_bots/unanswered_questions?${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_UNANSWERED_QUESTIONS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.APPEND_UNANSWERED_QUESTIONS_LIST_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* appendUnhelpfulResponsesList(params) {
  try {
    let searchValue = store.getState().Assistants.searchedValue;
    let { lastId } = params.payload;
    if (params.searchValue) {
      searchValue = params.searchValue;
    }
    let queryParams = `search_text=${encodeURIComponent(
      searchValue,
    )}&last_id=${lastId}`;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/support_bots/unhelpful_responses?${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_UNHELPFUL_RESPONSES_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.APPEND_UNHELPFUL_RESPONSES_LIST_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* fetchSupportBotsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/support_bots`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SUPPORT_BOTS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_SUPPORT_BOTS_LIST_FAILURE,
    });
    message.error('Fetch unanswered question actions failed');
  }
}

export function* trainBot(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/support_bots/process_unanswered_question`;
    const response = yield call(() => postPromise(url, params.payload));
    message.success(response.message);
    yield put({
      type: actions.FETCH_UNANSWERED_QUESTIONS_LIST,
    });
    yield put({
      type: actions.TRAIN_BOT_SUCCESS,
      payload: params.questionId,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.TRAIN_BOT_FAILURE,
      payload: params.questionId,
    });
    message.error(error.response.data.errors);
  }
}
export function* deleteUnansweredQuestions(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/support_bots/delete_unanswered_question?unanswered_question_id=${
      params.id
    }`;
    const response = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_UNANSWERED_QUESTIONS_LIST,
    });
    yield put({
      type: actions.DELETE_UNANSWERED_QUESTIONS_SUCCESS,
      payload: params.id,
    });
    message.success(response.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* createAssistantBotFromTemplate(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots`;
    const { data } = yield call(() => postPromise(url, params.payload));
    updateBrowserHistory(`bots/${data.bot.id}`);
    yield put({
      type: actions.CREATE_ASSISTANT_BOT_FROM_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_ASSISTANT_BOT_FROM_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}
export function* checkEnrollBotData(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bots/${
      params.id
    }/check_bot_is_linkable?reference_id=${params.payload}`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.CHECK_ENROLL_BOT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CHECK_ENROLL_BOT_DATA_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* fetchEmmaSources(params) {
  try {
    yield put({
      type: actions.SET_SOURCE_TABLE_LOADER,
      payload: true,
    });
    let searchValue = '',
      sourceType = '',
      { sourceOrderBy, sourceOrderType } = store.getState().Assistants;
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().Assistants.sourceSearchValue;
    }
    if (![null, undefined].includes(params.sourceOrderBy)) {
      sourceOrderBy = params.sourceOrderBy;
    }
    if (![null, undefined].includes(params.sourceOrderType)) {
      sourceOrderType = params.sourceOrderType;
    }
    if (params.sourceType) {
      sourceType = params.sourceType;
    } else {
      sourceType = store.getState().Assistants.selectedSourceType;
    }
    sourceType = sourceType === 'all' ? '' : sourceType;
    const queryParams = `?order_by=${sourceOrderBy}&order_type=${sourceOrderType}&source_type=${sourceType}&search_content=${searchValue}`;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources${queryParams}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMMA_SOURCES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_SOURCE_TABLE_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch Emma Sources failed');
  }
}

export function* createSource(params) {
  const {setShowDocumentModal} = params;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources${
      params.isPdfUpload ? '/upload_document' : ''
    }`;
    const response = yield call(() => postPromise(url, params.payload));
    // params?.callback();
    yield put({
      type: actions.SET_SOURCE_SUBMIT_LOADER,
      payload: false,
    })
    yield put({
      type: actions.FETCH_EMMA_SOURCES,
    });
    yield put({
      type: actions.FETCH_SOURCES_COUNT,
    });
    notification.success({
      message: response.data.message.message,
      description: response.data.message.description,
      placement: 'topRight',
      duration: 15,
      className: 'p-5',
    });
    
    setShowDocumentModal && setShowDocumentModal(false);
    !params.isPdfUpload && updateBrowserHistory('support/emma/contents');
  } catch (error) {
    setShowDocumentModal && setShowDocumentModal(false);
    yield put({
      type: actions.SET_SOURCE_SUBMIT_LOADER,
      payload: false,
    })
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Create Source failed');
  }
}

export function* appendSourcesList(params) {
  try {
    yield put({
      type: actions.SET_SOURCE_TABLE_LOADER,
      payload: true,
    });
    const {
        sourceOrderBy,
        sourceOrderType,
        sourceSearchValue,
        selectedSourceType,
      } = store.getState().Assistants,
      queryParams = `?order_by=${sourceOrderBy}&order_type=${sourceOrderType}&source_type=${
        selectedSourceType === 'all' ? '' : selectedSourceType
      }&search_content=${sourceSearchValue}&offset=${params.offset}`;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources${queryParams}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_SOURCES_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_SOURCE_TABLE_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch AI Sources failed');
  }
}

export function* fetchSourceDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources/${params.id}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SOURCE_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    params.resetValues();
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch Source failed');
  }
}

export function* fetchSourcesCount() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources/source_counts`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SOURCES_COUNT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch Source count failed');
  }
}

export function* updateSourceDetails(params) {
  try {
    const { id, payload, setButtonLoader, isStatusChangeFromDrawer } = params;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources/${id}`;
    const response = yield call(() => patchPromise(url, payload));
    if (params.callback) {
      params.callback();
    }
    if (isStatusChangeFromDrawer) {
      yield put({
        type: actions.UPDATE_SELECTED_SOURCE_DETAILS,
        payload: { ...payload.emma_sources },
      });
    }
    yield put({
      type: actions.SET_SOURCE_SUBMIT_LOADER,
      payload: false,
    })
    yield put({
      type: actions.FETCH_EMMA_SOURCES,
    });
    yield put({
      type: actions.FETCH_SOURCES_COUNT,
    });
    setButtonLoader && setButtonLoader(false);
    message.success(response.message);
  } catch (error) {
    params.setButtonLoader && params.setButtonLoader(false);
    yield put({
      type: actions.SET_SOURCE_SUBMIT_LOADER,
      payload: false,
    })
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Update Source failed');
  }
}

export function* sourceStatusBulkUpdate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources/update_bulk_items`;
    const response = yield call(() => patchPromise(url, params.payload));
    params.callback();
    yield put({
      type: actions.FETCH_EMMA_SOURCES,
    });
    yield put({
      type: actions.FETCH_SOURCES_COUNT,
    });
    message.success(response.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Update Sources failed');
  }
}

export function* deleteEmmaSources(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/emma_sources/destroy_source_items`;
    const response = yield call(() => deletePromise(url, params.payload));
    if (params.callback) {
      params.callback();
    }
    yield put({
      type: actions.FETCH_EMMA_SOURCES,
    });
    yield put({
      type: actions.FETCH_SOURCES_COUNT,
    });
    message.success(response.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Delete Sources failed');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ASSISTANT_LIST, fetchAssistantList),
    takeEvery(actions.DELETE_ASSISTANT, deleteAssistant),
    takeEvery(actions.APPEND_ASSISTANT_LIST, appendAssistantList),
    takeEvery(actions.CHANGE_ASSISTANT_POSITION, changeAssistantPosition),
    takeEvery(actions.CREATE_ASSISTANT, createAssistant),
    takeEvery(actions.DUPLICATE_ASSISTANT, duplicateAssistant),
    takeEvery(actions.UPDATE_ASSISTANT, updateAssistant),
    takeEvery(actions.UPDATE_ASSISTANT_SETTINGS, updateAssistantSettings),
    takeEvery(
      actions.UPDATE_ASSISTANT_DISPLAY_RULES,
      updateAssistantDisplayRules,
    ),
    takeEvery(actions.FETCH_ASSISTANT_BOT_DETAILS, fetchBotDetails),
    takeEvery(actions.FETCH_ASSISTANT_USER_LIST, getUsersList),
    takeEvery(actions.FETCH_MEETING, fetchMeeting),
    takeEvery(actions.UPDATE_ASSISTANT_STATUS, updateAssistantStatus),
    takeEvery(actions.APPLY_BOT_TAG, applyBotTags),
    takeEvery(actions.REMOVE_BOT_TAG, removeBotTags),
    takeEvery(actions.FETCH_BOT_PREVIEW_DETAILS, botPreview),
    takeEvery(
      actions.FETCH_UNANSWERED_QUESTIONS_LIST,
      fetchUnansweredQuestionsList,
    ),
    takeEvery(
      actions.APPEND_UNANSWERED_QUESTIONS_LIST,
      appendUnansweredQuestionsList,
    ),
    takeEvery(
      actions.FETCH_UNHELPFUL_RESPONSES_LIST,
      fetchUnhelpfulResponsesList,
    ),
    takeEvery(
      actions.APPEND_UNHELPFUL_RESPONSES_LIST,
      appendUnhelpfulResponsesList,
    ),
    takeEvery(actions.FETCH_SUPPORT_BOTS_LIST, fetchSupportBotsList),
    takeEvery(actions.TRAIN_BOT, trainBot),
    takeEvery(actions.DELETE_UNANSWERED_QUESTIONS, deleteUnansweredQuestions),
    takeEvery(
      actions.CREATE_ASSISTANT_BOT_FROM_TEMPLATE,
      createAssistantBotFromTemplate,
    ),
    takeEvery(actions.CHECK_ENROLL_BOT_DATA, checkEnrollBotData),
    takeEvery(actions.FETCH_EMMA_SOURCES, fetchEmmaSources),
    takeEvery(actions.CREATE_SOURCE, createSource),
    takeEvery(actions.APPEND_SOURCES_LIST, appendSourcesList),
    takeEvery(actions.FETCH_SOURCE_DETAILS, fetchSourceDetails),
    takeEvery(actions.FETCH_SOURCES_COUNT, fetchSourcesCount),
    takeEvery(actions.UPDATE_SOURCE_DETAILS, updateSourceDetails),
    takeEvery(actions.SOURCE_STATUS_BULK_UPDATE, sourceStatusBulkUpdate),
    takeEvery(actions.DELETE_EMMA_SOURCES, deleteEmmaSources),
  ]);
}
