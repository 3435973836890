import React from 'react';
import { openLinkInNewTab } from '../../../../../helpers/utility';
import '../style.scss';

const ButtonComponent = ({ onSubmit, onSheet, component, canvasLoading }) => {
  const handleURL = (url) => {
    openLinkInNewTab(url);
  };
  const handleSubmit = (componentId) => {
    if (componentId) {
      onSubmit(componentId);
    }
  };
  const handleSheet = (component) => {
    if (component) {
      onSheet(component);
    }
  };
  const isLoading = canvasLoading && canvasLoading[component.id];
  return (
    <button
      className={`${component.style}${component.disabled ? ' disabled' : ''}${
        isLoading ? ' loading' : ''
      }`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
      {...(component.action && component.action.type
        ? { 'data-action-type': component.action.type }
        : {})}
      {...(component.action && component.action.url
        ? { 'data-action-url': component.action.url }
        : {})}
      {...(component.action &&
      component.action.type === 'url' &&
      component.action.url
        ? {
            onClick: () => {
              handleURL(component.action && component.action.url);
            },
          }
        : {})}
      {...(component.action && component.action.type === 'submit'
        ? {
            onClick: () => {
              handleSubmit(component.id);
            },
          }
        : {})}
      {...(component.action && component.action.type === 'sheet'
        ? {
            onClick: () => {
              handleSheet(component);
            },
          }
        : {})}
    >
      {component.label}
      {isLoading && <div className="messenger-card-loader" />}
    </button>
  );
};

export default ButtonComponent;
