import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Skeleton } from 'antd';
import { isEqual } from 'lodash';

import TextComponent from './Components/TextComponent';
import DataTableComponent from './Components/DataTableComponent';
import ImageComponent from './Components/ImageComponent';
import SpacerComponent from './Components/SpacerComponent';
import DividerComponent from './Components/DividerComponent';
import ButtonComponent from './Components/ButtonComponent';
import InputComponent from './Components/InputComponent';
import TextAreaComponent from './Components/TextAreaComponent';
import ListComponent from './Components/ListComponent';
import SingleSelectComponent from './Components/SingleSelectComponent';
import CheckboxComponent from './Components/CheckboxComponent';
import DropdownComponent from './Components/DropdownComponent';
import actions from '../../../../redux/chat/actions';
import '../style.scss';
import { updateBrowserHistory } from '../../../../helpers/utility';
import { usePrevious } from '../../../../helpers/hooks';

const InboxWidget = (props) => {
  const {
    preview,
    response,
    onSubmit,
    onSheet,
    appID,
    canvasLoading,
    onConfigureApp,
    installedState,
    conversationId,
    isConfiguration,
    loader,
  } = props;
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [renderCount, setRenderCount] = useState(0);
  const previousResponse = usePrevious(response);
  const previousCanvasLoading = usePrevious(canvasLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEqual(previousResponse, response)) {
      setLoading(false);
      setRenderCount(renderCount + 1);
    }
    if (!isEqual(previousCanvasLoading, canvasLoading)) {
      setLoading(false);
    }
    if (Array.isArray(response?.canvas?.content?.components)) {
      let inputValues = {};
      response.canvas.content.components.forEach((data) => {
        if (data.value) {
          inputValues = { ...inputValues, [data.id]: data.value };
        }
      });
      setInputValues(inputValues);
    }
  }, [response, canvasLoading]);
  useEffect(() => {
    if (conversationId) reinitialize();
  }, [conversationId]);

  const onChange = (key, value) => {
      setInputValues({ ...inputValues, [key]: value });
    },
    onFormSubmit = (componentId) => {
      if (!preview) {
        onSubmit({
          inbox_app_id: appID,
          component_id: componentId,
          input_values: inputValues,
          current_canvas: response.canvas,
        });
        setLoading(true);
        setInputValues({});
      }
    },
    reinitialize = () => {
      if (isConfiguration) {
        onConfigureApp();
      } else {
        if (installedState !== 'reauthenticate') {
          dispatch({
            type: actions.INITIALIZE_INBOX_APP_DATA,
            payload: {
              inbox_app_id: appID,
              conversation_id: conversationId,
            },
          });
        }
      }
    };

  const onSheetSubmit = (component) => {
    if (!preview) {
      onSheet({
        inbox_app_id: appID,
        component: component,
        current_canvas: response.canvas,
      });
    }
  };

  if (installedState === 'reauthenticate') {
    return (
      <div
        className="inbox-widget-card error-card gist-fade-in"
        key={renderCount}
      >
        <div className="gist-card-component">
          <p className="header">Authentication required</p>
          <p className="description">
            Please authenticate to continue using this application.
          </p>
          <div className="button-container">
            <Button
              key="back"
              className="btn btn-secondary-outline btn-sm"
              onClick={() => updateBrowserHistory('apps', { appID })}
            >
              Authenticate
            </Button>
          </div>
        </div>
      </div>
    );
  }
  if (loader) {
    return (
      <Skeleton active title={false} paragraph={{ rows: 2, width: '100%' }} />
    );
  }
  if (!response || (response && Object.keys(response).length === 0)) {
    return (
      <div
        className="inbox-widget-card error-card gist-fade-in"
        key={renderCount}
      >
        <div className="gist-card-component">
          <p className="header">Something went wrong</p>
          <p className="description">
            If this problem persists, try contacting the developer of this app
            for help and support.
          </p>
          <div className="button-container">
            <Button
              key="back"
              className="btn btn-secondary-outline btn-sm"
              onClick={reinitialize}
            >
              Try again
            </Button>
          </div>
        </div>
      </div>
    );
  }

  let components = [];
  if (response && response.canvas && response.canvas.content)
    components = response.canvas.content.components;

  return (
    <div
      className={`inbox-widget-card${!preview ? ' gist-fade-in' : ''}${
        loading ? ' loading' : ''
      }`}
      key={renderCount}
    >
      {Array.isArray(components) &&
        components.map((component, index) => (
          <div
            className={`gist-card-component ${component.type}-component${
              component.bottom_margin === 'none' ? ' margin-bottom-none' : ''
            }${index === 0 ? ' first-component' : ''}${
              index === components.length - 1 ? ' last-component' : ''
            }`}
            key={index}
          >
            {component.type === 'text' && (
              <TextComponent component={component} index={index} />
            )}

            {component.type === 'data-table' && (
              <DataTableComponent component={component} index={index} />
            )}

            {component.type === 'image' && (
              <ImageComponent component={component} index={index} />
            )}

            {component.type === 'spacer' && (
              <SpacerComponent component={component} index={index} />
            )}

            {component.type === 'divider' && (
              <DividerComponent component={component} index={index} />
            )}

            {component.type === 'button' && (
              <ButtonComponent
                component={component}
                index={index}
                onSubmit={onFormSubmit}
                onSheet={onSheetSubmit}
                inboxAppID={appID}
                canvasLoading={canvasLoading}
              />
            )}

            {component.type === 'input' && (
              <InputComponent
                component={component}
                index={index}
                onChange={onChange}
                onSubmit={onFormSubmit}
                inboxAppID={appID}
                canvasLoading={canvasLoading}
              />
            )}

            {component.type === 'textarea' && (
              <TextAreaComponent
                component={component}
                index={index}
                onChange={onChange}
              />
            )}

            {component.type === 'list' && (
              <ListComponent
                component={component}
                index={index}
                onSubmit={onFormSubmit}
                onSheet={onSheetSubmit}
                inboxAppID={appID}
                canvasLoading={canvasLoading}
              />
            )}

            {component.type === 'single-select' && (
              <SingleSelectComponent
                component={component}
                index={index}
                onChange={onChange}
                onSubmit={onFormSubmit}
              />
            )}

            {component.type === 'checkbox' && (
              <CheckboxComponent
                component={component}
                index={index}
                onChange={onChange}
              />
            )}

            {component.type === 'dropdown' && (
              <DropdownComponent
                component={component}
                index={index}
                onChange={onChange}
              />
            )}
          </div>
        ))}
    </div>
  );
};

InboxWidget.defaultProps = {
  onSubmit: () => {},
  canvasLoading: {},
  loader: false,
  isConfiguration: false,
  onConfigureApp: () => {},
};

export default InboxWidget;
