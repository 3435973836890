import React from 'react';
import { openLinkInNewTab } from '../../../../../helpers/utility';
import '../style.scss';

const ListComponent = ({ component, onSubmit, onSheet, canvasLoading }) => {
  const handleURL = (url) => {
    openLinkInNewTab(url);
  };
  const handleClick = (componentId) => {
    if (componentId) {
      onSubmit(componentId);
    }
  };
  const handleSheet = (component) => {
    if (component) {
      onSheet(component);
    }
  };
  return (
    <div className={`list${component.disabled ? ' disabled' : ''}`}>
      {component.items &&
        component.items.map((item, index) => (
          <div
            className={`list-item${item.disabled ? ' disabled' : ''}`}
            key={index}
            {...(item.id ? { 'data-component-id': item.id } : {})}
            {...(item.action && item.action.type
              ? { 'data-action-type': item.action.type }
              : {})}
            {...(item.action && item.action.url
              ? { 'data-action-url': item.action.url }
              : {})}
            {...(item.action && item.action.type === 'url' && item.action.url
              ? {
                  onClick: () => {
                    handleURL(item.action && item.action.url);
                  },
                }
              : {})}
            {...(item.action && item.action.type === 'submit'
              ? {
                  onClick: () => {
                    handleClick(item.id);
                  },
                }
              : {})}
            {...(item.action && item.action.type === 'sheet'
              ? {
                  onClick: () => {
                    handleSheet(item);
                  },
                }
              : {})}
          >
            {item.image && (
              <div className="image-container">
                <img
                  className={`final-image${item.rounded_image ? ' round' : ''}`}
                  {...(item.image ? { src: item.image } : {})}
                  {...(item.image_width ? { width: item.image_width } : {})}
                  {...(item.image_height ? { height: item.image_height } : {})}
                  alt={''}
                />
              </div>
            )}
            <div className="text-container">
              {item.title && <div className="title">{item.title}</div>}

              {item.subtitle && (
                <div className="subtitle">
                  <div className="secondary-text">{item.subtitle}</div>

                  {item.tertiary_text && (
                    <div className="tertiary-text">{item.tertiary_text}</div>
                  )}
                </div>
              )}
            </div>
            {canvasLoading && canvasLoading[item.id] && (
              <div className="messenger-card-loader" />
            )}
          </div>
        ))}
    </div>
  );
};

export default ListComponent;
