import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchProjectSecretKey,
  getPromise,
  unAuthorisedRedirection,
} from '../../../helpers/utility';
import settings from '../../../settings/index';
import actions from './actions';
import { store } from '../../store';
import {
  dateDifference,
  sagaValueCheck,
} from '../../../components/Reports/helper';
import { message } from 'antd';
import NewsCenter from '../../newscenter/reducer';

export function* fetchNewConversationReports(params) {
  try {
    const { fromDate, toDate, timeZone, teamId, teamMateId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/new_inbound_conversations?from=${fromDate}&to=${toDate}&time_zone=${timeZone}&team_id=${teamId}&teammate_id=${teamMateId}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_NEW_CONVERSATION_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_NEW_CONVERSATION_REPORTS_FAILURE,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_NEW_CONVERSATION_REPORTS_FAILURE,
    });
  }
}

export function* fetchClosedConversationReports(params) {
  try {
    const { fromDate, toDate, timeZone, teamId, teamMateId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/closed_conversations?from=${fromDate}&to=${toDate}&time_zone=${timeZone}&team_id=${teamId}&teammate_id=${teamMateId}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_CLOSED_CONVERSATION_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_CLOSED_CONVERSATION_REPORTS_FAILURE,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_CLOSED_CONVERSATION_REPORTS_FAILURE,
    });
  }
}

export function* fetchMedianFirstResponseReports(params) {
  try {
    const { fromDate, toDate, timeZone, teamId, teamMateId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/median_first_response_time?from=${fromDate}&to=${toDate}&time_zone=${timeZone}&team_id=${teamId}&teammate_id=${teamMateId}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_FAILURE,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_FAILURE,
    });
  }
}

export function* fetchMedianCloseReports(params) {
  try {
    const { fromDate, toDate, timeZone, teamId, teamMateId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/median_time_to_close?from=${fromDate}&to=${toDate}&time_zone=${timeZone}&team_id=${teamId}&teammate_id=${teamMateId}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_MEDIAN_CLOSE_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_MEDIAN_CLOSE_REPORTS_FAILURE,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_MEDIAN_CLOSE_REPORTS_FAILURE,
    });
  }
}

export function* fetchTeamsAndTeammatesList(params) {
  try {
    const { fromDate, toDate, timeZone, teamId, teamMateId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/teams_and_teammates?from=${fromDate}&to=${toDate}&time_zone=${timeZone}&team_id=${teamId}&teammate_id=${teamMateId}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_TEAMS_AND_TEAMMATES_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_TEAMS_AND_TEAMMATES_LIST_FAILURE,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_TEAMS_AND_TEAMMATES_LIST_FAILURE,
    });
  }
}

export function* fetchRepliedConversationsReports(params) {
  try {
    const { fromDate, toDate, timeZone, teamId, teamMateId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/conversation_replies?from=${fromDate}&to=${toDate}&time_zone=${timeZone}&team_id=${teamId}&teammate_id=${teamMateId}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_REPLIED_CONVERSATION_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_REPLIED_CONVERSATION_REPORTS_FAILURE,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_REPLIED_CONVERSATION_REPORTS_FAILURE,
    });
  }
}

export function* fetchCustomersReachedReports(params) {
  try {
    const { fromDate, toDate, timeZone, teamId, teamMateId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/customers_reached?from=${fromDate}&to=${toDate}&time_zone=${timeZone}&team_id=${teamId}&teammate_id=${teamMateId}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_CUSTOMERS_REACHED_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_CUSTOMERS_REACHED_REPORTS_FAILURE,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_CUSTOMERS_REACHED_REPORTS_FAILURE,
    });
  }
}

export function* fetchAuthors() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/users_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ARTICLE_FEEDBACK_AUTHOR_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch Authors failed');
    }
  }
}

export function* fetchArticleFeedbackReports(params) {
  try {
    const {
      fromDate,
      toDate,
      timeZone,
      rating,
      author,
      has_comment,
    } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.articleFeedbackReportFilterChanges,
    );

    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/reports/knowledge_base/article_feedbacks?from=${fromDate}&to=${toDate}&time_zone=${timeZone}${
      has_comment && has_comment !== 'all' ? `&has_comment=${has_comment}` : ''
    }${rating && rating !== 'all' ? `&rating=${rating}` : ''}${
      author && author !== 'all' ? `&author_id=${author}` : ''
    }`;
    const data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_ARTICLE_FEEDBACK_REPORTS_SUCCESS,
      payload: data.data.article_feedbacks,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_ARTICLE_FEEDBACK_REPORTS_FAILURE,
    });
  }
}
export function* fetchSatisfactionRatingsMetrics(params) {
  try {
    const {
      fromDate,
      toDate,
      timeZone,
      rating,
      has_comment,
      user,
    } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.satisfactionRatingFilters,
    );
    const { page } = params;

    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/reports/conversations/satisfaction_ratings_metrics?from=${fromDate}&to=${toDate}&time_zone=${timeZone}`;
    if (has_comment && has_comment !== 'all') {
      url = `${url}&has_comment=${has_comment}`;
    }
    if (rating && rating !== 'all') {
      url = `${url}&rating=${rating}`;
    }
    if (user && user !== 'all') {
      url = `${url}&user_id=${user}`;
    }
    if (page) {
      url = `${url}&page=${page}`;
    }
    const data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_SATISFACTION_RATINGS_METRICS_SUCCESS,
      payload: data,
      page: page,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_SATISFACTION_RATINGS_METRICS_FAILURE,
    });
  }
}
export function* fetchSatisfactionRatingsCount(params) {
  try {
    const {
      fromDate,
      toDate,
      timeZone,
      rating,
      has_comment,
      user,
    } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.satisfactionRatingFilters,
    );

    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/reports/conversations/satisfaction_ratings_count?from=${fromDate}&to=${toDate}&time_zone=${timeZone}`;
    if (has_comment && has_comment !== 'all') {
      url = `${url}&has_comment=${has_comment}`;
    }
    if (rating && rating !== 'all') {
      url = `${url}&rating=${rating}`;
    }
    if (user && user !== 'all') {
      url = `${url}&user_id=${user}`;
    }
    const data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_SATISFACTION_RATINGS_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
  }
}

export function* fetchSupportBotMetricsReports(params) {
  try {
    const { fromDate, toDate, timeZone } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/support_bot_metrics/resolved_conversations?from=${fromDate}&to=${toDate}&time_zone=${timeZone}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_SUPPORT_BOT_METRICS_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_SUPPORT_BOT_METRICS_REPORTS_SUCCESS,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_SUPPORT_BOT_METRICS_REPORTS_FAILURE,
    });
  }
}

export function* fetchSupportBotCoverageReports(params) {
  try {
    const { fromDate, toDate, timeZone } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/support_bot_metrics/bot_coverage/${
        params.action_type
      }?from=${fromDate}&to=${toDate}&time_zone=${timeZone}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_SUPPORT_BOT_COVERAGE_REPORTS_SUCCESS,
        payload: data.data,
        action_type: params.action_type,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_SUPPORT_BOT_COVERAGE_REPORTS_FAILURE,
        action_type: params.action_type,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_SUPPORT_BOT_COVERAGE_REPORTS_FAILURE,
      action_type: params.action_type,
    });
  }
}

export function* fetchSupportResolutionRateReports(params) {
  try {
    const { fromDate, toDate, timeZone } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/support_bot_metrics/resolution_rate?from=${fromDate}&to=${toDate}&time_zone=${timeZone}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_SUCCESS,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_FAILURE,
    });
  }
}

export function* fetchSupportBotListOfAnswersReports(params) {
  try {
    const { fromDate, toDate, timeZone } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/conversations/support_bot_metrics/${
        params.action_type
      }?from=${fromDate}&to=${toDate}&time_zone=${timeZone}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_SUCCESS,
        payload: data.data,
        action_type: params.action_type,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_FAILURE,
        action_type: params.action_type,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_FAILURE,
      action_type: params.action_type,
    });
  }
}

export function* fetchEmailHealthReports() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/reports/emails/email_metrics/email_overall_reports`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMAIL_HEALTH_REPORTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_EMAIL_HEALTH_REPORTS_FAILURE,
    });
  }
}

export function* fetchEmaiMetricsReports(params) {
  try {
    const { fromDate, toDate, timeZone, mailFilterId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/reports/emails/email_metrics/${
        params.action_type
      }?from=${fromDate}&to=${toDate}&time_zone=${timeZone}${
        mailFilterId ? `&mail_filter_id=${mailFilterId}` : ''
      }`;

      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_EMAIL_METRICS_REPORTS_SUCCESS,
        payload: data.data,
        action_type: params.action_type,
      });
    } else {
      message.error('Please select a date range within 1 year');
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_EMAIL_METRICS_REPORTS_FAILURE,
      action_type: params.action_type,
    });
  }
}

export function* fetchNewsMetricsReports(params) {
  try {
    const { fromDate, toDate, timeZone, newsItemId } = sagaValueCheck(
      params.payload,
      store.getState().InboxReports.inboxReportFilterChanges,
    );
    if (dateDifference(fromDate, toDate)) {
      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/news_items/${newsItemId}/${
        params.action_type
      }?from=${fromDate}&to=${toDate}&time_zone=${timeZone}`;

      const data = yield call(() => getPromise(url));
     
      yield put({
        type: actions.FETCH_NEWS_METRICS_REPORTS_SUCCESS,
        payload: data.data,
        action_type: params.action_type,
      });
    } else {
      message.error('Please select a date range within 1 year');
      yield put({
        type: actions.FETCH_NEWS_METRICS_REPORTS_STOP_LOADER,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_NEWS_METRICS_REPORTS_FAILURE,
      action_type: params.action_type,
    });
  }
}

export function* fetchNewsRecipientsData(params) {
  try {
    if(params?.menu_type){
      const state = store.getState();
      const { timeZone } = state.InboxReports.inboxReportFilterChanges;
      const { newsRecipientsDataCount } = state.InboxReports;
      const { sortOrder: defaultSortOrder, searchText: defaultSearchText, reaction: defaultReaction } = state.NewsCenter.reportFilters;
      const pagination = params.pagination || 0;
      const sortValue = params.sortOrder || defaultSortOrder || '';
      const searchText = params.searchText || defaultSearchText || '';
      const reaction = params.reaction || defaultReaction || '';
      
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/news_items/${params.id}/load_people_data?metrics_type=${
        params.menu_type}&time_zone=${timeZone}&start=${pagination}${sortValue ? `&sort_type=${sortValue}` : ''}${
        searchText ? `&search_text=${searchText}` : ''}${
        (params.menu_type === 'reaction' && reaction && reaction !== 'all') ? `&reaction=${reaction}` : ''}`;
      
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_NEWS_RECIPIENT_DATA_SUCCESS,
        payload: data?.data?.person_data,
      });
      
      const countDetails = {
        filter_count: data?.data?.filter_count
      };
      yield put({
        type: actions.FETCH_NEWS_RECIPIENT_DATA_COUNT_SUCCESS,
        payload: countDetails,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_NEWS_RECIPIENT_DATA_FAILURE,
    });
  }
}

export function* fetchNewsRecipientsDataCount(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/news_items/${params.payload}/count-data`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NEWS_RECIPIENT_DATA_COUNT_SUCCESS,
      payload: data?.data?.count_details,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.FETCH_NEWS_RECIPIENT_DATA_COUNT_FAILURE,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.FETCH_NEW_CONVERSATION_REPORTS,
      fetchNewConversationReports,
    ),
    takeEvery(
      actions.FETCH_CLOSED_CONVERSATION_REPORTS,
      fetchClosedConversationReports,
    ),
    takeEvery(
      actions.FETCH_MEDIAN_FIRST_RESPONSE_REPORTS,
      fetchMedianFirstResponseReports,
    ),
    takeEvery(actions.FETCH_MEDIAN_CLOSE_REPORTS, fetchMedianCloseReports),
    takeEvery(
      actions.FETCH_TEAMS_AND_TEAMMATES_LIST,
      fetchTeamsAndTeammatesList,
    ),
    takeEvery(
      actions.FETCH_REPLIED_CONVERSATION_REPORTS,
      fetchRepliedConversationsReports,
    ),
    takeEvery(
      actions.FETCH_CUSTOMERS_REACHED_REPORTS,
      fetchCustomersReachedReports,
    ),
    takeEvery(actions.FETCH_ARTICLE_FEEDBACK_AUTHOR_LIST, fetchAuthors),
    takeEvery(
      actions.FETCH_ARTICLE_FEEDBACK_REPORTS,
      fetchArticleFeedbackReports,
    ),
    takeEvery(
      actions.FETCH_SATISFACTION_RATINGS_METRICS,
      fetchSatisfactionRatingsMetrics,
    ),
    takeEvery(
      actions.FETCH_SATISFACTION_RATINGS_COUNT,
      fetchSatisfactionRatingsCount,
    ),
    takeEvery(
      actions.FETCH_SUPPORT_BOT_METRICS_REPORTS,
      fetchSupportBotMetricsReports,
    ),
    takeEvery(
      actions.FETCH_SUPPORT_BOT_COVERAGE_REPORTS,
      fetchSupportBotCoverageReports,
    ),
    takeEvery(
      actions.FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS,
      fetchSupportResolutionRateReports,
    ),
    takeEvery(
      actions.FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS,
      fetchSupportBotListOfAnswersReports,
    ),
    takeEvery(actions.FETCH_EMAIL_HEALTH_REPORTS, fetchEmailHealthReports),
    takeEvery(actions.FETCH_EMAIL_METRICS_REPORTS, fetchEmaiMetricsReports),
    takeEvery(actions.FETCH_NEWS_METRICS_REPORTS, fetchNewsMetricsReports),
    takeEvery(actions.FETCH_NEWS_RECIPIENT_DATA, fetchNewsRecipientsData),
    takeEvery(actions.FETCH_NEWS_RECIPIENT_DATA_COUNT, fetchNewsRecipientsDataCount),
  ]);
}
