import React from 'react';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import PublicRoutes from './router';
import Boot from './redux/boot';
import { message } from 'antd';
import * as Sentry from '@sentry/react';

message.config({
  duration: 2,
  maxCount: 1,
});

const DashApp = () => (
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
      <PublicRoutes history={history} />
    </Provider>
  </Sentry.ErrorBoundary>
);
Boot()
  .then(() => DashApp())
  // eslint-disable-next-line no-console
  .catch((error) => console.error(error));

export default DashApp;
