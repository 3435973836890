import React, { Component, Fragment } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { fetchProjectSecretKey } from '../../helpers/utility';
import { store } from '../../redux/store';
import actions from '../../redux/sharedReducers/actions';

const { Sider } = Layout;

class SupportBotSidebar extends Component {
  constructor(props) {
    super(props);
    this.botMenu = [
      {
        title: 'Setup',
        url: 'setup',
        key: 'setup',
      },
      {
        title: 'Custom answers',
        url: 'answers',
        key: 'answers',
      },
      {
        title: 'Content library',
        url: 'contents',
        key: 'contents',
      },
    ];
    this.improveMenu = [
      {
        title: 'Unanswered Questions',
        url: 'unanswered',
        key: 'unanswered',
      },
      {
        title: 'Unhelpful',
        url: 'unhelpful',
        key: 'unhelpful',
      },
    ];
  }
  menuClick = e => {
    store.dispatch({
      type: actions.SET_SUPPORT_BOT_SIDEBAR_ACTIVE,
      key: e.keyPath,
    });
  };

  render() {
    const { supportBotSidebarActiveKey } = this.props;
    return (
      <Fragment>
        <Sider className="support-bot-sider">
          <div className="heading">Emma</div>
          <Menu
            mode="inline"
            selectedKeys={supportBotSidebarActiveKey}
            style={{ height: '100%', borderRight: 0 }}
          >
            {this.botMenu.map(data => (
              <Menu.Item key={data.key} onClick={this.menuClick}>
                {data.url && (
                  <>
                    <NavLink
                      to={`/projects/${fetchProjectSecretKey()}/support/emma/${
                        data.url
                      }`}
                    />
                    {data.title}
                  </>
                )}
              </Menu.Item>
            ))}

            <div className="separate-line" />
            <h4>Improve</h4>

            {this.improveMenu.map(data => (
              <Menu.Item key={data.key} onClick={this.menuClick}>
                {data.url && (
                  <>
                    <NavLink
                      to={`/projects/${fetchProjectSecretKey()}/support/emma/${
                        data.url
                      }`}
                    />
                    {data.title}
                  </>
                )}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      </Fragment>
    );
  }
}

export default SupportBotSidebar;
