import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Tooltip } from 'antd';
import TopNavBar from '../../components/TopNavBar/TopNavBar';
import authAction from '../../redux/auth/actions';
import { history, store } from '../../redux/store';
import AppRouter from './AppRouter';
import socket from '../../helpers/socket';
import {
  socketChannels,
  newMessageSound,
  newConversationSound,
  shortcutKeys,
  shortcutKeyCodes,
  osCTRLkey,
  shortcutRoutesMappedToAction,
  shortcutInboxTableRoutesMappedToAction,
} from './constants';
import settings from '../../settings';
import {
  extractCurrentUserId,
  fetchProjectSecretKey,
  fetchTeamRestrictedFeatures,
  setTableState,
  profanityFilter,
  getOrigin,
  getAppDetail,
  extractConversationIdentifierFromUrl,
  fetchRestrictedFeatures,
  extractConversationIdentifier,
  initiatePreferColorScheduleListeners,
  updateBrowserHistory,
  extractCurrentUserSecret,
  canWeAccess,
  extractCurrentPersonId,
  handleNotAuthorizedModal,
  isAllowAllUser,
  extractPreviousConversationIdentifier,
  handleImageAttachment,
  handleUploadAttachment,
  getLastPersonOrAgentMessage,
  activateChatRoom,
} from '../../helpers/utility';
import { TopBarNotification } from '../../components/TopBarNotification/TopBarNotification';
import contactsActions from '../../redux/contacts/actions';
import filterActions from '../../redux/filters/actions';
import CRMActions from '../../redux/CRM/actions';
import SideBar from '../../components/Settings/SideBar/SideBar';
import { NotificationBar } from '../../components/TopBarNotification/NotificationBar';
import { bot } from '../../components/Chat/Messages/constants';
import IdleTimer from 'react-idle-timer';
import { menu, userRestriction } from '../../components/TopNavBar/constants';
import settingsActions from '../../redux/settings/actions';
import chatActions from '../../redux/chat/actions';
import ReportSideBar from '../../components/Reports/ReportSideBar';
import MessageSideBar from '../../containers/Messages/Email/Shared/Sidebar';
import NotAuthorizedModal from '../../components/Shared/NotAuthorizedModal';
import OmniSearch from '../../components/Shared/OmniSearch';
import Hotkeys from 'react-hot-keys';
import CreateContactModal from '../../components/Contacts/UsersListTable/CreateContactModal';
import CreateDealDrawer from '../../components/CRM/CreateDealDrawer';
import SelectChannel from '../../components/Shared/Messages/SelectChannel';
import messageAction from '../../redux/messages/actions';
import sharedActions from '../../redux/sharedReducers/actions';
import { spamRestrictOmniSearchKeys } from '../../components/constants';
import jQuery from 'jquery';
import KeyboardShortcuts from '../../components/Shared/KeyboardShortcuts';
import SupportBotSidebar from '../SupportBot/SupportBotSidebar';
import isElectron from 'is-electron';
import { socialMediaRestriction } from '../../components/Chat/Messages/MessageComposer';
import actions from '../../redux/chat/actions';
import kbActions from '../../redux/knowledgeBase/actions';
import TaskWidget from '../../components/GettingStarted/TaskWidget/TaskWidget';
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import { requestPermission } from '../../firebase/firebaseConfig';
import { navigateToInboxAdvancedSearch } from '../../redux/chat/actions';

let pingInterval = null,
  isIdle = false,
  inbox_room = null,
  agents_status_room = null,
  unread_message_count_room = null,
  notification_room = null,
  settings_room = null,
  agent_inbox_room = null;

function displaySidebar(projectBlocked, displaySettingsSideBar) {
  return !projectBlocked && displaySettingsSideBar;
}

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displaySupportBotSidebar: false,
      displaySettingsSideBar: false,
      displayReportSidebar: false,
      displayMessagesSidebar: false,
      displayDealDrawer: false,
    };
    window.Offline.on('up', () => {
      // refreshConversationPage(); refresh the conversation in socket connect method
      store.dispatch({
        type: chatActions.DETECT_NETWORK_STATUS,
        status: 'online',
      });
      startPing();
      startInterval();
    });

    window.Offline.on('down', () => {
      store.dispatch({
        type: chatActions.DETECT_NETWORK_STATUS,
        status: 'offline',
      });
      stopPing();
    });

    const psk = fetchProjectSecretKey();
    inbox_room = `${psk}/inbox`;
    agents_status_room = `${psk}/${extractCurrentUserId()}`;
    unread_message_count_room = psk;
    notification_room = `users/${extractCurrentUserId()}`;
    settings_room = `${psk}/settings`;
    agent_inbox_room = `${psk}/inbox/${extractCurrentUserSecret()}`;

    function startPing() {
      if (!isIdle) {
        store.dispatch({ type: authAction.PING_AGENT_STATUS });
      }
    }

    function stopPing() {
      if (pingInterval !== null) {
        clearInterval(pingInterval);
        pingInterval = null;
      }
    }
    function startInterval() {
      pingInterval = setInterval(startPing, settings.PING_API_INTERVAL);
    }

    // if (extractUserProfileData().agent_status === 'online') { Reason for commanded last ping not update when away mode
    startPing();
    startInterval();
    // }

    // socket.on(socketChannels.connectError, function() {
    //   socket.io.opts.transports = ['polling', 'websocket'];
    // });

    let isEmitRoomsCalled = false;
    let connectAttempts = 0;
    if (socket.connected && !isEmitRoomsCalled) {
      emitRooms();
      isEmitRoomsCalled = true;
      connectAttempts++;
    }

    socket.on(socketChannels.connect, function() {
      if (!isEmitRoomsCalled || connectAttempts > 0) {
        emitRooms();
      }
      if (connectAttempts > 0) {
        //reload the conversations when conversations actions performed in client side and the socket has disconnected and connected.
        const isConversationActive = window.location.pathname.includes(
          `/${fetchProjectSecretKey()}/conversations/`,
        );
        if (isConversationActive) {
          // Empty the conversation reducer data
          store.dispatch({
            type: chatActions.EMPTY_CONVERSATION_REDUCER_DATA,
          });
          props.fetchConversations({
            chatRoomActivation: true,
            emptyChatRoom: true,
            conversationIdentifier: extractConversationIdentifierFromUrl(),
            avoidLoading: true,
          });
          props.fetchCurrentConversationMessages({
            cid: extractConversationIdentifierFromUrl(),
          });
          props.fetchAssigneeList();
        }
      }
      connectAttempts++;
    });

    function emitRooms() {
      // Connected, lets sign-up for to receive messages in this room
      const permission = canWeAccess(userRestriction.inbox);
      if (permission.all) {
        socket.emit('room', inbox_room);
      } else {
        socket.emit('room', agent_inbox_room);
      }
      socket.emit('room', agents_status_room);
      socket.emit('room', unread_message_count_room);
      socket.emit('room', notification_room);
      socket.emit('room', settings_room);
    }

    socket.on(socketChannels.online, function() {
      startPing();
      startInterval();
    });

    socket.on(socketChannels.offline, function() {
      stopPing();
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userPermissions.can_manage_inbox !==
      this.props.userPermissions.can_manage_inbox
    ) {
      const permission = canWeAccess(userRestriction.inbox);
      if (permission.all) {
        // access to all users
        socket.emit('leave_room', agent_inbox_room);
        socket.emit('room', inbox_room);
      } else if (permission.no_access) {
        // no access
        socket.emit('leave_room', agent_inbox_room);
        socket.emit('leave_room', inbox_room);
      } else {
        // assigned to teammate or team
        socket.emit('leave_room', inbox_room);
        socket.emit('room', agent_inbox_room);
      }
    }
  }

  visibilityChangeHandler = function (randomTabKey) {
    return function (event) {
      if (!document.hidden) {
        let updatedKeyArray = JSON.parse(localStorage.getItem('keyArray'));
        if (updatedKeyArray.includes(String(randomTabKey))) {
          updatedKeyArray = updatedKeyArray.filter(
            (element) => element !== randomTabKey,
          );
          updatedKeyArray.push(randomTabKey);
          localStorage.setItem('keyArray', JSON.stringify(updatedKeyArray));
        } else {
          updatedKeyArray.push(randomTabKey);
          localStorage.setItem('keyArray', JSON.stringify(updatedKeyArray));
        }
      }
    };
  };

  beforeUnloadHandler = function (randomTabKey) {
    return function (event) {
      let localStorageItem = localStorage.getItem('keyArray');
      let updatedKeyArray = JSON.parse(localStorageItem).filter(
        (element) => element !== randomTabKey,
      );
      localStorage.setItem('keyArray', JSON.stringify(updatedKeyArray));
    };
  };
  componentDidMount() {
    // Initiate Theme Listener
    initiatePreferColorScheduleListeners(
      this.props.userPreferences.theme || 'auto',
    );

    // To set the current table state in reducer after refresh
    setTableState(localStorage.getItem('currentTableState'));

    // delete all local storage items that start with "gist-message-"
    deleteGistMessageStorage();

    // create random tab key variable to uniquely identify the current tab
    const randomTabKey = Math.round(Math.random() * 100000).toString();
    sessionStorage.setItem("randomTabKey",randomTabKey);
    let localStorageItem = localStorage.getItem('keyArray');

    const visibilityChangeWrapper = this.visibilityChangeHandler(randomTabKey);
    document.addEventListener('visibilitychange', visibilityChangeWrapper);

    const beforeUnloadWrapper = this.beforeUnloadHandler(randomTabKey);
    window.addEventListener('beforeunload', beforeUnloadWrapper);

    let keyArray;
    if (localStorageItem === null) {
      keyArray = [randomTabKey];
    } else {
      keyArray = JSON.parse(localStorageItem);
      keyArray.push(randomTabKey);
    }
    localStorage.setItem('keyArray', JSON.stringify(keyArray));

    const { pathname } = window.location;
    this.displaySidebar(pathname);
    if (!canWeAccess(userRestriction.inbox).no_access) {
      isSupported().then((messagingSupported) => {
        if (messagingSupported) {
          const app = !getApps().length
            ? initializeApp(settings.FIRE_BASE_CONFIG)
            : getApp();
          const messaging = getMessaging(app);
          requestPermission(messaging);
          onMessage(messaging, (payload) => {
            const { pathname } = window.location;
            if (
              !pathname.includes('conversations') &&
              payload.data.click_action.includes(fetchProjectSecretKey())
            ) {
              const notificationTitle = payload.data.title;
              const notificationOptions = {
                body:
                  payload.data.attachment === 'true'
                    ? 'Attachment 📎'
                    : profanityFilter(payload.data.body),
                requireInteraction: true,
                tag: payload.data.click_action,
                icon: payload.data.icon,
              };
              if ('Notification' in window) {
                const notification = new Notification(
                  notificationTitle,
                  notificationOptions,
                );
                notification.onclick = (event) => {
                  event.preventDefault();
                  window.open(notificationOptions.tag, '_blank');
                };
              }
            }
          });
        }
      });
      socket.on(socketChannels.chatMessage, (msg) => {
        if (
          msg.conversation &&
          msg.conversation.person_messages_count > 0 &&
          msg.status === 'sent'
        ) {
          if (msg.play_sound) {
            let newKeyArray = JSON.parse(localStorage.getItem('keyArray'));
            if (
              newKeyArray[newKeyArray.length - 1] === randomTabKey &&
              this.props.isAudioNotificationEnabled
            ) {
              let soundPlay;
              if (msg.new_conversation) {
                soundPlay = newConversationSound.play();
              } else {
                soundPlay = newMessageSound.play();
              }
              if (soundPlay !== undefined) {
                soundPlay
                  .then(_ => {
                    soundPlay();
                  })
                  .catch(error => {});
              }
            }
          }

          if (window.firebase && !window.firebase.messaging.isSupported()) {
            if (!('Notification' in window)) {
              alert('This browser does not support desktop notification');
            } else {
              try {
                Notification.requestPermission().then(() => {
                  let data = showPushNotification(msg);
                  if (data) {
                    triggerPushNotification(
                      data.msg,
                      data.chat_name,
                      data.image,
                    );
                  }
                });
              } catch (error) {
                if (error instanceof TypeError) {
                  Notification.requestPermission(() => {
                    let data = showPushNotification(msg);
                    if (data) {
                      triggerPushNotification(
                        data.msg,
                        data.chat_name,
                        data.image,
                      );
                    }
                  });
                } else {
                  throw error;
                }
              }
            }
          }

          if (isElectron()) {
            let data = showPushNotification(msg);
            if (data) {
              window.postMessage({
                type: 'push_notification',
                data,
              });
            }
          }
        }
      });
      socket.on(socketChannels.blockUser, function(data) {
        blockOrUnblockConversation(data);
      });
      socket.on(socketChannels.unblockUser, function(data) {
        blockOrUnblockConversation(data);
      });
    }
    socket.on(socketChannels.webPermissions, function(data) {
      store.dispatch({
        type: authAction.UPDATE_USER_PERMISSION_DATA,
        payload: data,
      });
    });
    socket.on(socketChannels.notificationSettings, function(data) {
      store.dispatch({
        type: authAction.UPDATE_NOTIFICATION_SETTINGS,
        payload: data.message,
      });
    });

    // delete all local storage items that start with "gist-message-"
    function deleteGistMessageStorage() {
      var key;
      for (var i = 0; i < window.localStorage.length; i++) {
        key = window.localStorage.key(i);
        if (key.startsWith('gist-message-')) {
          window.localStorage.removeItem(key);
        }
      }
    }

    function showPushNotification(msg) {
      const { pathname } = window.location,
        { desktopNotifications } = store.getState().Auth;

      if (
        !pathname.includes('conversations') &&
        desktopNotifications.global_notification === 'true'
      ) {
        return pushNotificationIfConditionsSatisfied(msg);
      }
    }

    function pushNotificationIfConditionsSatisfied(msg) {
      if (bot.includes(msg.message_type)) return false;
      const { desktopNotifications } = store.getState().Auth;
      let isAssignedToMe = false,
        isAssignedToRelatedTeams = false,
        isUnassigned = false,
        isMentioned = false,
        isAssignedToOthers = false,
        assignedToMeEnabled = desktopNotifications.reply_for_assigned_to_me,
        assignedToRelatedTeamsEnabled =
          desktopNotifications.assigned_to_related_teams,
        assignedToOthersEnabled = desktopNotifications.assigned_to_others,
        unassignedEnabled = desktopNotifications.unassigned,
        mentionedMeEnabled = desktopNotifications.mentioned,
        mentionIds;
      if (msg.user_id === null || msg.mention_id.length !== 0) {
        if (msg.mention_id.length !== 0) {
          mentionIds = msg.mention_id.map(id => parseInt(id));
        }
        if (
          assignedToMeEnabled === 'true' &&
          msg.conversation.member_id !== null
        ) {
          isAssignedToMe =
            msg.conversation.member_id === extractCurrentUserId();
        } else if (
          assignedToRelatedTeamsEnabled === 'true' &&
          msg.conversation.team_id !== null
        ) {
          isAssignedToRelatedTeams = desktopNotifications.team_ids.includes(
            msg.conversation.team_id,
          );
        }
        if (isAssignedToMe === false && isAssignedToRelatedTeams === false) {
          if (
            assignedToOthersEnabled === 'true' &&
            (msg.conversation.member_id !== null ||
              msg.conversation.team_id !== null)
          ) {
            if (msg.conversation.member_id !== null) {
              isAssignedToOthers =
                msg.conversation.member_id !== extractCurrentUserId();
            } else {
              isAssignedToOthers = !desktopNotifications.team_ids.includes(
                msg.conversation.team_id,
              );
            }
          } else if (unassignedEnabled === 'true') {
            isUnassigned =
              msg.conversation.team_id === null &&
              msg.conversation.member_id === null;
          } else if (
            mentionedMeEnabled === 'true' &&
            msg.mention_id.length !== 0
          ) {
            isMentioned = mentionIds.includes(extractCurrentUserId());
          }
        }
        if (
          isAssignedToMe ||
          isAssignedToRelatedTeams ||
          isUnassigned ||
          isAssignedToOthers ||
          isMentioned
        ) {
          return pushNotification(msg, mentionIds);
        }
      }
    }

    function pushNotification(msg, mentionIds) {
      let image = '';
      let chat_name;
      if (
        msg.person_id !== null &&
        msg.person !== undefined &&
        msg.person.person_chat_name !== undefined
      ) {
        chat_name = msg.person.person_chat_name;
        if (!msg.person.avatar_url.includes('assets.getgist.com/avatar')) {
          image = msg.person.avatar_url;
        }
        return { msg, chat_name, image };
      } else if (
        msg.mention_id.length !== 0 &&
        mentionIds.includes(extractCurrentUserId())
      ) {
        chat_name = msg.user.full_name;
        if (!msg.user.avatar_url.includes('assets.getgist.com/avatar')) {
          image = msg.user.avatar_url;
        }
        return { msg, chat_name, image };
      } else {
        return false;
      }
    }

    function triggerPushNotification(msg, chat_name, image) {
      let notificationTitle = chat_name;
      let notificationOptions = {
        body: msg.is_attachment
          ? 'Attachment 📎'
          : profanityFilter(msg.message_content),
        icon: image,
        tag: `gist-app-chat-message_${msg.id}`,

        requireInteraction: true,
        timeout: 50000,
      };
      if ('Notification' in window) {
        let notification = new Notification(
          notificationTitle,
          notificationOptions,
        );
        notification.onclick = function(event) {
          notification.close();
          event.preventDefault();
          window.open(
            `${getOrigin()}/projects/${fetchProjectSecretKey()}/conversations/${
              msg.conversation_identifier
            }`,
            '_blank',
          );
        };
      }
    }

    socket.on(socketChannels.chatSettings, function(data) {
      store.dispatch({
        type: settingsActions.UPDATE_MAGIC_TYPE_SETTINGS,
        payload: data.allow_magic_type,
      });
      store.dispatch({
        type: chatActions.UPDATE_CHAT_MAGIC_TYPE_SETTINGS,
        payload: data.allow_magic_type,
      });
    });

    // Feedback widget
    const s = document.createElement('script');
    s.async = 1;
    s.src = settings.USER_FEEDBACK;
    const parent_node = document.head || document.body;
    parent_node.appendChild(s);

    socket.on(socketChannels.joinCall, function(msg) {
      store.dispatch({
        type: chatActions.UPDATE_JOIN_CALL_MESSAGES,
        payload: msg,
      });
    });

    function blockOrUnblockConversation(data) {
      const payload = { data: data.data };
      store.dispatch({ type: chatActions.BLOCK_CONVERSATION, payload });
      if (window.location.pathname.includes('settings/spam')) {
        store.dispatch({ type: settingsActions.FETCH_BLOCKED_IP });
        store.dispatch({ type: settingsActions.FETCH_BLOCKED_USERS });
      }
    }

    if (isElectron()) {
      window.switchProject = project_secret => {
        window.location.href = `/projects/${project_secret}/contacts`;
      };

      window.switchConversation = conversation_secret => {
        updateBrowserHistory(`conversations/${conversation_secret}`);
      };
    }
  }

  displaySidebar(pathname) {
    if (pathname.split('/').includes('settings')) {
      this.setState({
        displaySettingsSideBar: true,
        displayReportSidebar: false,
        displayMessagesSidebar: false,
        displaySupportBotSidebar: false,
      });
    } else if (
      pathname.split('/').includes('support') &&
      pathname.split('/').includes('emma')
    ) {
      this.setState({
        displaySupportBotSidebar: true,
        displaySettingsSideBar: false,
        displayReportSidebar: false,
        displayMessagesSidebar: false,
      });
    } else if (pathname.split('/').includes('messages')) {
      if (
        pathname.split('/').includes('post') &&
        pathname.split('/').length === 6
      ) {
        this.setState({
          displaySettingsSideBar: false,
          displayReportSidebar: false,
          displayMessagesSidebar: false,
          displaySupportBotSidebar: false,
        });
      } else {
        this.setState({
          displaySettingsSideBar: false,
          displayReportSidebar: false,
          displayMessagesSidebar: true,
          displaySupportBotSidebar: false,
        });
      }
    } else if (
      pathname.split('/')[3] &&
      pathname.split('/')[3].includes('reports') &&
      pathname.split('/')[4] &&
      (pathname.split('/')[4].includes('support') ||
        pathname.split('/')[4].includes('marketing'))
    ) {
      this.setState({
        displayReportSidebar: true,
        displaySettingsSideBar: false,
        displayMessagesSidebar: false,
        displaySupportBotSidebar: false,
      });
    } else {
      this.setState({
        displaySettingsSideBar: false,
        displayReportSidebar: false,
        displayMessagesSidebar: false,
        displaySupportBotSidebar: false,
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.unlisten = history.listen(location => {
      const { pathname } = location;
      this.displaySidebar(pathname);
    });
  }

  componentWillUnmount() {
    this.unlisten();
    clearInterval(pingInterval);
    socket.off(socketChannels.chatMessage);
    socket.off(socketChannels.openConversationsCount);
    socket.off(socketChannels.blockUser);
    socket.off(socketChannels.unblockUser);
    const visibilityChangeWrapper = this.visibilityChangeHandler(sessionStorage.getItem("randomTabKey"));
    document.removeEventListener('visibilitychange', visibilityChangeWrapper);
    const beforeUnloadWrapper = this.beforeUnloadHandler(sessionStorage.getItem("randomTabKey"));
    window.removeEventListener('beforeunload', beforeUnloadWrapper);
  }

  onIdleStatusActive = () => {
    isIdle = false;
    store.dispatch({ type: authAction.PING_AGENT_STATUS });
  };

  onIdleStatusIdle = () => {
    isIdle = true;
  };

  fetchConversationList = filterKey => {
    store.dispatch({
      type: chatActions.SET_FILTER,
      payload: {
        inbox: filterKey,
      },
    });
    if (this.handleTableViewQueryURL()) {
      store.dispatch({
        type: chatActions.CONVERSATION_DRAWER_STATUS,
        payload: false,
      });
    }
    this.props.fetchConversations({
      chatRoomActivation: !(this.props.inboxViewMode.toLowerCase() === 'table'),
      emptyChatRoom: true,
      filter: filterKey,
      status: this.props.statusFilter,
      sort: this.props.sortFilter,
      searchTag: decodeURIComponent(this.props.location.search),
    });
    store.dispatch({
      type: chatActions.FETCH_SNIPPETS,
      offset: '',
      personId: extractCurrentPersonId(),
    });
    store.dispatch({ type: chatActions.FETCH_GIF });
    store.dispatch({
      type: kbActions.FETCH_SUPPORTED_LANGUAGES,
      isForComposer: true,
      callback: () => {
        store.dispatch({ type: chatActions.FETCH_ARTICLES, offset: '' });
      },
    });
  };

  navigateTo = (urlSlug, keyCode) => {
    if (['conversations/latest', 'conversations/table'].includes(urlSlug)) {
      if (keyCode === shortcutKeyCodes.A) {
        this.fetchConversationList('IB1');
      } else if (keyCode === shortcutKeyCodes.U) {
        if (
          canWeAccess(userRestriction.inbox).all ||
          canWeAccess(userRestriction.UnassignedInbox)
        ) {
          this.fetchConversationList('IB3');
        } else {
          handleNotAuthorizedModal(true);
        }
      } else if (keyCode === shortcutKeyCodes.I) {
        this.fetchConversationList('IB4');
      } else if (keyCode === shortcutKeyCodes.J) {
        this.fetchConversationList('IB5');
      } else if (keyCode === shortcutKeyCodes.M) {
        this.fetchConversationList('IB2');
      }
    }
    updateBrowserHistory(urlSlug);
    this.handleOmniSearchDisplay(false);
  };

  /**
   * Determine if the current page is the Inbox's conversation page.
   *
   * @returns {boolean} True if the page is an Inbox conversations page and not a rules or rule page.
   */
  isInInboxPage = () => {
    // Split the current path to analyze the segments
    const pathName = window.location.pathname.split('/');

    return (
      // Check if the fourth segment is 'conversations'
      pathName[3] === 'conversations' &&
      // And ensure that the fifth segment is not 'rules' or 'rule'
      !['rules', 'rule', 'search'].includes(pathName[4])
    );
  };

  isInInboxSearchPage = () => {
    const pathName = window.location.pathname.split('/');
    return pathName[3] === 'conversations' && pathName[4] === 'search';
  };

  handleTableViewQueryURL = () => {
    let queryString = window.location.search;
    let params = new URLSearchParams(queryString);
    let parameterValue = params.get('view');
    return parameterValue === 'table';
  };
  /**
   * Register the asset creation shortcut: N
   *
   * @param {Event} e - The keydown event.
   */
  handleAssetCreationShortcut = (e) => {
    if (e.keyCode === shortcutKeyCodes.N) {
      this.handleOmniSearchDisplay(true);
      store.dispatch({
        type: sharedActions.UPDATE_OMNISEARCH_STATE,
        payload: 'create_new',
      });
    }
  };

  handleLayoutChange = () => {
    let isTable = this.props.inboxViewMode.toLowerCase() === 'table';
    let chatRooms = this.props.chatRooms;
    if (isTable) {
      updateBrowserHistory('conversations/latest');
      store.dispatch({
        type: actions.SET_INBOX_MODE,
        payload: 'List',
      });
      if (chatRooms.length > 0) {
        activateChatRoom('', {
          data: { conversations: chatRooms },
        });
      }
    } else {
      updateBrowserHistory('conversations/table');
      store.dispatch({
        type: actions.SET_INBOX_MODE,
        payload: 'Table',
      });
    }
    store.dispatch({
      type: chatActions.SHOW_ADVANCED_SEARCH,
      payload: false,
    });
  };
  handleToggleFilterMenu = () => {
    store.dispatch({
      type: chatActions.TOGGLE_INBOX_LEFT_MENU,
    });
  };
  /**
   * Register the navigate shortcut: G
   *
   * @param {Event} e - The keydown event.
   */
  handleNavigationShortcut = (e) => {
    if (e.keyCode === shortcutKeyCodes.G) {
      this.handleOmniSearchDisplay(true);
      store.dispatch({
        type: sharedActions.UPDATE_OMNISEARCH_STATE,
        payload: 'go_to',
      });
    }
  };

  handleSearchViewInboxShortcut = (keycode) => {
    let filterKey = '';
    if (keycode === shortcutKeyCodes.A) {
      filterKey = 'IB1';
    } else if (keycode === shortcutKeyCodes.U) {
      filterKey = 'IB3';
    } else if (keycode === shortcutKeyCodes.I) {
      filterKey = 'IB4';
    } else if (keycode === shortcutKeyCodes.J) {
      filterKey = 'IB5';
    }
    store.dispatch({
      type: chatActions.CLEAR_CONVERSATION,
    });
    store.dispatch({
      type: chatActions.SHOW_ADVANCED_SEARCH,
      payload: false,
    });
    store.dispatch({
      type: chatActions.SET_FILTER,
      payload: {
        inbox: filterKey,
      },
    });
  };

  moveToPreviousConversation = () => {
    // Get the currently selected conversation
    let isSelected = jQuery('#chatRoomHolder .conversation.selected');

    if (isSelected.length) {
      // Get the previous conversation
      let prev = isSelected.parent().prev().children('.conversation');
      if (prev.length) {
        prev.addClass('selected');
        jQuery('.List').scrollTop(0);
        jQuery('.List').scrollTop(
          jQuery('.selected:first').offset().top - jQuery('.List').height(),
        );
        isSelected.removeClass('selected');
      }
    } else {
      // If none is selected, get the active conversation and move to its previous
      let prev = jQuery('#chatRoomHolder .conversation.active')
        .parent()
        .prev()
        .children('.conversation');
      if (prev.length) {
        prev.addClass('selected');
      }
    }
  };

  moveToNextConversation = () => {
    // Get the currently selected conversation
    let selectedConversation = jQuery('#chatRoomHolder .conversation.selected');

    if (selectedConversation.length) {
      // Get the next conversation
      let nextConversation = selectedConversation
        .parent()
        .next()
        .children('.conversation');
      if (nextConversation.length) {
        nextConversation.addClass('selected');
        jQuery('.List').scrollTop(0);
        jQuery('.List').scrollTop(
          jQuery('.selected:first').offset().top -
            jQuery('.List').height() +
            100,
        );
        selectedConversation.removeClass('selected');
      }
    } else {
      // If none is selected, get the active conversation and move to its next
      let next = jQuery('#chatRoomHolder .conversation.active')
        .parent()
        .next()
        .children('.conversation');
      if (next.length) {
        next.addClass('selected');
      }
    }
  };

  setActiveConversation = () => {
    // Get the elements with the class 'selected'
    let isSelected = document.getElementsByClassName('selected');
    // If there's a selected conversation, simulate a click on it
    if (isSelected.length) {
      isSelected[0].click();
    }
  };

  handleConversationShortcut = (keyName, e, isInbox) => {
    if (!isInbox && keyName !== '[') return;

    const {
      selectedPreviousConversationData,
      showNewConversation,
      chatRooms,
      activeConversationId,
    } = this.props;
    let conversationId = activeConversationId;
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('view')) {
      conversationId = extractConversationIdentifierFromUrl();
    }
    const selectedChatRoom = chatRooms.find(
      (conversation) => conversation.conversation_identifier === conversationId,
    );

    // Determine the effective chat room, either a previous conversation or the currently selected one
    const effectiveSelectedChatRoom = extractPreviousConversationIdentifier()
      ? selectedPreviousConversationData
      : selectedChatRoom;
    // Check if a conversation is currently active
    const isConversationActive =
      extractPreviousConversationIdentifier() ||
      (effectiveSelectedChatRoom && effectiveSelectedChatRoom.id) ||
      showNewConversation ||
      (!isInbox && keyName === '[') ||
      keyName === 'command+p';

    // Exit the function if no conversation is currently active
    if (!isConversationActive) return;

    // Define all commands using keyName
    const keyNameCommands = {
      [`${osCTRLkey}+e`]: 'ask_emma',
      [`${osCTRLkey}+j`]: 'ai_assist',
      [`${osCTRLkey}+p`]: 'search_conversation',
      [`${osCTRLkey}+shift+a`]: 'upload_attachment',
      [`${osCTRLkey}+shift+e`]: 'toggle_conversation_events',
      [`${osCTRLkey}+shift+h`]: 'article',
      [`${osCTRLkey}+shift+i`]: 'upload_image',
      [`${osCTRLkey}+shift+m`]: 'meeting',
      [`${osCTRLkey}+shift+o`]: 'reopen_conversation',
      [`${osCTRLkey}+shift+y`]: 'close_conversation',
      'shift+r': 'add_note',
      'shift+t': 'tag_contact',
      ']': 'toggle_right_menu',
      a: 'assign_to',
      i: 'assign_to_me',
      z: 'snooze_conversation',
      p: 'change_priority',
      t: 'tag_conversation',
      r: 'write_reply',
      j: this.moveToPreviousConversation,
      k: this.moveToNextConversation,
      x: this.setActiveConversation,
      '\\': 'snippet',
    };

    // Fetch action using keyName
    const action = keyNameCommands[keyName];

    if (action) {
      // If the mapped action is a function, call it directly
      if (typeof action === 'function') {
        action();
      } else {
        this.executeShortcutCommand(action);
      }
    }
  };

  onHotkeyDown = (keyName, e, handle) => {
    e.preventDefault(); // Prevent default browser behavior

    // Check if the current page is an Inbox page
    const isInbox = this.isInInboxPage();

    // Check for OmniSearch hotkey trigger and display OmniSearch if pressed
    if (keyName === `${osCTRLkey}+k`) {
      this.handleOmniSearchDisplay(true);
    }
    // Check for ESC key, which closes OmniSearch and the Keyboard Shortcuts modal
    else if (e.keyCode === shortcutKeyCodes.ESC) {
      this.handleOmniSearchDisplay(false);
      this.handleKeyboardShortcutsDisplay(false);
    }
    // Check for 'shift+/' hotkey to trigger the Keyboard Shortcuts modal
    else if (keyName === 'shift+/') {
      this.handleKeyboardShortcutsDisplay(true);
    } else if (isInbox && keyName === 'l') {
      this.handleLayoutChange();
    } else if (keyName === '[') {
      this.handleToggleFilterMenu();
    }
    // If none of the above, handle other potential shortcut actions
    else {
      this.handleAssetCreationShortcut(e); // Handle shortcuts for creating assets
      this.handleNavigationShortcut(e); // Handle navigation shortcuts
      this.handleConversationShortcut(keyName, e, isInbox); // Handle conversation-related shortcuts
    }

    return false; // Return false to prevent further propagation of the event
  };

  handleOmniSearchDisplay = value => {
    store.dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: value,
    });
  };

  handleKeyboardShortcutsDisplay = value => {
    store.dispatch({
      type: sharedActions.SHOW_HIDE_KEYBOARD_SHORTCUTS,
      payload: value,
    });
  };

  closeCreateContactModal = () => {
    store.dispatch({
      type: contactsActions.CONTACT_CREATE_MODAL,
      payload: false,
    });
  };

  closeCreateDealDrawer = () => {
    this.setState({ displayDealDrawer: false });
    store.dispatch({
      type: CRMActions.OPEN_CREATE_DEAL_DRAWER,
      payload: false,
    });
  };

  executeShortcutCommand = (action, isInbox = this.isInInboxPage()) => {
    const {
      displayOmniSearch,
      inboxFilter,
      replyEditor,
      noteEditor,
      isFromPreviousConversation,
      selectedPreviousConversation,
      chatRooms,
    } = this.props;

    // Extract conversation identifier
    const conversationIdentifier =
      extractPreviousConversationIdentifier() ||
      extractConversationIdentifier();
    const currentReplyEditor =
      replyEditor && replyEditor[conversationIdentifier || 'new_message'];
    const currentNoteEditor =
      noteEditor && noteEditor[conversationIdentifier || 'new_message'];
    const effectiveSelectedChatRoom = isFromPreviousConversation
      ? selectedPreviousConversation
      : chatRooms.find(
          (conversation) =>
            conversation.conversation_identifier === conversationIdentifier,
        );

    // Helper function to dispatch actions for reply editor focus
    const focusReplyEditor = (payload) => {
      store.dispatch({
        type: chatActions.CHANGE_REPLY_EDITOR_FOCUS,
        payload,
      });
      store.dispatch({
        type: chatActions.CHANGE_NOTE_EDITOR_FOCUS,
        payload: !payload,
      });
    };

    // Helper function to dispatch actions for opening omnisearch
    const openOmniSearch = (state) => {
      store.dispatch({
        type: sharedActions.UPDATE_OMNISEARCH_STATE,
        payload: state,
      });
      if (!displayOmniSearch) {
        store.dispatch({
          type: sharedActions.SHOW_HIDE_OMNISEARCH,
          payload: true,
        });
      }
    };

    // Helper function to check if social media restriction applies
    const hasSocialMediaRestriction = () => {
      const {
        selectedChatRoomMessages,
        selectedPreviousConversationIdentifier,
        selectedPreviousChatMessages,
      } = this.props;
      let chatRoomMessage = selectedChatRoomMessages;
      if (selectedPreviousConversationIdentifier) {
        chatRoomMessage = selectedPreviousChatMessages;
      }
      return socialMediaRestriction(chatRoomMessage);
    };

    // Helper function to dispatch actions for conversation status change
    const changeConversationStatus = (status) => {
      store.dispatch({
        type: chatActions.INIT_CONVERSATION_STATUS_CHANGE,
        statusDetails: {
          change_status_to: status,
          conversation_identifiers: [conversationIdentifier],
          select_all: false,
          currentStatus: effectiveSelectedChatRoom.status,
          isFromPreviousConversation: isFromPreviousConversation,
        },
      });
      store.dispatch({
        type: chatActions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS,
        payload: {
          conversation_identifier: conversationIdentifier,
          status: status,
          isFromPreviousConversation: isFromPreviousConversation,
        },
      });
    };

    // Helper function to check if the user is authorized to access a feature
    const isUserAuthorized = (restriction) =>
      !canWeAccess(restriction).no_access;

    // Helper function to open the create contact modal
    const openCreateContactModal = () => {
      if (canWeAccess(userRestriction.contacts)) {
        if (this.props.customProperties.length === 0) {
          store.dispatch({ type: filterActions.FILTER_FETCH_PROPERTIES });
        }
        if (this.props.allPropertiesLists.length === 0) {
          store.dispatch({ type: contactsActions.FETCH_PROPERTIES });
        }
        store.dispatch({
          type: contactsActions.CONTACT_CREATE_MODAL,
          payload: true,
        });
      } else {
        handleNotAuthorizedModal(true);
      }
    };

    // Helper function to open the new conversation screen
    const openNewConversationScreen = () => {
      updateBrowserHistory('conversations/new');
      store.dispatch({
        type: chatActions.SHOW_TO_USER,
        payload: false,
      });
      store.dispatch({
        type: chatActions.TOGGLE_NEW_CONVERSATION_SCREEN,
        payload: true,
      });
    };

    // Helper function to open the select channel modal
    const openSelectChannelModal = () => {
      store.dispatch({
        type: messageAction.SET_SELECT_CHANNEL_MODAL_STATUS,
        payload: true,
      });
    };

    // Helper function to open the create deal drawer
    const openCreateDealDrawer = () => {
      if (!isUserAuthorized(userRestriction.deals)) {
        handleNotAuthorizedModal(true);
        return;
      }
      if (this.props.userList.length === 0) {
        store.dispatch({ type: settingsActions.FETCH_USERS_LIST });
      }
      if (this.props.pipelineListData.length === 0) {
        store.dispatch({ type: CRMActions.GET_PIPELINE_LIST });
      }
      store.dispatch({
        type: CRMActions.OPEN_CREATE_DEAL_DRAWER,
        payload: true,
      });
      this.setState({ displayDealDrawer: true });
    };

    // Helper function to navigate to a specific route
    const navigateTo = (route, keycode) => {
      const isSearchView =
        this.isInInboxSearchPage() || this.props.redirectingToSearch;

      if (isSearchView) {
        this.handleSearchViewInboxShortcut(keycode);
        this.navigateTo(route, keycode);
        return;
      }

      if (route === 'conversations/table') {
        if (shortcutInboxTableRoutesMappedToAction[action]) {
          this.navigateTo(route, keycode);
          store.dispatch({
            type: chatActions.CHANGE_PREVIOUS_CONVERSATION_DRAWER_STATUS,
          });
        }
      } else {
        if (shortcutRoutesMappedToAction[action]) {
          this.navigateTo(route, keycode);
          store.dispatch({
            type: chatActions.CHANGE_PREVIOUS_CONVERSATION_DRAWER_STATUS,
          });
        }
      }
    };

    // Handle the action based on its type
    if (
      !(inboxFilter === 'IB5' && spamRestrictOmniSearchKeys.includes(action))
    ) {
      let isTable = this.props.inboxViewMode.toLowerCase() === 'table';
      if (action === 'toggle_inbox_layout') {
        this.handleLayoutChange();
      } else if (action === 'toggle_filter_menu') {
        this.handleToggleFilterMenu();
      }
      switch (action) {
        case 'write_reply':
          focusReplyEditor(true);
          break;
        case 'add_note':
          focusReplyEditor(false);
          break;
        case 'snippet':
          openOmniSearch('snippet');
          break;
        case 'article':
          openOmniSearch('article');
          break;
        case 'meeting':
          if (hasSocialMediaRestriction()) {
            openOmniSearch('meeting');
          }
          break;
        case 'search_conversation':
          if (fetchRestrictedFeatures().includes(menu.inboxAdvancedSearch)) {
            store.dispatch({
              type: chatActions.SET_SEARCH_STATUS,
              payload: true,
            });
          } else {
            store.dispatch(navigateToInboxAdvancedSearch());
          }
          break;
        case 'ai_assist':
          if (!fetchRestrictedFeatures().includes(menu.aiAssist)) {
            if (currentReplyEditor) {
              currentReplyEditor.selection.restore();
              currentReplyEditor.selection.clear();
            }
            if (currentNoteEditor) {
              currentNoteEditor.selection.restore();
              currentNoteEditor.selection.clear();
            }
            store.dispatch({
              type: actions.UPDATE_AI_ASSIST_MODE,
              payload: 'default',
            });
            openOmniSearch('ai_assist');
          }
          break;
        case 'upload_attachment':
          handleUploadAttachment();
          break;
        case 'upload_image':
          handleImageAttachment();
          break;
        case 'assign_to':
          openOmniSearch('assign_to');
          break;
        case 'assign_to_me':
          let assignData = {
            assign_to: extractCurrentUserId(),
            conversation_identifiers: [conversationIdentifier],
            type: 'member',
          };
          store.dispatch({
            type: chatActions.POST_MESSAGE,
            assignData,
          });
          break;
        case 'close_conversation':
          if (effectiveSelectedChatRoom.status !== 'closed') {
            changeConversationStatus('closed');
          }
          break;
        case 'ask_emma':
          if (!fetchRestrictedFeatures().includes(menu.supportBot)) {
            store.dispatch({
              type: chatActions.UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY,
              payload: {
                visible: true,
                mode: 'ask_emma',
              },
            });
          }
          break;
        case 'reopen_conversation':
          if (effectiveSelectedChatRoom.status !== 'open') {
            changeConversationStatus('open');
          }
          break;
        case 'snooze_conversation':
          openOmniSearch('snooze');
          break;
        case 'change_priority':
          store.dispatch({
            type: chatActions.PRIORITIZE_CONVERSATION,
            payload: {
              priority: !effectiveSelectedChatRoom.priority,
              cid: conversationIdentifier,
              isFromPreviousConversation: isFromPreviousConversation,
            },
          });
          break;
        case 'tag_conversation':
          const { selectedChatRoomMessages } = this.props;
          const message = getLastPersonOrAgentMessage(selectedChatRoomMessages);

          if (message) {
            openOmniSearch('tag_conversation');
          }
          break;
        case 'tag_contact':
          if (extractCurrentPersonId()) {
            openOmniSearch('tag_contact');
          }
          break;
        case 'toggle_conversation_events':
          store.dispatch({
            type: authAction.HIDE_CONVERSATION_STATUS_MESSAGES,
            payload: {
              users_profile: {
                hide_conversation_status_messages: !this.props.userPreferences
                  .hide_conversation_status_messages,
              },
            },
          });
          break;
        case 'toggle_right_menu':
          store.dispatch({
            type: chatActions.TOGGLE_INBOX_RIGHT_MENU,
          });
          break;
        case 'navigate_assigned_inbox':
          if (isTable) {
            navigateTo(
              shortcutInboxTableRoutesMappedToAction[action],
              shortcutKeyCodes.A,
            );
          } else {
            navigateTo(
              shortcutRoutesMappedToAction[action],
              shortcutKeyCodes.A,
            );
          }
          break;
        case 'navigate_to_mentioned_inbox':
          if (isTable) {
            navigateTo(
              shortcutInboxTableRoutesMappedToAction[action],
              shortcutKeyCodes.M,
            );
          } else {
            navigateTo(
              shortcutRoutesMappedToAction[action],
              shortcutKeyCodes.M,
            );
          }
          break;
        case 'navigate_unassigned_inbox':
          if (isTable) {
            navigateTo(
              shortcutInboxTableRoutesMappedToAction[action],
              shortcutKeyCodes.U,
            );
          } else {
            navigateTo(
              shortcutRoutesMappedToAction[action],
              shortcutKeyCodes.U,
            );
          }
          break;
        case 'navigate_all_inbox':
          if (isTable) {
            navigateTo(
              shortcutInboxTableRoutesMappedToAction[action],
              shortcutKeyCodes.I,
            );
          } else {
            navigateTo(
              shortcutRoutesMappedToAction[action],
              shortcutKeyCodes.I,
            );
          }
          break;
        case 'navigate_spam_inbox':
          if (isTable) {
            navigateTo(
              shortcutInboxTableRoutesMappedToAction[action],
              shortcutKeyCodes.J,
            );
          } else {
            navigateTo(
              shortcutRoutesMappedToAction[action],
              shortcutKeyCodes.J,
            );
          }
          break;
        case 'navigate_contacts':
        case 'navigate_messages':
        case 'navigate_workflows':
        case 'navigate_knowledgebase':
        case 'navigate_deals':
        case 'navigate_liveview':
        case 'navigate_forms':
        case 'navigate_settings':
        case 'navigate_integrations':
          navigateTo(shortcutRoutesMappedToAction[action]);
          break;
        case 'create_contact':
          openCreateContactModal();
          break;
        case 'create_message':
          if (!isUserAuthorized(userRestriction.messages)) {
            handleNotAuthorizedModal(true);
            break;
          }
          if (isInbox) {
            openNewConversationScreen();
          } else {
            openSelectChannelModal();
          }
          break;
        case 'create_workflow':
          this.navigateTo('workflow/choose-template');
          break;
        case 'create_article':
          this.navigateTo('knowledge-base/article');
          break;
        case 'create_deal':
          openCreateDealDrawer();
          break;
        case 'create_form':
          this.navigateTo('forms/choose-form-template');
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { url } = this.props.match,
      {
        restrictedFeatures,
        projects,
        fetchContacts,
        beta,
        sideBarActiveKey,
        profile,
        projectBlocked,
        owner,
        isGetStarted,
        completedCount,
        reportSideBarActiveKey,
        bannerMessages,
        plan,
        messageSideBarActiveKey,
        supportBotSidebarActiveKey,
        isBlackFridayUpgradeEligible,
        openConversationCount,
        notAuthorizedModalStatus,
        customProperties,
        allPropertiesLists,
        isOpenCreateDealDrawer,
        pipelineListData,
        stagesList,
        contacts,
        contactListLoader,
        createDealBtnLoader,
        companyList,
        companyListLoader,
        isSelectChannelModalVisible,
        createChatLoader,
        emailTemplates,
        emailTemplatesLoader,
        selectedTemplateId,
        templateType,
        templateJson,
        createBroadcastLoader,
        createPostLoader,
        displayKeyboardShortcuts,
        contactModalVisible,
        conversationInboxCount,
        modalContactId,
        askEmmaMatchFound,
        aiAssistMode,
      } = this.props;

    let {
        displaySettingsSideBar,
        displayReportSidebar,
        displayMessagesSidebar,
        displaySupportBotSidebar,
      } = this.state,
      link = this.props.location.pathname.replace(
        `/projects/${fetchProjectSecretKey()}/settings/`,
        '',
      ),
      messageLink = this.props.location.pathname.replace(
        `/projects/${fetchProjectSecretKey()}/messages/`,
        '',
      ),
      supportSidebarLink = this.props.location.pathname.replace(
        `/projects/${fetchProjectSecretKey()}/support/emma/`,
        '',
      ),
      inProductOnBoarding = this.props.location.pathname.includes(
        'connect-channel',
      ),
      { userList } = this.props;

    userList = isAllowAllUser(userList);

    if (link === 'roles') {
      link = 'teams';
    }

    return (
      <Hotkeys
        keyName={shortcutKeys}
        onKeyDown={(keyName, e, handle) => e.preventDefault()}
        onKeyUp={this.onHotkeyDown.bind(this)}
      >
        <Layout
          className={`layout main-layout ${isElectron() ? 'is-electron' : ''}`}
        >
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdleStatusIdle}
            onActive={this.onIdleStatusActive}
            timeout={settings.PING_API_INTERVAL}
          />
          <TopBarNotification
            emailSendingModalVisible={this.props.emailSendingModalVisible}
            emailSendingLoader={this.props.emailSendingLoader}
            pathName={this.props.location.pathname}
            bannerMessages={bannerMessages}
            isBlackFridayUpgradeEligible={isBlackFridayUpgradeEligible}
            plan={plan}
          />
          <TopNavBar
            restrictedFeatures={restrictedFeatures}
            projects={projects}
            fetchContacts={fetchContacts}
            beta={beta}
            profile={profile}
            isGetStarted={isGetStarted}
            completedCount={completedCount}
            projectBlocked={projectBlocked}
            plan={plan}
            owner={owner}
            openConversationCount={
              conversationInboxCount.all
                ? conversationInboxCount.all
                : openConversationCount
            }
            handleKeyboardShortcutsDisplay={this.handleKeyboardShortcutsDisplay}
            modalContactId={modalContactId}
          />
          <NotificationBar location={this.props.location} />
          <Layout
            className={`content-container ${
              inProductOnBoarding ? 'bg-white' : ''
            }`}
            style={{ display: 'flex' }}
          >
            {isElectron() && (
              <div className="workspace-switcher-sidebar">
                {projects.map(project => (
                  <div
                    className={`workspace-item ${
                      project.secret_key === fetchProjectSecretKey()
                        ? 'selected'
                        : ''
                    }`}
                    key={project.secret_key}
                  >
                    <a href={`/projects/${project.secret_key}/contacts`}>
                      <Tooltip title={project.project_name} placement="right">
                        <img src={project.logo} alt={project.project_name} />
                      </Tooltip>
                    </a>
                  </div>
                ))}
              </div>
            )}
            {displaySidebar(projectBlocked, displaySettingsSideBar) && (
              <SideBar
                sideBarActiveKey={[link].length ? [link] : sideBarActiveKey}
                plan={plan}
              />
            )}
            {displaySidebar(projectBlocked, displayMessagesSidebar) && (
              <MessageSideBar
                messageSideBarActiveKey={
                  [messageLink].length ? [messageLink] : messageSideBarActiveKey
                }
              />
            )}
            {!projectBlocked &&
              displayReportSidebar &&
              !fetchTeamRestrictedFeatures() && (
              <ReportSideBar sideBarActiveKey={reportSideBarActiveKey} />
            )}
            {!projectBlocked &&
              displaySupportBotSidebar && (
                <SupportBotSidebar
                  supportBotSidebarActiveKey={
                    [supportSidebarLink].length
                      ? [supportSidebarLink]
                      : supportBotSidebarActiveKey
                  }
                />
              )}
            <AppRouter
              url={url}
              projectBlocked={projectBlocked}
              owner={owner}
            />
          </Layout>
          <NotAuthorizedModal visible={notAuthorizedModalStatus} />

          <CreateContactModal
            visible={contactModalVisible}
            handleCancel={this.closeCreateContactModal}
            customProperties={customProperties}
            allPropertiesLists={allPropertiesLists}
          />

          {isOpenCreateDealDrawer &&
            this.state.displayDealDrawer && (
              <CreateDealDrawer
                visible={isOpenCreateDealDrawer}
                handleCreateDealDrawer={this.closeCreateDealDrawer}
                pipelineList={pipelineListData}
                stages={stagesList}
                userList={userList}
                isEdit={false}
                contacts={contacts}
                contactListLoader={contactListLoader}
                createDealBtnLoader={createDealBtnLoader}
                companyList={companyList}
                companyListLoader={companyListLoader}
              />
            )}

          {isSelectChannelModalVisible && (
            <SelectChannel
              isOneOffOnly={false}
              visible={isSelectChannelModalVisible}
              createChatLoader={createChatLoader}
              emailTemplates={emailTemplates}
              emailTemplatesLoader={emailTemplatesLoader}
              selectedTemplateId={selectedTemplateId}
              restrictedFeatures={fetchRestrictedFeatures()}
              templateType={templateType}
              templateJson={templateJson}
              createBroadcastLoader={createBroadcastLoader}
              createPostLoader={createPostLoader}
            />
          )}

          <OmniSearch
            executeShortcutCommand={this.executeShortcutCommand}
            askEmmaMatchFound={askEmmaMatchFound}
            aiAssistMode={aiAssistMode}
          />

          {displayKeyboardShortcuts && (
            <KeyboardShortcuts
              handleKeyboardShortcutsDisplay={
                this.handleKeyboardShortcutsDisplay
              }
            />
          )}
          <NotAuthorizedModal visible={notAuthorizedModalStatus} />
          <TaskWidget />
        </Layout>
      </Hotkeys>
    );
  }
}

function mapStateToProps(state) {
  const {
      restrictedFeatures,
      projects,
      beta,
      profile,
      projectBlocked,
      owner,
      currentProjectName,
      isGetStarted,
      completedCount,
      emailSendingModalVisible,
      emailSendingLoader,
      bannerMessages,
      plan,
      isBlackFridayUpgradeEligible,
      isAudioNotificationEnabled,
      openConversationCount,
      userPreferences,
      notAuthorizedModalStatus,
      userPermissions,
    } = state.Auth,
    { sideBarActiveKey, userList } = state.Settings,
    {
      fetchContacts,
      contactProfileData,
      customProperties,
      contactModalVisible,
      modalContactId,
    } = state.Contacts,
    { allPropertiesLists } = state.Filter,
    {
      broadcastName,
      emailTemplates,
      emailTemplatesLoader,
      selectedTemplateId,
      templateType,
      templateJson,
      createBroadcastLoader,
    } = state.BroadcastEmail,
    { autoEmailName } = state.AutoEmail,
    { campaignsData } = state.CampaignEmail,
    { reportData } = state.EmailReport,
    { workflowDetail } = state.Workflow,
    { triggerName } = state.Rules,
    { meetingData } = state.Meetings,
    { companyDetails } = state.Company,
    { articleDetail } = state.KnowledgeBase,
    { reportSideBarActiveKey } = state.InboxReports,
    { editorPageDetails } = state.Forms,
    {
      messageSideBarActiveKey,
      supportBotSidebarActiveKey,
      displayOmniSearch,
      currentOmniSearchState,
      displayKeyboardShortcuts,
      replyEditor,
      noteEditor,
      isComposerUndo,
    } = state.SharedReducers,
    {
      isOpenCreateDealDrawer,
      pipelineListData,
      stagesList,
      contacts,
      contactListLoader,
      createDealBtnLoader,
      companyList,
      companyListLoader,
    } = state.CRM,
    { isSelectChannelModalVisible, createChatLoader } = state.MessagesReducer,
    { createPostLoader } = state.PostReducer,
    {
      articles,
      chatTagsData,
      userTagList,
      tags,
      meetingSchedulerList,
      agentTimingList,
      snippets,
      snippetCategories,
      mostRecentSnippets,
      teams,
      teamMates,
      selectedChatRoom,
      selectedChatRoomMessages,
      fetchConversations,
      fetchCurrentConversationMessages,
      fetchCurrentPersonProfile,
      setSelectedChatRoom,
      conversationInboxCount,
      removedConversationIdentifier,
      activeComposerTab,
      currentConversationId,
      activeConversationId,
      statusFilter,
      meetingAgentId,
      inboxFilter,
      showNewConversation,
      agentTypedReplyMessages,
      agentTypedNoteMessages,
      askEmmaMatchFound,
      aiAssistMode,
      chatRooms,
      inboxViewMode,
      redirectingToSearch,
      isFromPreviousConversation,
      previousConversations,
      selectedPreviousConversation,
    } = state.Chat,
    { selectedNewsCenter } = state.NewsCenter;
  return {
    restrictedFeatures,
    projects,
    fetchContacts,
    beta,
    sideBarActiveKey,
    profile,
    projectBlocked,
    owner,
    isGetStarted,
    completedCount,
    currentProjectName,
    contactProfileData,
    customProperties,
    allPropertiesLists,
    broadcastName,
    autoEmailName,
    campaignsData,
    reportData,
    workflowDetail,
    activeConversationId,
    triggerName,
    companyDetails,
    meetingData,
    articleDetail,
    emailSendingModalVisible,
    emailSendingLoader,
    reportSideBarActiveKey,
    bannerMessages,
    plan,
    editorPageDetails,
    messageSideBarActiveKey,
    supportBotSidebarActiveKey,
    isBlackFridayUpgradeEligible,
    isAudioNotificationEnabled,
    openConversationCount,
    userPreferences,
    notAuthorizedModalStatus,
    isOpenCreateDealDrawer,
    pipelineListData,
    stagesList,
    contacts,
    contactListLoader,
    createDealBtnLoader,
    companyList,
    companyListLoader,
    userList,
    isSelectChannelModalVisible,
    createChatLoader,
    emailTemplates,
    emailTemplatesLoader,
    selectedTemplateId,
    templateType,
    templateJson,
    chatRooms,
    createBroadcastLoader,
    createPostLoader,
    displayOmniSearch,
    currentOmniSearchState,
    displayKeyboardShortcuts,
    articles,
    chatTagsData,
    userTagList,
    tags,
    meetingSchedulerList,
    agentTimingList,
    snippets,
    snippetCategories,
    mostRecentSnippets,
    teams,
    teamMates,
    selectedChatRoom,
    userPermissions,
    selectedChatRoomMessages,
    contactModalVisible,
    fetchConversations,
    fetchCurrentConversationMessages,
    fetchCurrentPersonProfile,
    setSelectedChatRoom,
    conversationInboxCount,
    removedConversationIdentifier,
    replyEditor,
    noteEditor,
    activeComposerTab,
    currentConversationId,
    statusFilter,
    meetingAgentId,
    modalContactId,
    isComposerUndo,
    inboxFilter,
    showNewConversation,
    agentTypedReplyMessages,
    agentTypedNoteMessages,
    askEmmaMatchFound,
    aiAssistMode,
    inboxViewMode,
    redirectingToSearch,
    isFromPreviousConversation,
    previousConversations,
    selectedPreviousConversation,
    selectedNewsCenter
  };
}

export default connect(
  mapStateToProps,
  {
    ...authAction,
    ...contactsActions,
    ...chatActions,
  },
)(App);
//
// const createDynamicTitlePatterns = (props) => ({
//   '^/contacts/[a-zA-Z0-9-]+$': () => {
//     const { contactProfileData } = props;
//     if (contactProfileData) {
//       return contactProfileData.profile.full_name
//         ? `${contactProfileData.profile.full_name} - Contacts`
//         : 'Site Visitor - Contacts';
//     }
//     return null;
//   },
//   '^/email/behavioral/[a-zA-Z0-9-]+/reports$': () =>
//     this.props.reportData.emailPreview
//       ? `Report - ${this.props.reportData.emailPreview.title} - Behavioral Emails`
//       : null,
//   '^/behavioral/[a-zA-Z0-9-]+$': () =>
//     this.props.autoEmailName
//       ? `${this.props.autoEmailName} - Behavioral Emails`
//       : null,
//   '^/broadcasts/[a-zA-Z0-9-]+/reports$': () =>
//     this.props.reportData.emailPreview
//       ? `Report - ${this.props.reportData.emailPreview.title} - Broadcasts`
//       : null,
//   '^/broadcasts/[a-zA-Z0-9-]+$': () =>
//     this.props.broadcastName
//       ? `${this.props.broadcastName} - Broadcasts`
//       : null,
//   '^/campaigns/[a-zA-Z0-9-]+/email+/[a-zA-Z0-9-]+/reports$': () =>
//     this.props.reportData.emailPreview
//       ? `Report - ${this.props.reportData.emailPreview.title} - Drip Campaigns`
//       : null,
//   '^/email/campaigns/[a-zA-Z0-9-]+/email+/[a-zA-Z0-9-]+$': () =>
//     this.props.campaignsData && this.props.reportData.emailPreview
//       ? `${this.props.campaignsData.name} - Emails - ${this.props.reportData.emailPreview.title} - Drip Campaigns`
//       : null,
//   '^/campaigns/[a-zA-Z0-9-]+$': () =>
//     this.props.campaignsData && this.props.campaignsData.name
//       ? `${this.props.campaignsData.name} - Drip Campaigns`
//       : null,
//   '^/workflows/[a-zA-Z0-9-]+$': () =>
//     this.props.workflowDetail
//       ? `${this.props.workflowDetail.name} - Workflows`
//       : null,
//   '^/workflow-templates/[a-zA-Z0-9-]+$': () =>
//     this.props.workflowDetail
//       ? `${this.props.workflowDetail.name} - Workflow Templates`
//       : null,
//   '^/rules/[a-zA-Z0-9-]+$': () =>
//     this.props.triggerName
//       ? `${this.props.triggerName} - Automation Rules`
//       : null,
//   '^/meetings/[a-zA-Z0-9-]+$': () =>
//     this.props.meetingData?.name
//       ? `${this.props.meetingData.name} - Meetings`
//       : null,
//   '^/knowledge-base/article/[a-zA-Z0-9-]+$': () =>
//     this.props.articleDetail
//       ? `${this.props.articleDetail.title} - Knowledge Base`
//       : null,
//   '^/forms/[a-zA-Z0-9-]+$': () =>
//     this.props.editorPageDetails && this.props.editorPageDetails.title
//       ? `${this.props.editorPageDetails.title} - Forms`
//       : null,
//   '^/conversations/[a-zA-Z0-9-]+$': () => 'Inbox',
//   '^/company/[a-zA-Z0-9-]+$': () =>
//     this.props.companyDetails
//       ? `${this.props.companyDetails.name} - Companies`
//       : null,
//   '^/deals/[0-9]+$': () => 'Deals',
// });
//
// const updatePageTitle = () => {
//   const { location } = this.props;
//   let pathname = location.pathname.toLowerCase();
//
//   // Check if the URL contains '/projects/'
//   if (pathname.includes('/projects/')) {
//     // Extract the part of the URL after '/projects/projectId/'
//     const parts = pathname.split('/');
//     const projectIndex = parts.indexOf('projects');
//     if (projectIndex !== -1 && parts.length > projectIndex + 2) {
//       pathname = `/${parts.slice(projectIndex + 2).join('/')}`;
//     }
//   }
//
//   // Remove trailing slash if present
//   pathname = pathname.replace(/\/$/, '');
//
//   // Find the title by checking if any key is a suffix of the pathname
//   const staticTitle = Object.keys(PAGE_TITLES).find((key) =>
//     pathname.endsWith(key),
//   );
//
//   if (staticTitle) {
//     this.setDocumentTitle(PAGE_TITLES[staticTitle]);
//     return;
//   }
//
//   for (const [pattern, titleGetter] of Object.entries(
//     this.DYNAMIC_TITLE_PATTERNS,
//   )) {
//     if (new RegExp(pattern).test(pathname)) {
//       const dynamicTitle = titleGetter(this.props);
//       if (dynamicTitle) {
//         this.setDocumentTitle(dynamicTitle);
//         return;
//       }
//     }
//   }
//
//   this.setDocumentTitle('');
// };
//
// const setDocumentTitle = (title) => {
//   document.title = `${sanitizer(title)} - ${sanitizer(
//     this.props.currentProjectName,
//   )} - Gist`;
// };
//
//
// if (this.props.location !== prevProps.location) {
//   this.updatePageTitle();
// }
// // Update DYNAMIC_TITLE_PATTERNS if any relevant props have changed
// if (
//   this.props.contactProfileData !== prevProps.contactProfileData ||
//   this.props.reportData !== prevProps.reportData ||
//   this.props.autoEmailName !== prevProps.autoEmailName ||
//   this.props.broadcastName !== prevProps.broadcastName ||
//   this.props.campaignsData !== prevProps.campaignsData ||
//   this.props.workflowDetail !== prevProps.workflowDetail ||
//   this.props.triggerName !== prevProps.triggerName ||
//   this.props.companyDetails !== prevProps.companyDetails ||
//   this.props.meetingData !== prevProps.meetingData ||
//   this.props.articleDetail !== prevProps.articleDetail ||
//   this.props.editorPageDetails !== prevProps.editorPageDetails
// ) {
//   this.DYNAMIC_TITLE_PATTERNS = this.createDynamicTitlePatterns(this.props);
//   this.updatePageTitle();
// }
