import React, { useMemo, useState } from 'react';
import {
  Row,
  Col,
  Layout,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tooltip,
  Icon,
  InputNumber,
} from 'antd';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/theme/panda-syntax.css';
import './canvas.scss';
import '../../components/Chat/UserProperties/Widgets/style.scss';
import '../../components/Chat/UserProperties/Widgets/messenger-style.scss';
import InboxWidget from '../../components/Chat/UserProperties/Widgets/InboxWidget';
import TextComponent from '../../components/Chat/UserProperties/Widgets/Components/TextComponent';
import DataTableComponent from '../../components/Chat/UserProperties/Widgets/Components/DataTableComponent';
import ImageComponent from '../../components/Chat/UserProperties/Widgets/Components/ImageComponent';
import SpacerComponent from '../../components/Chat/UserProperties/Widgets/Components/SpacerComponent';
import DividerComponent from '../../components/Chat/UserProperties/Widgets/Components/DividerComponent';
import ButtonComponent from '../../components/Chat/UserProperties/Widgets/Components/ButtonComponent';
import InputComponent from '../../components/Chat/UserProperties/Widgets/Components/InputComponent';
import TextAreaComponent from '../../components/Chat/UserProperties/Widgets/Components/TextAreaComponent';
import ListComponent from '../../components/Chat/UserProperties/Widgets/Components/ListComponent';
import SingleSelectComponent from '../../components/Chat/UserProperties/Widgets/Components/SingleSelectComponent';
import CheckboxComponent from '../../components/Chat/UserProperties/Widgets/Components/CheckboxComponent';
import DropdownComponent from '../../components/Chat/UserProperties/Widgets/Components/DropdownComponent';
import { getQueryParamsValue } from '../../helpers/utility';
import { defaultResponseObject } from '../../components/Chat/UserProperties/Widgets/constants';

const options = {
  mode: 'javascript',
  gutters: ['CodeMirror-lint-markers'],
  lint: true,
  lineNumbers: true,
  theme: 'panda-syntax',
};
const Option = Select.Option;

// const sampleCode = `[
// {
//   "type": "text",
//   "text": "**Create a ticket**",
//   "style": "header"
// },
// {
//   "type": "input",
//   "id": "title",
//   "label": "Title",
//   "placeholder": "Enter a title for your issue..."
// },
// {
//   "type": "textarea",
//   "id": "description",
//   "label": "Description",
//   "placeholder": "Enter a description of the issue..."
// },
// {
//   "type": "dropdown",
//   "id": "label",
//   "label": "Label",
//   "options": [
//     {
//       "type": "option",
//       "id": "bug",
//       "text": "Bug"
//     },
//     {
//       "type": "option",
//       "id": "feedback",
//       "text": "Feedback"
//     }
//   ]
// },
// {
//   "type": "single-select",
//   "id": "priority",
//   "label": "Priority",
//   "options": [
//     {
//       "type": "option",
//       "id": "low",
//       "text": "Low"
//     },
//     {
//       "type": "option",
//       "id": "medium",
//       "text": "Medium"
//     },
//     {
//       "type": "option",
//       "id": "high",
//       "text": "High"
//     }
//   ]
// },
// {
//   "type": "spacer",
//   "size": "s"
// },
// {
//   "type": "button",
//   "id": "submit-issue-form",
//   "label": "Create issue",
//   "style": "primary",
//   "action": {
//     "type": "submit"
//   }
// }
// ]`;

const CanvasBuilder = () => {
  const defaultCode = useMemo(
    () =>
      getQueryParamsValue('code') === 'null'
        ? null
        : getQueryParamsValue('code'),
    [],
  );

  const [code, setCode] = useState(
    defaultCode ||
      JSON.stringify(defaultResponseObject.canvas.content.components, null, 2),
  );
  const [isBlockModalVisible, setIsBlockModalVisible] = useState(false);
  const [activeCanvasStyle, setActiveCanvasStyle] = useState('messenger');

  const addNewBlock = (block) => {
    let parsedCode = JSON.parse(code || '[]');
    setCode(JSON.stringify([...parsedCode, block], null, 2));
    setIsBlockModalVisible(false);
  };

  let codeJSON = [];
  try {
    codeJSON = JSON.parse(code);
  } catch (e) {}

  return (
    <Layout className="content-container developers-container">
      <div className="content">
        <Row>
          <Col span={12}>
            <div className="toolbar">
              <Button
                className="btn btn-secondary-outline btn-sm"
                onClick={() => setIsBlockModalVisible(true)}
              >
                Add new component
              </Button>
            </div>
            <CodeMirror
              value={code}
              onBeforeChange={(editor, data, value) => {
                setCode(value);
              }}
              onChange={(editor, data, value) => {}}
              options={options}
            />
          </Col>
          <Col span={12}>
            <div className="canvas-header">
              <span className="text-muted">Select canvas style:</span>

              <div className="canvas-tabs">
                <button
                  className={`tab-switcher ${
                    activeCanvasStyle === 'inbox' ? 'active' : ''
                  }`}
                  onClick={() => setActiveCanvasStyle('inbox')}
                >
                  Inbox
                </button>

                <button
                  className={`tab-switcher ${
                    activeCanvasStyle === 'messenger' ? 'active' : ''
                  }`}
                  onClick={() => setActiveCanvasStyle('messenger')}
                >
                  Messenger
                </button>
              </div>
            </div>

            <div className="canvas-preview">
              <div className="canvas">
                <div id={`${activeCanvasStyle}-canvas`}>
                  <div className="card">
                    {activeCanvasStyle === 'inbox' && (
                      <div className="card-header">
                        <i className="fal fa-cube" />
                        <div className="app-name">Your App Name</div>
                      </div>
                    )}

                    <div className="card-body">
                      <div className="inbox-widget-canvas">
                        <InboxWidget
                          response={{
                            canvas: {
                              content: {
                                components: codeJSON,
                              },
                            },
                          }}
                          preview={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <BlockModal
        onBlockAdd={addNewBlock}
        isBlockModalVisible={isBlockModalVisible}
        setIsBlockModalVisible={setIsBlockModalVisible}
      />
    </Layout>
  );
};
export default CanvasBuilder;

const BlockModal = (props) => {
  const { onBlockAdd, isBlockModalVisible, setIsBlockModalVisible } = props;
  const [viewState, setViewState] = useState('block-select');
  const [selectedBlock, setSelectedBlock] = useState();

  const blocks = [
    {
      type: 'text',
      name: 'Text',
    },
    {
      type: 'data-table',
      name: 'Data table',
    },
    {
      type: 'image',
      name: 'Image',
    },
    {
      type: 'spacer',
      name: 'Spacer',
    },
    {
      type: 'divider',
      name: 'Divider',
    },
    {
      type: 'button',
      name: 'Button',
    },
    {
      type: 'input',
      name: 'Input',
    },
    {
      type: 'textarea',
      name: 'Textarea',
    },
    {
      type: 'list',
      name: 'List',
    },
    {
      type: 'single-select',
      name: 'Single select',
    },
    {
      type: 'checkbox',
      name: 'Checkbox',
    },
    {
      type: 'dropdown',
      name: 'Dropdown',
    },
  ];

  const onInsertBlock = (block) => {
    onBlockAdd(block);
    setViewState('block-select');
  };

  return (
    <Modal
      width={800}
      visible={isBlockModalVisible}
      wrapClassName={'add-block-modal'}
      footer={null}
      title={''}
      closable
      onCancel={() => {
        setIsBlockModalVisible(false);
        setViewState('block-select');
      }}
      destroyOnClose={true}
    >
      <>
        {viewState === 'block-select' && (
          <div className="block-container">
            <h3>Choose component</h3>
            {blocks.map((block, index) => (
              <div
                className="block"
                key={index}
                onClick={() => {
                  setViewState('block-config');
                  setSelectedBlock(block);
                }}
              >
                {block.name}
              </div>
            ))}
          </div>
        )}

        {viewState === 'block-config' && (
          <>
            <Row>
              <Col span={11}>
                <div className="add-block-modal-header">
                  <div
                    className="back"
                    onClick={() => setViewState('block-select')}
                  >
                    <Icon type="left" />
                    Back
                  </div>

                  <h3>Edit component</h3>
                </div>
              </Col>

              <Col span={11} offset={2}>
                <strong>Preview</strong>
              </Col>
            </Row>

            {selectedBlock.type === 'text' && (
              <TextBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'data-table' && (
              <DataTableBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'image' && (
              <ImageBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'spacer' && (
              <SpacerBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'divider' && (
              <DividerBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'button' && (
              <ButtonBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'input' && (
              <InputBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'textarea' && (
              <TextAreaBlock onBlockAdd={onInsertBlock} />
            )}
            {selectedBlock.type === 'list' && (
              <ListBlock onBlockAdd={onInsertBlock} />
            )}
            {(selectedBlock.type === 'single-select' ||
              selectedBlock.type === 'checkbox' ||
              selectedBlock.type === 'dropdown') && (
              <SelectBlock
                onBlockAdd={onInsertBlock}
                blockType={selectedBlock.type}
              />
            )}
          </>
        )}
      </>
    </Modal>
  );
};

const TextBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'text',
    text: 'This is a paragraph',
    style: 'paragraph',
    align: 'left',
  });

  const handleBlockUpdate = (type, value) => {
    setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
  };

  return (
    <Row className="container">
      <Col span={12}>
        <Form.Item
          label={
            <span>
              Text
              <Tooltip title="The text that will be rendered.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.text}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('text', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Align
              <Tooltip title="Aligns the text.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.align}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('align', value)}
          >
            <Option value="left">Left</Option>
            <Option value="center">Center</Option>
            <Option value="right">Right</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Style
              <Tooltip title="Styles the text.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.style}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('style', value)}
          >
            <Option value="header">Header</Option>
            <Option value="paragraph">Paragraph</Option>
            <Option value="muted">Muted</Option>
            <Option value="error">Error</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Bottom margin
              <Tooltip title="Disables a component’s margin-bottom of 10px.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.bottom_margin === 'none' ? 'none' : '10px'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('bottom_margin', value)}
          >
            <Option value="none">None</Option>
            <Option value="10px">10px</Option>
          </Select>
        </Form.Item>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <TextComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const DataTableBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'data-table',
    items: [
      {
        type: 'field-value',
        field: 'Key',
        value: 'Value 1',
      },
      {
        type: 'field-value',
        field: 'Key',
        value: 'Value 2',
      },
    ],
  });

  const addNewField = () => {
    const placeholder = {
      type: 'field-value',
      field: '',
      value: '',
    };

    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{ items: [...prevBlock.items, placeholder] },
    }));
  };

  const removeField = (index) => {
    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{
        items: [
          ...prevBlock.items.slice(0, index),
          ...prevBlock.items.slice(index + 1),
        ],
      },
    }));
  };

  const handleBlockUpdate = (index, type, value) => {
    let updatedItem = block.items[index];
    updatedItem[type] = value;

    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{
        items: [
          ...prevBlock.items.slice(0, index),
          updatedItem,
          ...prevBlock.items.slice(index + 1),
        ],
      },
    }));
  };

  return (
    <Row className="container">
      <Col span={12}>
        {block.items.map((item, index) => (
          <Row key={index}>
            <Col span={10}>
              <Form.Item>
                <Input
                  value={item.field}
                  className="form-item input-sm"
                  onChange={(event) =>
                    handleBlockUpdate(index, 'field', event.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={10} offset={1}>
              <Form.Item>
                <Input
                  value={item.value}
                  className="form-item input-sm"
                  onChange={(event) =>
                    handleBlockUpdate(index, 'value', event.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={2} offset={1}>
              <span className="delete" onClick={() => removeField(index)}>
                <i className="fal fa-trash" />
              </span>
            </Col>
          </Row>
        ))}

        <span className="link" onClick={() => addNewField()}>
          Add new
        </span>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <DataTableComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const ImageBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'image',
    url: 'https://picsum.photos/530/240',
    height: 240,
    width: 530,
    bottom_margin: 'none',
    action: {
      type: 'url',
      url: 'https://google.com',
    },
  });

  const handleBlockUpdate = (type, value) => {
    if (type === 'action_url') {
      if (value && value !== '') {
        let actionObj = {
          type: 'url',
          url: value,
        };
        setBlock((prevBlock) => ({ ...prevBlock, ...{ action: actionObj } }));
      } else {
        setBlock((prevBlock) => {
          let newBlock = { ...prevBlock };
          delete newBlock['action'];
          return newBlock;
        });
      }
    } else {
      setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
    }
  };

  return (
    <Row className="container">
      <Col span={12}>
        <Form.Item
          label={
            <span>
              Image URL
              <Tooltip title="The URL where the image is located.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.url}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('url', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Image width
              <Tooltip title="The width of the image.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <InputNumber
            value={block.width}
            className="form-item input-number-md"
            onChange={(value) => handleBlockUpdate('width', value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Image height
              <Tooltip title="The height of the image.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <InputNumber
            value={block.height}
            className="form-item input-number-md"
            onChange={(value) => handleBlockUpdate('height', value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Align
              <Tooltip title="Aligns the image inside the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.align || 'left'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('align', value)}
          >
            <Option value="center">Center</Option>
            <Option value="right">Right</Option>
            <Option value="full-width">Full width</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Rounded
              <Tooltip title="Rounds the corners of the image.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.rounded || false}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('rounded', value)}
          >
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Bottom margin
              <Tooltip title="Disables a component’s margin-bottom of 10px.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.bottom_margin === 'none' ? 'none' : '10px'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('bottom_margin', value)}
          >
            <Option value="none">None</Option>
            <Option value="10px">10px</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Action URL
              <Tooltip title="The URL will open in a new browser tab when the image is clicked.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.action && block.action.url}
            className="form-item input-md"
            onChange={(event) =>
              handleBlockUpdate('action_url', event.target.value)
            }
          />
        </Form.Item>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <ImageComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const SpacerBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'spacer',
    size: 's',
  });

  const handleBlockUpdate = (type, value) => {
    setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
  };

  return (
    <Row className="container">
      <Col span={12}>
        <Form.Item
          label={
            <span>
              Size
              <Tooltip title="The amount of space between components.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.size}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('size', value)}
          >
            <Option value="xs">Very small</Option>
            <Option value="s">Small</Option>
            <Option value="m">Medium</Option>
            <Option value="l">Large</Option>
            <Option value="xl">Very large</Option>
          </Select>
        </Form.Item>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <SpacerComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const DividerBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'divider',
  });

  const handleBlockUpdate = (type, value) => {
    setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
  };

  return (
    <Row className="container">
      <Col span={12}>
        <Form.Item
          label={
            <span>
              Bottom margin
              <Tooltip title="Disables a component’s margin-bottom of 10px.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.bottom_margin === 'none' ? 'none' : '10px'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('bottom_margin', value)}
          >
            <Option value="none">None</Option>
            <Option value="10px">10px</Option>
          </Select>
        </Form.Item>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <DividerComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const ButtonBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'button',
    id: 'primary-1',
    label: 'Primary',
    style: 'primary',
    action: {
      type: 'submit',
    },
  });

  const handleBlockUpdate = (type, value, actionType) => {
    if (type === 'action_type') {
      if (value === 'submit') {
        let actionObj = {
          type: 'submit',
        };
        setBlock((prevBlock) => ({ ...prevBlock, ...{ action: actionObj } }));
      } else if (['url', 'sheet'].includes(value)) {
        let actionObj = {
          type: value,
          url: '',
        };
        setBlock((prevBlock) => ({ ...prevBlock, ...{ action: actionObj } }));
      } else {
        setBlock((prevBlock) => {
          let newBlock = { ...prevBlock };
          delete newBlock['action'];
          return newBlock;
        });
      }
    } else if (type === 'action_url') {
      if (value && value !== '') {
        let actionObj = {
          type: actionType,
          url: value,
        };
        setBlock((prevBlock) => ({ ...prevBlock, ...{ action: actionObj } }));
      } else {
        setBlock((prevBlock) => {
          let newBlock = { ...prevBlock };
          delete newBlock['action'];
          return newBlock;
        });
      }
    } else {
      setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
    }
  };

  return (
    <Row className="container">
      <Col span={12}>
        <Form.Item
          label={
            <span>
              ID
              <Tooltip title="A unique identifier for the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.id}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('id', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Button text
              <Tooltip title="The call to action text.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.label}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('label', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Style
              <Tooltip title="Styles the button.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.style}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('style', value)}
          >
            <Option value="primary">Primary</Option>
            <Option value="secondary">Secondary</Option>
            <Option value="link">Link</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Disabled
              <Tooltip title="Disables the button.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.disabled || false}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('disabled', value)}
          >
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Action type
              <Tooltip title="The type of action you are attaching to this component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={(block.action && block.action.type) || 'none'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('action_type', value)}
          >
            <Option value="none">None</Option>
            <Option value="sheet">Sheet</Option>
            <Option value="url">URL Action</Option>
            <Option value="submit">Submit Action</Option>
          </Select>
        </Form.Item>

        {['url', 'sheet'].includes(
          block?.action?.type) && (
            <Form.Item
              label={
                <span>
                  Action URL
                  <Tooltip title="The URL will open in a new browser tab when the image is clicked.">
                    <i className="fal fa-circle-question" />
                  </Tooltip>
                </span>
              }
            >
              <Input
                value={block.action && block.action.url}
                className="form-item input-md"
                onChange={event =>
                  handleBlockUpdate('action_url', event.target.value, block.action.type)
                }
              />
            </Form.Item>
          )}

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <ButtonComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const InputBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'input',
    id: 'unsaved-1',
    label: 'Unsaved',
    placeholder: 'Enter input here...',
    save_state: 'unsaved',
    action: {
      type: 'submit',
    },
  });

  const handleBlockUpdate = (type, value) => {
    if (type === 'action_type') {
      if (value === 'submit') {
        let actionObj = {
          type: 'submit',
        };
        setBlock((prevBlock) => ({ ...prevBlock, ...{ action: actionObj } }));
      } else {
        setBlock((prevBlock) => {
          let newBlock = { ...prevBlock };
          delete newBlock['action'];
          return newBlock;
        });
      }
    } else {
      setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
    }
  };

  return (
    <Row className="container">
      <Col span={12}>
        <Form.Item
          label={
            <span>
              ID
              <Tooltip title="A unique identifier for the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.id}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('id', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Label
              <Tooltip title="The text shown above the input.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.label}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('label', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Placeholder
              <Tooltip title="An example value shown inside the component when it’s empty.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.placeholder}
            className="form-item input-md"
            onChange={(event) =>
              handleBlockUpdate('placeholder', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Value
              <Tooltip title="An entered value which is already inside the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.value}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('value', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Save state
              <Tooltip title="Styles the input.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.save_state || 'unsaved'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('save_state', value)}
          >
            <Option value="unsaved">Unsaved</Option>
            <Option value="saved">Saved</Option>
            <Option value="failed">Failed</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Disabled
              <Tooltip title="Disables the input.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.disabled || false}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('disabled', value)}
          >
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Action type
              <Tooltip title="The type of action you are attaching to this component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={(block.action && block.action.type) || 'none'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('action_type', value)}
          >
            <Option value="none">None</Option>
            <Option value="submit">Submit Action</Option>
          </Select>
        </Form.Item>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <InputComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const TextAreaBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'textarea',
    id: 'unsaved-1',
    label: 'Unsaved',
    placeholder: 'Enter input here...',
  });

  const handleBlockUpdate = (type, value) => {
    if (type === 'action_type') {
      if (value === 'submit') {
        let actionObj = {
          type: 'submit',
        };
        setBlock((prevBlock) => ({ ...prevBlock, ...{ action: actionObj } }));
      } else {
        setBlock((prevBlock) => {
          let newBlock = { ...prevBlock };
          delete newBlock['action'];
          return newBlock;
        });
      }
    } else {
      setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
    }
  };

  return (
    <Row className="container">
      <Col span={12}>
        <Form.Item
          label={
            <span>
              ID
              <Tooltip title="A unique identifier for the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.id}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('id', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Label
              <Tooltip title="The text shown above the input.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.label}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('label', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Placeholder
              <Tooltip title="An example value shown inside the component when it’s empty.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.placeholder}
            className="form-item input-md"
            onChange={(event) =>
              handleBlockUpdate('placeholder', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Value
              <Tooltip title="An entered value which is already inside the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.value}
            className="form-item input-md"
            onChange={(event) => handleBlockUpdate('value', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Error
              <Tooltip title="Styles the input as failed.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.error || 'unsaved'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('error', value)}
          >
            <Option value={false}>False</Option>
            <Option value={true}>True</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Disabled
              <Tooltip title="Disables the input.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.disabled || false}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleBlockUpdate('disabled', value)}
          >
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <TextAreaComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const ListBlock = (props) => {
  const { onBlockAdd } = props;
  const [block, setBlock] = useState({
    type: 'list',
    disabled: false,
    items: [
      {
        type: 'item',
        id: 'list-item1',
        title: 'Item 1',
        subtitle: 'With Rounded Image',
        tertiary_text: 'With Action',
        image: 'https://picsum.photos/600',
        image_width: 48,
        image_height: 48,
        rounded_image: true,
        action: {
          type: 'submit',
        },
      },
    ],
  });

  const addNewField = () => {
    const placeholder = {
      type: 'item',
      id: 'list-item',
      title: 'Item',
      subtitle: 'With Subtitle',
    };

    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{ items: [...prevBlock.items, placeholder] },
    }));
  };

  const removeField = (index) => {
    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{
        items: [
          ...prevBlock.items.slice(0, index),
          ...prevBlock.items.slice(index + 1),
        ],
      },
    }));
  };

  const handleMainBlockUpdate = (type, value) => {
    setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
  };

  const handleBlockUpdate = (index, type, value, actionType) => {
    let updatedItem = block.items[index];

    if (type === 'action_type') {
      if (value === 'submit') {
        let actionObj = {
          type: 'submit',
        };
        updatedItem['action'] = actionObj;
      } else if (['url', 'sheet'].includes(value)) {
        let actionObj = {
          type: value,
          url: '',
        };
        updatedItem['action'] = actionObj;
      } else {
        delete updatedItem['action'];
      }
    } else if (type === 'action_url') {
      if (value && value !== '') {
        let actionObj = {
          type: actionType,
          url: value,
        };
        updatedItem['action'] = actionObj;
      } else {
        delete updatedItem['action'];
      }
    } else {
      updatedItem[type] = value;
    }

    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{
        items: [
          ...prevBlock.items.slice(0, index),
          updatedItem,
          ...prevBlock.items.slice(index + 1),
        ],
      },
    }));
  };

  return (
    <Row className="container">
      <Col span={12} className="scrollable">
        {block.items.map((item, index) => (
          <div className="list-item-block" key={index}>
            <Form.Item label="ID">
              <Input
                value={item.id}
                className="form-item input-md"
                onChange={(event) =>
                  handleBlockUpdate(index, 'id', event.target.value)
                }
              />
            </Form.Item>

            <Form.Item label="Title">
              <Input
                value={item.title}
                className="form-item input-sm"
                onChange={(event) =>
                  handleBlockUpdate(index, 'title', event.target.value)
                }
              />
            </Form.Item>

            <Form.Item label="Subtitle">
              <Input
                value={item.subtitle}
                className="form-item input-sm"
                onChange={(event) =>
                  handleBlockUpdate(index, 'subtitle', event.target.value)
                }
              />
            </Form.Item>

            <Form.Item label="Tertiary text">
              <Input
                value={item.tertiary_text}
                className="form-item input-sm"
                onChange={(event) =>
                  handleBlockUpdate(index, 'tertiary_text', event.target.value)
                }
              />
            </Form.Item>

            <Form.Item label="Image URL">
              <Input
                value={item.image}
                className="form-item input-sm"
                onChange={(event) =>
                  handleBlockUpdate(index, 'image', event.target.value)
                }
              />
            </Form.Item>

            <Form.Item label="Image width">
              <InputNumber
                value={item.image_width}
                className="form-item input-number-md"
                onChange={(value) =>
                  handleBlockUpdate(index, 'image_width', value)
                }
              />
            </Form.Item>

            <Form.Item label="Image height">
              <InputNumber
                value={item.image_height}
                className="form-item input-number-md"
                onChange={(value) =>
                  handleBlockUpdate(index, 'image_height', value)
                }
              />
            </Form.Item>

            <Form.Item label="Rounded image">
              <Select
                value={item.rounded_image || false}
                suffixIcon={<i className={'fas fa-caret-down'} />}
                className="form-item select-sm"
                onChange={(value) =>
                  handleBlockUpdate(index, 'rounded_image', value)
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Disabled">
              <Select
                value={item.disabled || false}
                suffixIcon={<i className={'fas fa-caret-down'} />}
                className="form-item select-sm"
                onChange={(value) =>
                  handleBlockUpdate(index, 'disabled', value)
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Action type">
              <Select
                value={(item.action && item.action.type) || 'none'}
                suffixIcon={<i className={'fas fa-caret-down'} />}
                className="form-item select-sm"
                onChange={(value) =>
                  handleBlockUpdate(index, 'action_type', value)
                }
              >
                <Option value="none">None</Option>
                <Option value="sheet">Sheet</Option>
                <Option value="url">URL Action</Option>
                <Option value="submit">Submit Action</Option>
              </Select>
            </Form.Item>

            {['url', 'sheet'].includes(item?.action?.type) && (
              <Form.Item label="Action URL">
                <Input
                  value={item.action && item.action.url}
                  className="form-item input-md"
                  onChange={(event) =>
                    handleBlockUpdate(index, 'action_url', event.target.value,item.action.type)
                  }
                />
              </Form.Item>
            )}

            <span className="link" onClick={() => removeField(index)}>
              Delete item
            </span>
          </div>
        ))}

        <span className="link" onClick={() => addNewField()}>
          Add new item
        </span>

        <Form.Item
          label={
            <span>
              Disabled
              <Tooltip title="Disables all list items.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.disabled || false}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleMainBlockUpdate('disabled', value)}
          >
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              <ListComponent component={block} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const SelectBlock = (props) => {
  const { onBlockAdd, blockType } = props;

  let sampleBlock = {};
  if (blockType === 'single-select') {
    sampleBlock = {
      type: 'single-select',
      id: 'single-select-1',
      label: 'Unsaved Options',
      options: [
        {
          type: 'option',
          id: 'option-1',
          text: 'Option 1',
        },
        {
          type: 'option',
          id: 'option-2',
          text: 'Option 2',
        },
        {
          type: 'option',
          id: 'option-3',
          text: 'Option 3',
        },
      ],
    };
  } else if (blockType === 'checkbox') {
    sampleBlock = {
      type: 'checkbox',
      id: 'checkbox-1',
      label: 'Unsaved Options',
      options: [
        {
          type: 'option',
          id: 'option-1',
          text: 'Option 1',
        },
        {
          type: 'option',
          id: 'option-2',
          text: 'Option 2',
        },
        {
          type: 'option',
          id: 'option-3',
          text: 'Option 3',
        },
      ],
    };
  } else {
    sampleBlock = {
      type: 'dropdown',
      id: 'dropdown-1',
      label: 'Unsaved Options',
      options: [
        {
          type: 'option',
          id: 'option-1',
          text: 'Option 1',
        },
        {
          type: 'option',
          id: 'option-2',
          text: 'Option 2',
        },
        {
          type: 'option',
          id: 'option-3',
          text: 'Option 3',
        },
      ],
    };
  }

  const [block, setBlock] = useState(sampleBlock);

  const addNewField = () => {
    const placeholder = {
      type: 'option',
      id: 'option-1',
      text: 'Option 1',
    };

    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{ options: [...prevBlock.options, placeholder] },
    }));
  };

  const removeField = (index) => {
    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{
        options: [
          ...prevBlock.options.slice(0, index),
          ...prevBlock.options.slice(index + 1),
        ],
      },
    }));
  };

  const handleMainBlockUpdate = (type, value) => {
    if (type === 'action_type') {
      if (value === 'submit') {
        let actionObj = {
          type: 'submit',
        };
        setBlock((prevBlock) => ({ ...prevBlock, ...{ action: actionObj } }));
      } else {
        setBlock((prevBlock) => {
          let newBlock = { ...prevBlock };
          delete newBlock['action'];
          return newBlock;
        });
      }
    } else {
      setBlock((prevBlock) => ({ ...prevBlock, ...{ [type]: value } }));
    }
  };

  const handleBlockUpdate = (index, type, value) => {
    let updatedItem = block.options[index];
    updatedItem[type] = value;

    setBlock((prevBlock) => ({
      ...prevBlock,
      ...{
        options: [
          ...prevBlock.options.slice(0, index),
          updatedItem,
          ...prevBlock.options.slice(index + 1),
        ],
      },
    }));
  };

  return (
    <Row className="container">
      <Col span={12} className="scrollable">
        <Form.Item
          label={
            <span>
              ID
              <Tooltip title="A unique identifier for the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.id}
            className="form-item input-md"
            onChange={(event) =>
              handleMainBlockUpdate('id', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Label
              <Tooltip title="The text shown above the options.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.label}
            className="form-item input-md"
            onChange={(event) =>
              handleMainBlockUpdate('label', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Value
              <Tooltip title="An entered value which is already inside the component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Input
            value={block.value}
            className="form-item input-md"
            onChange={(event) =>
              handleMainBlockUpdate('value', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Save state
              <Tooltip title="Styles the options.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.save_state || 'unsaved'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleMainBlockUpdate('save_state', value)}
          >
            <Option value="unsaved">Unsaved</Option>
            <Option value="saved">Saved</Option>
            <Option value="failed">Failed</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Disabled
              <Tooltip title="Disables the options.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={block.disabled || false}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleMainBlockUpdate('disabled', value)}
          >
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Action type
              <Tooltip title="The type of action you are attaching to this component.">
                <i className="fal fa-circle-question" />
              </Tooltip>
            </span>
          }
        >
          <Select
            value={(block.action && block.action.type) || 'none'}
            suffixIcon={<i className={'fas fa-caret-down'} />}
            className="form-item select-sm"
            onChange={(value) => handleMainBlockUpdate('action_type', value)}
          >
            <Option value="none">None</Option>
            <Option value="submit">Submit Action</Option>
          </Select>
        </Form.Item>

        <div className="ant-form-item-label">
          <label>Options</label>
        </div>
        {block.options.map((option, index) => (
          <div className="list-item-block" key={index}>
            <Form.Item label="ID">
              <Input
                value={option.id}
                className="form-item input-md"
                onChange={(event) =>
                  handleBlockUpdate(index, 'id', event.target.value)
                }
              />
            </Form.Item>

            <Form.Item label="Text">
              <Input
                value={option.text}
                className="form-item input-sm"
                onChange={(event) =>
                  handleBlockUpdate(index, 'text', event.target.value)
                }
              />
            </Form.Item>

            <Form.Item label="Disabled">
              <Select
                value={option.disabled || false}
                suffixIcon={<i className={'fas fa-caret-down'} />}
                className="form-item select-sm"
                onChange={(value) =>
                  handleBlockUpdate(index, 'disabled', value)
                }
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>

            <span className="link" onClick={() => removeField(index)}>
              Delete item
            </span>
          </div>
        ))}

        <span className="link" onClick={() => addNewField()}>
          Add new item
        </span>

        <Button
          className="btn btn-primary btn-sm"
          onClick={() => onBlockAdd(block)}
        >
          Insert
        </Button>
      </Col>

      <Col span={11} offset={1}>
        <div className="inbox-widget-canvas">
          <div className="inbox-widget-card">
            <div
              className={`gist-card-component ${
                block.type
              }-component first-component${
                block.bottom_margin === 'none' ? ' margin-bottom-none' : ''
              }`}
            >
              {block.type === 'single-select' && (
                <SingleSelectComponent
                  component={block}
                  onChange={() => {}}
                  onSubmit={() => {}}
                />
              )}

              {block.type === 'checkbox' && (
                <CheckboxComponent component={block} onChange={() => {}} />
              )}

              {block.type === 'dropdown' && (
                <DropdownComponent component={block} onChange={() => {}} />
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
