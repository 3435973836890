import { all, takeEvery, put, call } from 'redux-saga/effects';
import settings from '../../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  updateBrowserHistory,
  deletePromise,
  patchPromise,
  disableAutoSave,
  unAuthorisedRedirection,
  trackEvent,
  unProcessableEntity,
} from '../../../helpers/utility';
import actions from './actions';
import { message } from 'antd';
import { history, store } from '../../store';
import reportActions from '../report/actions';
import {
  filterVariables,
  serverErrorCodes,
  unAuthorizedErrorCode,
} from '../../constants';
import authActions from '../../auth/actions';
import filterActions from '../../filters/actions';
import * as Sentry from '@sentry/react';

export function* fetchTemplates(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/templates_list?template_type=${params.templateType}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.CAMPAIGN_FETCH_TEMPLATES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch template failed');
    }
  }
}

export function* fetchCampaigns(params) {
  let {
    sortKey,
    sortValue,
    campaignSearchText,
    campaignStatus,
  } = store.getState().CampaignEmail;
  let status = params.status ? params.status : campaignStatus,
    searchText = params.searchText ? params.searchText : campaignSearchText,
    sortKeyTemp = params.sortKey && params.sortValue ? params.sortKey : sortKey,
    sortValueTemp =
      params.sortValue && params.sortKey ? params.sortValue : sortValue;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns?status=${status}&search_text=${encodeURIComponent(
      searchText,
    )}&sort_key=${sortKeyTemp}&sort_type=${sortValueTemp}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.CAMPAIGN_FETCH_CAMPAIGN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_CAMPAIGN_ERROR,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch campaign failed');
    }
  }
}

export function* appendCampaigns(params) {
  const {
    sortKey,
    sortValue,
    campaignSearchText,
    campaignStatus,
  } = store.getState().CampaignEmail;
  let status = params.status ? params.status : campaignStatus,
    lastCampaignId = params.lastCampaignId ? params.lastCampaignId : '';
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns?status=${status}&search_text=${encodeURIComponent(
      campaignSearchText,
    )}&sort_key=${sortKey}&sort_type=${sortValue}&last_campaign_id=${lastCampaignId}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_CAMPAIGN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch campaign failed');
    }
  }
}

export function* createCampaign(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/campaign_create`;
    const data = yield call(() => postPromise(url, params.campaign));
    trackEvent('Created campaign');
    updateBrowserHistory(`email/campaigns/${data.data.id}`);
    yield put({ type: actions.SET_CAMPAIGN_ID, payload: data.data });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('campaign creation failed');
    }
  }
  yield put({ type: actions.OPEN_CREATE_CAMPAIGN_DRAWER, payload: false });
  yield put({ type: actions.SET_DISABLE_CREATE_CAMPAIGN, payload: false });
}

export function* fetchCampaignEmails(params) {
  let searchText = ![null, undefined].includes(params.searchText)
    ? params.searchText
    : store.getState().CampaignEmail.emailSearchText;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${
      params.campaignId
    }?search_text=${encodeURIComponent(searchText)}`;
    const data = yield call(() => getPromise(url));
    yield put({ type: actions.EMPTY_CAMPAIGN_EMAILS });
    yield put({
      type: actions.FETCH_CAMPAIGN_EMAILS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_CAMPAIGN_EMAILS_ERROR,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch campaign emails failed');
    }
  }
}

export function* fetchCampaignDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${params.campaignId}/edit`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CAMPAIGN_DETAILS_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: filterActions.SET_FILTERS,
      payload: data.data.new_filters,
      filterName: filterVariables.campaignEntryKey,
    });
    yield put({
      type: filterActions.SET_FILTERS,
      payload: data.data.exit_filters,
      filterName: filterVariables.campaignExitKey,
    });
    if (data.data.message_goal) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.data.message_goal.goal_filter,
        filterName: filterVariables.campaignKeyGoal,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch campaign details failed');
    }
  }
}

export function* deleteCampaign(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${params.campaignId}`;
    let data = yield call(() => deletePromise(url));
    if (params.isFromSettings) {
      updateBrowserHistory('messages/campaigns');
    } else {
      yield put({
        type: actions.CAMPAIGN_FETCH_CAMPAIGNS,
        status: store.getState().CampaignEmail.campaignStatus,
        searchText: store.getState().CampaignEmail.campaignSearchText,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('campaign delete failed');
    }
  }
}

export function* deleteCampaignEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.campaignEmailId
    }`;
    yield call(() => deletePromise(url, { campaign_id: params.campaignId }));
    yield put({
      type: actions.FETCH_CAMPAIGN_EMAILS,
      searchText: store.getState().CampaignEmail.emailSearchText,
      campaignId: store.getState().CampaignEmail.currentCampaignId,
    });
    message.success('Email deleted successfully');
  } catch (error) {
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      Sentry.captureException(error);
    }
    if (error.response && error.response.status === unAuthorizedErrorCode) {
      store.dispatch({
        type: authActions.AUTHORIZATION_ERROR,
      });
      history.push('/login');
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else if (error.response && error.response.status === 404) {
      message.error('Email not found');
      updateBrowserHistory('messages/campaigns');
    } else if (error.response && error.response.status === 403) {
      if (error.response.data.error.message === 'Project blocked') {
        if (store.getState().Auth.owner) {
          updateBrowserHistory('settings/subscription');
        } else {
          updateBrowserHistory('project-blocked');
        }
      } else {
        message.error(error.response.data.error.message, 5);
      }
    } else {
      message.error('Email delete failed');
    }
  }
}

export function* createCampaignEmail(params) {
  let campaignId = store.getState().CampaignEmail.currentCampaignId;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${campaignId}/mail_filters/create_campaign_mail_filter`;
    const data = yield call(() => postPromise(url, params.campaignEmail));
    yield put({
      type: actions.DISABLE_CAMPAIGN_EMAIL_CREATION,
      payload: false,
    });
    trackEvent('Created campaign email');
    updateBrowserHistory(`email/campaigns/${campaignId}/email/${data.data.id}`);
    yield put({ type: actions.SET_CAMPAIGN_EMAIL_ID, payload: data.data });
  } catch (error) {
    yield put({
      type: actions.DISABLE_CAMPAIGN_EMAIL_CREATION,
      payload: false,
    });
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      Sentry.captureException(error);
    }
    if (error.response && error.response.status === unAuthorizedErrorCode) {
      store.dispatch({
        type: authActions.AUTHORIZATION_ERROR,
      });
      history.push('/login');
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else if (error.response && error.response.status === 404) {
      message.error('campaign not found');
      updateBrowserHistory('messages/campaigns');
    } else if (error.response && error.response.status === 403) {
      if (error.response.data.error.message === 'Project blocked') {
        if (store.getState().Auth.owner) {
          updateBrowserHistory('settings/subscription');
        } else {
          updateBrowserHistory('project-blocked');
        }
      } else {
        message.error(error.response.data.error.message, 5);
      }
    } else {
      message.error('Email creation failed');
    }
  }
}

export function* fetchEmailDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${
      params.campaignID
    }/mail_filters/${params.emailID}/edit`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CAMPAIGN_EMAIL_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch campaign details failed');
    }
  }
}

export function* createCampaignEmailComposer(params) {
  let campaignID = store.getState().CampaignEmail.currentCampaignId;
  yield put({ type: actions.SET_AUTO_SAVE_LOADER, payload: true });
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${campaignID}/mail_filters/campaign_mail_composer/${
      params.campaignEmailId
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    yield put({ type: actions.SET_AUTO_SAVE_LOADER, payload: false });
    yield put({
      type: actions.SET_COMPOSER_VALUE_CHANGE,
      payload: false,
    });
    yield put({
      type: actions.SET_CAMPAIGN_LAST_SAVE_TIME,
      payload: data.data,
      campaignDetails: params.object.mail_filter,
    });
    yield put({ type: actions.CHANGE_EMAIL_PUBLISH_STATUS, payload: false });
    if (params.isAutoSave) disableAutoSave();
    else if (params.isSave) message.success('Email successfully updated');
    if (params.isReports) {
      yield put({
        type: reportActions.UPDATE_EMAIL_NAME_SUCCESS,
        payload: params.object.mail_filter.title,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.SET_AUTO_SAVE_FAILURE, payload: true });
    yield put({ type: actions.CHANGE_EMAIL_PUBLISH_STATUS, payload: false });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Update email failed');
    }
    disableAutoSave();
  }
}

export function* sendTestEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/send_test_mail`;
    const data = yield call(() => postPromise(url, params.object));
    message.success(data.message);
    yield put({ type: actions.SEND_CAMPAIGN_TEST_EMAIL_SUCCESS });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Test mail send failed');
    }
    yield put({ type: actions.SEND_CAMPAIGN_TEST_EMAIL_FAILURE });
  }
}

export function* changeCampaignStatus(params) {
  let campaignID = params.campaignID
    ? params.campaignID
    : store.getState().CampaignEmail.currentCampaignId;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${campaignID}/change_campaign_status?status=${
      params.status
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    message.success(data.message);
    if (!params.campaignID) {
      updateBrowserHistory('messages/campaigns');
    }
    yield put({
      type: actions.CHANGE_CAMPAIGN_STATUS_SUCCESS,
      campaignID: campaignID,
      status: params.status,
    });
  } catch (error) {
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      Sentry.captureException(error);
    }
    if (error.response && error.response.status === unAuthorizedErrorCode) {
      store.dispatch({
        type: authActions.AUTHORIZATION_ERROR,
      });
      history.push('/login');
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else if (error.response && error.response.status === 404) {
      message.error('campaign not found');
    } else if (error.response && error.response.status === 403) {
      if (error.response.data.error.message === 'Project blocked') {
        if (store.getState().Auth.owner) {
          updateBrowserHistory('settings/subscription');
        } else {
          updateBrowserHistory('project-blocked');
        }
      } else {
        message.error(error.response.data.error.message, 5);
      }
    } else {
      message.error('campaign status change failed');
    }
  }
}

export function* changeEmailStatus(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${
      params.campaignID
    }/mail_filters/change_mail_filter_status/${params.emailID}?status=${
      params.status
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    message.success(data.message);
    if (params.from === 'email-list') {
      yield put({
        type: actions.CHANGE_EMAIL_STATUS_SUCCESS,
        emailID: params.emailID,
        status: params.status,
      });
    } else if (params.from === 'composer') {
      yield put({
        type: actions.CHANGE_CAMPAIGN_EMAIL_STATUS_SUCCESS,
        emailID: params.emailID,
        status: params.status,
      });
    }
    if (params.status === 'live') {
      yield put({
        type: actions.CHANGE_EMAIL_LIVE_STATUS,
        payload: false,
      });
    } else if (params.status === 'paused') {
      yield put({
        type: actions.CHANGE_EMAIL_PAUSE_STATUS,
        payload: false,
      });
    }
  } catch (error) {
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      Sentry.captureException(error);
    }
    if (params.status === 'live') {
      yield put({
        type: actions.CHANGE_EMAIL_LIVE_STATUS,
        payload: false,
      });
    } else if (params.status === 'paused') {
      yield put({
        type: actions.CHANGE_EMAIL_PAUSE_STATUS,
        payload: false,
      });
    }
    if (error.response && error.response.status === unAuthorizedErrorCode) {
      store.dispatch({
        type: authActions.AUTHORIZATION_ERROR,
      });
      history.push('/login');
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else if (error.response && error.response.status === 404) {
      message.error('Email not found');
      updateBrowserHistory('messages/campaigns');
    } else if (error.response && error.response.status === 403) {
      if (error.response.data.error.message === 'Project blocked') {
        if (store.getState().Auth.owner) {
          updateBrowserHistory('settings/subscription');
        } else {
          updateBrowserHistory('project-blocked');
        }
      } else {
        message.error(error.response.data.error.message, 5);
      }
    } else {
      message.error('Email status change failed');
    }
  }
}

export function* createDuplicateCampaign(params) {
  let values = params.values,
    campaignID = store.getState().CampaignEmail.currentCampaignId,
    isDuplicateAllEmail = values.isAllEmail ? values.isAllEmail : false;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${campaignID}/duplicate?duplicate_email=${isDuplicateAllEmail}&name=${encodeURIComponent(
      values.campaignName,
    )}
`;
    const data = yield call(() => postPromise(url));
    message.success('Duplicate campaign created successfully');
    yield put({ type: actions.SET_CAMPAIGN_TAB_KEY, payload: '1' });
    updateBrowserHistory(`email/campaigns/${data.data.id}`);
    yield put({ type: actions.SET_CAMPAIGN_ID, payload: data.data });
    yield put({
      type: actions.FETCH_CAMPAIGN_DETAILS,
      campaignId: data.data.id,
    });
  } catch (error) {
    if (error.response && serverErrorCodes.includes(error.response.status)) {
      Sentry.captureException(error);
    }
    if (error.response && error.response.status === unAuthorizedErrorCode) {
      store.dispatch({
        type: authActions.AUTHORIZATION_ERROR,
      });
      history.push('/login');
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else if (error.response && error.response.status === 404) {
      message.error('campaign not found');
      updateBrowserHistory('messages/campaigns');
    } else if (error.response && error.response.status === 403) {
      if (error.response.data.error.message === 'Project blocked') {
        if (store.getState().Auth.owner) {
          updateBrowserHistory('settings/subscription');
        } else {
          updateBrowserHistory('project-blocked');
        }
      } else {
        message.error(error.response.data.error.message, 5);
      }
    } else {
      message.error('Duplicate campaign creation failed');
    }
  }
  yield put({ type: actions.OPEN_CREATE_CAMPAIGN_DRAWER, payload: false });
  yield put({ type: actions.SET_DISABLE_CREATE_CAMPAIGN, payload: false });
}

export function* updateSettings(params) {
  let campaignID = store.getState().CampaignEmail.currentCampaignId,
    isSave =
      store.getState().CampaignEmail.campaignsData.status === 'live'
        ? params.isSave && true
        : true;
  if (isSave) {
    yield put({ type: actions.SET_AUTO_SAVE_LOADER, payload: true });
    try {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/campaigns/${campaignID}/settings
`;
      const data = yield call(() => patchPromise(url, params.settings));
      yield put({ type: actions.UPDATE_SETTINGS_SUCCESS, payload: data.data, messageGoal: params.settings.campaign.message_goal });
      yield put({ type: actions.SET_AUTO_SAVE_LOADER, payload: false });
      disableAutoSave();
      if (params.isSave) {
        message.success('Settings updated successfully!');
        store.dispatch({
          type: actions.SET_VALUE_SETTINGS_CHANGES,
          payload: false,
        });
      }
    } catch (error) {
      yield put({ type: actions.SET_AUTO_SAVE_FAILURE, payload: true });
      disableAutoSave();
      unAuthorisedRedirection(error);
      if (error.response && error.response.status === 422) {
        message.error(error.response.data.error.message);
      } else {
        message.error('General update failed');
      }
    }
  }
}

export function* fetchCampaignRecipientsCount(params) {
  let campaignID = params.campaignID
    ? params.campaignID
    : store.getState().CampaignEmail.currentCampaignId;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/get_filtered_people_count?campaign_id=${campaignID}&validate_email_sending=true`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.CAMPAIGN_FETCH_RECIPIENTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch recipients failed');
    yield put({
      type: actions.CAMPAIGN_FETCH_RECIPIENTS_FAILURE,
      payload:
        error.response.data &&
        error.response.data.data &&
        Object.keys(error.response.data.data).length > 0,
      count: error.response.data,
    });
  }
}

export function* changeEmailPosition(params) {
  let campaignId = store.getState().CampaignEmail.currentCampaignId;
  let orderedEmails = {
    positions: params.payload.map(email => email.id),
  };
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns/${campaignId}/update_mail_filters_position
`;
    yield call(() => patchPromise(url, orderedEmails));
    disableAutoSave();
  } catch (error) {
    disableAutoSave();
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
      yield put({ type: actions.FETCH_CAMPAIGN_EMAILS, campaignId });
    } else {
      message.error('Email position change failed');
    }
  }
}
export function* createRecipients(params) {
  let campaignID = store.getState().CampaignEmail.currentCampaignId,
    isLive =
      store.getState().CampaignEmail.campaignsData &&
      store.getState().CampaignEmail.campaignsData.status === 'live',
    isSave = isLive ? params.payload.isSave && true : true,
    {
      campaignEntryAudienceFilters,
      campaignExitAudienceFilters,
    } = store.getState().Filter,
    recipientsData = {
      campaign: {
        ...campaignEntryAudienceFilters,
        exit_filters: campaignExitAudienceFilters.new_filters,
      },
    };
  if (isSave) {
    yield put({ type: actions.SET_AUTO_SAVE_LOADER, payload: true });
    try {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/campaigns/${campaignID}/recipient_create`;
      const data = yield call(() => patchPromise(url, recipientsData));
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.data.new_filters,
        filterName: filterVariables.campaignEntryKey,
      });
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.data.exit_filters,
        filterName: filterVariables.campaignExitKey,
      });
      yield put({
        type: actions.SET_CAMPAIGN_LAST_SAVE_TIME,
        payload: data.data,
      });
      yield put({ type: actions.SET_CAMPAIGN_DATA, payload: data.data });
      yield put({ type: actions.SET_AUTO_SAVE_LOADER, payload: false });
      if (params.payload.isSave) {
        message.success('Recipient created successfully');
      }
      disableAutoSave();
    } catch (error) {
      yield put({ type: actions.SET_AUTO_SAVE_FAILURE, payload: true });
      disableAutoSave();
      unAuthorisedRedirection(error);
      message.error('Create recipient failed');
    }
  }
}

export function* fetchVisualBuilderJson(params) {
  try {
    let json = yield call(() =>
      getPromise(
        `${settings.BEE_EMAIL_TEMPLATE}${params.templateId}.json`,
        false,
      ),
    );
    yield put({
      type: actions.FETCH_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON_SUCCESS,
      payload: json,
    });
  } catch {
    message.error('JSON fetch failed');
  }
}

export function* fetchBeeToken(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }bee_free/token?project_secret_key=${fetchProjectSecretKey()}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CAMPAIGN_EMAIL_BEE_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    message.error('Fetch bee token failed');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CAMPAIGN_FETCH_TEMPLATES, fetchTemplates),
    takeEvery(actions.CAMPAIGN_FETCH_CAMPAIGNS, fetchCampaigns),
    takeEvery(actions.CREATE_CAMPAIGN, createCampaign),
    takeEvery(actions.FETCH_CAMPAIGN_EMAILS, fetchCampaignEmails),
    takeEvery(actions.FETCH_CAMPAIGN_DETAILS, fetchCampaignDetails),
    takeEvery(actions.DELETE_CAMPAIGN, deleteCampaign),
    takeEvery(actions.DELETE_CAMPAIGN_EMAIL, deleteCampaignEmail),
    takeEvery(actions.CREATE_CAMPAIGN_EMAIL, createCampaignEmail),
    takeEvery(actions.FETCH_CAMPAIGN_EMAIL_DETAILS, fetchEmailDetails),
    takeEvery(actions.CREATE_CAMPAIGN_COMPOSER, createCampaignEmailComposer),
    takeEvery(actions.SEND_TEST_EMAIL, sendTestEmail),
    takeEvery(actions.APPEND_CAMPAIGNS, appendCampaigns),
    takeEvery(actions.CHANGE_CAMPAIGN_STATUS, changeCampaignStatus),
    takeEvery(actions.CHANGE_EMAIL_STATUS, changeEmailStatus),
    takeEvery(actions.CREATE_DUPLICATE_CAMPAIGN, createDuplicateCampaign),
    takeEvery(actions.UPDATE_SETTINGS, updateSettings),
    takeEvery(
      actions.GET_CAMPAIGNS_RECIPIENTS_COUNT,
      fetchCampaignRecipientsCount,
    ),
    takeEvery(actions.CHANGE_EMAIL_POSITION, changeEmailPosition),
    takeEvery(actions.CAMPAIGN_CREATE_RECIPIENTS, createRecipients),
    takeEvery(
      actions.FETCH_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON,
      fetchVisualBuilderJson,
    ),
    takeEvery(actions.FETCH_CAMPAIGN_EMAIL_BEE_TOKEN, fetchBeeToken),
  ]);
}
