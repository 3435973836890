import actions from './actions';
import { cloneDeep, findIndex } from 'lodash';
import {
  getFromUserData,
  getToUserData,
} from '../../../components/Email/helper';

let initState = {
  selectedEmailCount: [],
  broadcastData: [],
  broadcastTypeList: [],
  broadcastTableLoading: true,
  broadcastStatus: 'all',
  searchValue: '',
  emailTemplates: [],
  emailTemplatesLoader: true,
  broadcastName: '',
  selectedTemplateId: '',
  broadcastID: '',
  emailComposerData: null,
  customisedEmailTemplate: [],
  mailText: '',
  mailSubject: '',
  sendTestEmailLoader: false,
  testEmailModalVisible: false,
  scheduleDetails: '',
  fromUserID: '',
  toUserID: '',
  openCreateBroadcastDrawer: false,
  duplicateBroadcastName: '',
  openTemplateDrawer: false,
  broadcastTabKey: '1',
  preHeaderText: '',
  autoSaved: false,
  customFields: [],
  templateContent: '',
  uploadImageUrl: [],
  broadcastRecipientsCount: 0,
  sortKey: '',
  sortValue: '',
  filterOption: {
    sortKey: 'sent_at',
    sortOrder: 'desc',
  },
  lastSavedTime: null,
  broadcastRecipientsLoader: false,
  templateType: 'gist_templates',
  broadcastFilteredPeople: [],
  createBroadcastLoader: false,
  deleteBroadCastFolderLoading: false,
  unengagedContactId: '',
  unengagedPeopleCount: '',
  sunsetPolicyStatus: false,
  isEmailLimitReached: false,
  broadcastType: '',
  duplicateBroadcastType: '',
  editorContentJson: '',
  templateJson: '',
  isComposerChange: false,
  beeToken: '',
  subjectValidation: '',
  broadcastTagId: '',
  isbroadcastEmailSaving: false,
  mailSubscriptionID: undefined,
  saveBtnLoader: false,
  saveFailed: false,
  broadcastSendBtnDisable: false,
  batchSendingLoader: false,
  batchSendingData: null,
};

export default function broadcastEmailReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_BROADCAST_EMAIL_SELECTED_ROW_KEYS:
      return {
        ...state,
        selectedEmailCount: action.selectedRowKeys,
      };
    case actions.FETCH_BROADCAST:
      return {
        ...state,
        broadcastTableLoading: true,
      };
    case actions.FETCH_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcastData: action.payload.mail_details,
        broadcastTypeList: action.payload.mail_type,
        broadcastTableLoading: false,
      };
    case actions.SET_BROADCAST_POSTAL_ADDRESS:
      return {
        ...state,
        emailComposerData: {
          ...state.emailComposerData,
          postal_address: action.postal_address,
        },
      };
    case actions.APPEND_BROADCAST:
      return {
        ...state,
        broadcastTableLoading: true,
      };
    case actions.APPEND_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcastData: state.broadcastData.concat(action.payload.mail_details),
        broadcastTableLoading: false,
      };
    case actions.SET_BROADCAST_STATUS:
      return {
        ...state,
        broadcastStatus: action.status,
      };
    case actions.SET_BROADCAST_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload,
        emailTemplatesLoader: false,
        selectedTemplateId: state.duplicateData
          ? state.selectedTemplateId
          : action.payload.default_template_id,
      };
    case actions.SET_EMAIL_TEMPLATES_DETAILS:
      return {
        ...state,
        emailTemplates: action.payload,
        emailTemplatesLoader: false,
      };
    case actions.SET_BROADCAST_NAME:
      return {
        ...state,
        broadcastName: action.payload.name,
        broadcastType: action.payload.type,
      };
    case actions.SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: action.templateId,
      };
    case actions.CREATE_BROADCAST:
      return {
        ...state,
        createBroadcastLoader: true,
      };
    case actions.CREATE_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcastID: action.payload.id,
        selectedTemplateId: action.payload.template_id,
        createBroadcastLoader: false,
      };
    case actions.CREATE_BROADCAST_FAILURE:
      return {
        ...state,
        createBroadcastLoader: false,
      };
    case actions.FETCH_BROADCAST_DATA:
      return {
        ...state,
        templateContent: '',
      };
    case actions.FETCH_BROADCAST_DATA_SUCCESS:
      return {
        ...state,
        emailComposerData: action.payload.data,
        customisedEmailTemplate: action.payload.settings,
        fromUserID: getFromUserData(action.payload.data),
        toUserID: getToUserData(action.payload.data),
        mailText: action.payload.data.mail_text,
        mailSubject: action.payload.data.subject
          ? action.payload.data.subject
          : '',
        selectedTemplateId: action.payload.data.template_id,
        scheduleDetails: action.payload.data.schedule_details
          ? action.payload.data.schedule_details
          : '',
        broadcastName: action.payload.data.title,
        broadcastType: action.payload.data.composer_type,
        preHeaderText: action.payload.data.pre_header_text
          ? action.payload.data.pre_header_text
          : '',
        customFields: action.payload.data.custom_fields,
        templateContent: action.payload.data.template_content,
        broadcastRecipientsCount: 0,
        broadcastFilteredPeople: [],
        unengagedContactId: action.payload.data.unengaged_contact_segment_id,
        unengagedPeopleCount: action.payload.data.unengaged_people_count,
        sunsetPolicyStatus: action.payload.data.sunset_policy_enabled,
        isEmailLimitReached: action.payload.email_limit_reached,
        editorContentJson: action.payload.data.visual_builder_json,
        isComposerChange: false,
        mailSubscriptionID: action.payload.data.mail_subscription_id,
      };
    case actions.SET_BROADCAST_EMAIL_TEXT:
      return {
        ...state,
        isComposerChange: true,
        mailText: action.message,
      };
    case actions.SET_BROADCAST_EMAIL_SUBJECT:
      return {
        ...state,
        mailSubject: action.subject,
      };
    case actions.SEND_BROADCAST_TEST_EMAIL:
      return {
        ...state,
        sendTestEmailLoader: true,
      };
    case actions.SEND_BROADCAST_TEST_EMAIL_SUCCESS:
      return {
        ...state,
        sendTestEmailLoader: false,
        testEmailModalVisible: false,
      };
    case actions.TEST_EMAIL_MODAL_VISIBLE:
      return {
        ...state,
        testEmailModalVisible: action.visible,
      };
    case actions.SEND_BROADCAST_TEST_EMAIL_FAILURE:
      return {
        ...state,
        sendTestEmailLoader: false,
      };
    case actions.SET_BROADCAST_SORT_KEY_AND_VALUE:
      return {
        ...state,
        sortKey: action.sortKey,
        sortValue: action.sortOrder,
      };
    case actions.SET_BROADCAST_ID:
      return {
        ...state,
        broadcastID: action.id,
      };
    case actions.SET_FROM_EMAIL_USER_ID:
      return {
        ...state,
        fromUserID: action.fromUserID,
      };
    case actions.SET_TO_EMAIL_USER_ID:
      return {
        ...state,
        toUserID: action.toUserID,
      };
    case actions.OPEN_CREATE_BROADCAST_DRAWER:
      return {
        ...state,
        openCreateBroadcastDrawer: action.payload,
        duplicateBroadcastName: '',
        duplicateBroadcastType: '',
        duplicateData: '',
        selectedTemplateId: '',
        templateType: 'gist_templates',
      };
    case actions.SET_DUPLICATE_BROADCAST_DATA:
      return {
        ...state,
        duplicateBroadcastName: `${action.payload.data.title}(Copy)`,
        duplicateBroadcastType: action.payload.data.composer_type,
        duplicateData: action.payload.data,
        selectedTemplateId:
          action.payload.data.template_id ||
          action.payload.data.visual_builder_template_name,
        broadcastRecipientsCount: 0,
        broadcastFilteredPeople: [],
        templateType: action.payload.data.template_type,
        editorContentJson: action.payload.data.visual_builder_json,
      };
    case actions.UPDATE_VISUAL_BUILDER_JSON:
      return {
        ...state,
        editorContentJson: action.payload,
      };
    case actions.CLOSE_CREATE_BROADCAST_DRAWER:
      return {
        ...state,
        openCreateBroadcastDrawer: action.payload,
      };
    case actions.OPEN_BROADCAST_TEMPLATE_DRAWER:
      return {
        ...state,
        openTemplateDrawer: action.payload,
      };
    case actions.SET_BROADCAST_TAB_KEY:
      return {
        ...state,
        broadcastTabKey: action.payload,
      };
    case actions.SET_DUPLICATE_BROADCAST_NAME:
      return {
        ...state,
        duplicateBroadcastName: action.payload.name,
        duplicateBroadcastType: action.payload.type,
        broadcastType: '',
      };
    case actions.SET_PRE_HEADER_TEXT:
      return {
        ...state,
        preHeaderText: action.preHeaderText,
      };
    case actions.SET_BROADCAST_LAST_SAVE_TIME:
      return {
        ...state,
        lastSavedTime: action.payload.updated_at,
      };
    case actions.RESET_EMAIL_COMPOSER_FORM:
      return {
        ...state,
        emailComposerData: '',
        fromUserID: '',
        toUserID: '',
        mailText: '',
        mailSubject: '',
        scheduleDetails: '',
        broadcastName: '',
        preHeaderText: '',
        editorContentJson: '',
      };
    case actions.BROADCAST_FETCH_RECIPIENTS_SUCCESS:
      return {
        ...state,
        broadcastRecipientsCount: action.payload.data.matched_people_count,
        broadcastSendBtnDisable: false,
      };
    case actions.BROADCAST_FETCH_RECIPIENTS_FAILURE:
      return {
        ...state,
        broadcastSendBtnDisable: action.payload,
        broadcastRecipientsCount: action.payload
          ? action.count.data.matched_people_count
          : state.broadcastRecipientsCount,
      };
    case actions.SET_BROADCAST_FILTER_OPTION:
      return {
        ...state,
        filterOption: action.payload,
      };
    case actions.BROADCAST_SET_RECIPIENTS_LOADER:
      return {
        ...state,
        broadcastRecipientsLoader: action.payload,
      };
    case actions.BROADCAST_FILTER_VALIDATION: {
      return {
        ...state,
        broadcastRecipientsCount: action.payload
          ? state.broadcastRecipientsCount
          : 0,
        broadcastFilteredPeople: action.payload
          ? state.broadcastFilteredPeople
          : [],
      };
    }
    case actions.CREATE_RECIPIENTS:
      return {
        ...state,
        saveBtnLoader: true,
        saveFailed: false,
      };
    case actions.BROADCAST_RECIPIENTS_CREATE_SUCCESS:
      return {
        ...state,
        broadcastRecipientsCount:
          action.payload.new_filters && action.payload.new_filters.length !== 0
            ? state.broadcastRecipientsCount
            : 0,
        saveBtnLoader: false,
      };
    case actions.BROADCAST_RECIPIENTS_CREATE_FAILURE:
      return {
        ...state,
        saveBtnLoader: false,
        saveFailed: true,
      };
    case actions.SET_BROADCAST_TEMPLATE_TAB_KEY:
      return {
        ...state,
        templateType: action.payload,
      };
    case actions.CLEAR_BROADCAST_CUSTOM_FIELDS:
      return {
        ...state,
        customFields: [],
      };
    case actions.DELETE_BROADCAST_SUCCESS:
      return {
        ...state,
        selectedEmailCount: [],
      };
    case actions.DELETE_BROADCAST_EMAIL_FOLDER_LOADING:
      return {
        ...state,
        deleteBroadCastFolderLoading: action.payload,
      };
    case actions.EMAIL_UPDATE_SUNSET_POLICY:
      return {
        ...state,
        sunsetPolicyStatus: action.payload,
      };
    case actions.CREATE_BROADCAST_COMPOSER:
      return {
        ...state,
        mailText: action.isFromBeeEditor
          ? action.object.mail_filter.mail_text
          : state.mailText,
        editorContentJson: action.isFromBeeEditor
          ? action.object.mail_filter.visual_builder_json
          : state.editorContentJson,
        saveBtnLoader: action.saveBtnLoader,
      };
    case actions.CREATE_BROADCAST_SETTINGS:
      return {
        ...state,
        saveBtnLoader: true,
      };
    case actions.CREATE_BROADCAST_COMPOSER_SUCCESS:
      return {
        ...state,
        saveBtnLoader: action.payload,
        saveFailed: false,
      };
    case actions.CREATE_BROADCAST_COMPOSER_FAILURE:
      return {
        ...state,
        saveBtnLoader: action.payload,
        saveFailed: true,
      };
    case actions.SET_BROADCAST_EMAIL_LOADING_INDICATOR:
      return {
        ...state,
        isbroadcastEmailSaving: action.payload,
      };
    case actions.FETCH_VISUAL_BUILDER_JSON_SUCCESS:
      return {
        ...state,
        templateJson: action.payload,
      };
    case actions.SET_COMPOSER_VALUE_CHANGE:
      return {
        ...state,
        isComposerChange: action.payload,
      };
    case actions.FETCH_BEE_TOKEN_SUCCESS:
      return {
        ...state,
        beeToken: action.payload,
      };
    case actions.SET_BROADCAST_EMAIL_SUBJECT_VALIDATION:
      return {
        ...state,
        subjectValidation: action.payload,
      };
    case actions.BROADCAST_CANCEL_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          has_schedule: false,
        },
      };
    case actions.SET_BROADCAST_TAG_ID:
      return {
        ...state,
        broadcastTagId: action.payload,
      };
    case actions.STOP_BROADCAST:
      return {
        ...state,
        stopBtnLoader: true,
      };
    case actions.STOP_BROADCAST_SUCCESS:
      const { id, status } = action.payload;
      let emailData = cloneDeep(state.broadcastData);
      const index = findIndex(emailData, { id: id });
      if (index !== -1) {
        emailData[index].status = status;
      }
      return {
        ...state,
        broadcastData: emailData,
        stopBtnLoader: false,
      };
    case actions.STOP_BROADCAST_FAILURE:
      return {
        ...state,
        stopBtnLoader: false,
      };
    case actions.FETCH_BATCH_DETAILS:
      return {
        ...state,
        batchSendingLoader: true,
      };
    case actions.CHANGE_BATCH_SENDING_STATUS:
      return {
        ...state,
        batchSendingData: action.batchSendingData,
        batchSendingLoader: false,
      };
    default:
      return state;
  }
}
